const enableJS = (function () {
  function setup() {
    document.documentElement.classList.remove('no-js');
  }

  return {
    init: setup,
  };
})();

export default enableJS;
