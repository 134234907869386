var jsAjaxFilter = (function () {
  function setup($_container) {
    var $_c = $_container ? $_container : $('body');
    var $loading = $('.loading-indicator');

    $(document).on('click', '.js-filter-trigger', function (e) {
      e.preventDefault();
      var $b = $(this);
      var $box = $('.js-filter-box');
      var $panel = $_c.find('.js-filter-content');
      var $heading = $('.js-filter-heading');
      var urlpath = $b.attr('href');
      var $showMoreBtn = $('.js-show-more-container');
      var $bTxt = $b.html();
      var $headerHeight = $('.page-head').outerHeight();
      if ($box.length) {
        var $targetOffset = $box.offset().top;
      }

      $box.find('.dropdown-toggle').html($bTxt);
      $box.find('.dropdown-toggle').addClass('is-selected');

      $panel.fadeOut(500, function () {
        $heading.fadeOut(300);
        $loading.fadeIn(300);
        $panel.children().remove();
        $showMoreBtn.fadeOut(300);

        $.ajax({
          url: urlpath,
        }).done(function (data, textStatus, xhr) {
          var $res = $.parseJSON(data);
          var $headingTxt = $res['contentHeading'];
          var $appendItems = $res['contentItems'];
          var hasHiddenItem = $res['hasHiddenItem'];

          $heading.html($headingTxt);
          $heading.fadeIn(300);
          $panel.append($appendItems);

          $loading.fadeOut(300);
          $panel.find('> div').css('opacity', 0);
          $panel.fadeIn(500, function () {
            jsMasonry.init($panel);
            $panel.find('> div').animate({ opacity: 1 }, 800);

            $('html, body').animate({ scrollTop: $targetOffset - $headerHeight - 20 }, 400);
          });

          if (hasHiddenItem) {
            $showMoreBtn.fadeIn(300);
          }

          return false;
        });
      });
    });
  }

  return {
    init: setup,
  };
})();

export default jsAjaxFilter;
