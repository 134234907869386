import { getLang, langArg } from './lang';
import { Bloodhound, loadjQueryPlugin } from 'corejs-typeahead/index';

const typeaheadSearch = (function() {
  class solrSearch {
    constructor({
      $input = null,
      baseUrl = null,
    }) {
      let __this = this,
        config = [];
      this.$input = $input;
      this.cookies__name = 'smart-search';
      this.cookies__max = 10;
      
      this.lang = getLang();
      this.baseUrl = baseUrl;

      //this.support__result__search = false;
      this.$search__form = $input.closest('.search-form');
      // this.$search__container = $("#smart-search-result");

      this.rating = '';
      // typeahead

      this.cookies__data = localStorage.getItem('smart-search') ? JSON.parse(localStorage.getItem('smart-search')) : [];

      this.suggestion__header = langArg({
        en: 'Topics you may be interested in',
        tc: '你可能感興趣的主題',
        sc: '你可能感兴趣的主题',
      });

      this.cookies__btn = langArg({
        en: 'Clear',
        tc: '移除搜尋紀錄',
        sc: '移除搜寻纪录',
      });

      this.cookies__header = langArg({
        en: 'Recent Search',
        tc: '最近搜尋',
        sc: '最近搜寻',
      });

      this.data__suggestion = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.whitespace,
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        remote: {
          url: `${__this.baseUrl}search/effo2?lang={lang}&q={query}&start=0&rows=5&len=200&fq={fq}`,
          prepare: function (q, settings) {
            let queryString = q.trim().toLowerCase();
            settings.url = settings.url.replace('{lang}', __this.lang);
            settings.url = settings.url.replace('{query}', queryString);

            const fq = queryString.split(' ').filter(q => q).map(q => `title:${q}`).join(' AND ');
            settings.url = settings.url.replace('{fq}', fq);

            settings.url = encodeURI(settings.url);
            return settings;
          },
          transform: function (__data) {
            var data = __data?.response?.docs;

            let transformed__data = [];

            if (typeof data !== 'undefined') {
              for (const val of data) {
                transformed__data.push({
                  text: val['title'],
                  url: val['url'],
                  target: val['target'],
                  type: val['type'],
                  _query: __this.$input.val(),
                });
              }              
            }

            return transformed__data;
          },
        },
      });

      this.data__keywords = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.whitespace,
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        remote: {
          // url: `${keywordsUrl}?q={query}`,
          url: `${__this.baseUrl}terms/effo2?omitHeader=true&wt=json&json.nl=flat&terms=true&terms.regex={query}.*&terms.regx.flag=case_insensitive&terms.limit=10&terms.sort=count&terms.fl=title`,
          prepare: function (q, settings) {
            let queryString;

            if ($input.hasClass('js-keyword-exact')) {
              queryString = q.trim().toLowerCase();
            } else {
              queryString = __this.getTheLastWord(q).toLowerCase();
            }

            settings.url = settings.url.replace('{query}', queryString);

            return settings;
          },
          transform: function (__data) {
            let data = __data.terms.title;
            let transformed__data = [];

            if (typeof data !== 'undefined') {
              for (const val of data) {
                if (typeof val == 'string') {
                  transformed__data.push({
                    keywords: val,
                    _query: __this.$input.val(),
                  });
                }
              }
            }

            return transformed__data;
          },
        },
      });

      config = [
        {
          name: 'data-keywords',
          limit: 4,
          source: this.engineWithDefaultsKeywords,
          templates: {
            suggestion: function (item) {
              if ($input.hasClass('js-keyword-exact')) {
                return `<div>${item.keywords}</div>`;
              } else {
                return `<div>${__this.getNoLastWord(item._query)}${item.keywords}</div>`;
              }
            },
          },
          display: function (item) {
            if ($input.hasClass('js-keyword-exact')) {
              return `${item.keywords}`;
            } else {
              return `${__this.getNoLastWord(item._query)}${item.keywords}`;
            }
          },
        },
        {
          name: 'data-suggestions',
          limit: 4,
          source: this.engineWithDefaultsSuggestions,
          templates: {
            header: `<div class="tt-suggestion-header"><p>${this.suggestion__header}</p></div>`,
            suggestion: function (item) {
              return `<div><a class="tt-link" href="${item.url}"><span class="tt-link__text">${item.text}</span></a></div>`;
            },
          },
          display: function (item) {
            return '';
          },
        },
      ];

      this.$input.typeahead(
          {
            highlight: true,
            minLength: 0,
          },
          ...config,
          {
            name: 'data-cookies',
            source: this.engineCookies(this),
            limit: 6,
            templates: {
              header: `<div class="tt-cookies-header">${this.cookies__header}</div>`,
              footer: `<div class="tt-cookies-footer"><button class="tt-cookies-remove"> <span class="ico ico--closed" aria-hidden="true"></span> ${this.cookies__btn}</button></div>`,
            },
          }
        )
        .on('typeahead:open', this.typeaheadOpen(this))
        .on('typeahead:close', this.typeaheadClose(this))
        .on('typeahead:render', this.typeaheadRender(this))
        .on('typeahead:select', this.typeaheadSelect(this));

      // End Basic type ahead

      this.$search__form.find('.tt-menu').wrap("<div class='tt-menu-container'></div>");

      this.$search__form.on('submit.cookie', function (e) {
        e.preventDefault();
        __this.$input.typeahead('close');

        const $form = $(this);
        $form.off('submit.cookie');
        $form.trigger('submit');
      });

      this.setClearInut();

      this.$input.prevAll('.tt-hint').attr(
        'title',
        langArg({
          en: 'Search hint',
          tc: '搜尋提示',
          sc: '搜寻提示',
        })
      );

      this.$input.data('solrSearch', this);

      this.$input.on('keyup', function (e) {
        setTimeout(function () {
          __this.checkInputHvVal();
        }, 50);
      });
    }

    checkInputHvVal() {
      this.$input.toggleClass('js-input-hv-val', this.$input.val().trim() !== '');
    }

    typeaheadRender(__solrSearch) {
      return (e, ...item) => {
        let $clearBtn = __solrSearch.$input.closest('.twitter-typeahead').find('.tt-cookies-remove');

        if ($clearBtn.length) {
          $clearBtn.off('click.removecookies').on('click.removecookies', function (e) {
            e.preventDefault();
            localStorage.removeItem('smart-search');
            __solrSearch.cookies__data = [];
            __solrSearch.$input.typeahead('close');
            __solrSearch.$input.closest('.twitter-typeahead').find('.tt-menu').find('.tt-dataset-data-cookies').remove();
          });
        }
      };
    }

    typeaheadOpen(__solrSearch) {
      return (e) => {
        let $menuContainer = __solrSearch.$input.siblings('.tt-menu-container');

        $menuContainer.addClass('show');
      };
    }

    typeaheadClose(__solrSearch) {
      return (e) => {
        let $menuContainer = __solrSearch.$input.siblings('.tt-menu-container');

        $menuContainer.removeClass('show');
      };
    }

    typeaheadSelect(__solrSearch) {
      return (e, __selected) => {
        let $input = __solrSearch.$input;

        if ($input.attr('data-hidden-form')) {
          let $formtarget = $($input.attr('data-hidden-form')),
            $formInput = $formtarget.find('input[type="hidden"]');
          let val = null;
          if (typeof __selected !== 'string') {
            val = __selected['keywords'];
          } else {
            val = __selected;
          }

          $formInput.val(val);
          $formtarget.submit();
        } else {
          if (typeof __selected == 'string' || __selected['keywords']) {
            $input.closest('form').submit();
          }
        }
      };
    }

    engineWithDefaultsKeywords(q, sync, async) {
      let $solarData = this.$el.closest(this.selectors.wrapper).find(this.selectors.input);

      let solarData = $solarData.data('solrSearch');

      if (q === '') {
      } else {
        solarData.data__keywords.search(q, sync, async);
      }
    }

    engineWithDefaultsSuggestions(q, sync, async) {
      let $solarData = this.$el.closest(this.selectors.wrapper).find(this.selectors.input);

      let solarData = $solarData.data('solrSearch');

      if (q === '') {
      } else {
        solarData.data__suggestion.search(q, sync, async);
      }
    }

    engineCookies(__solrSearch) {
      return function ec(q, cb) {
        if (q == '') {
          cb(__solrSearch.cookies__data);
        } else {
          cb([]);
        }
      };
    }

    getTheLastWord(__str) {
      return [...__str.trim().split(/[\s]+/)].pop();
    }

    getNoLastWord(__str) {
      let wd = '';

      if (__str.trim().match(/[\s]+/)) {
        wd = langArg({
          en: __str.trim().replace(/\s(\w+)$/, '') + ' ',
          tc: __str.trim().replace(/\s([^\s]+)$/, '') + ' ',
          sc: __str.trim().replace(/\s([^\s]+)$/, '') + ' ',
        });
      }

      return wd;
    }

    setCookies(__string) {
      this.cookies__data.unshift(__string);
      let distinct__cookies = [...new Set(this.cookies__data)];

      if (distinct__cookies.length > this.cookies__max) {
        distinct__cookies.pop();
      }

      this.cookies__data = distinct__cookies;

      localStorage.setItem('smart-search', JSON.stringify(this.cookies__data));

      console.log(distinct__cookies);
    }

    setClearInut() {
      let __this = this;

      __this.$clearBtn = __this.$search__form.find('.js-input-clear');
      __this.$clearBtn.on('click', function (e) {
        e.preventDefault();
        __this.$input.val('');
        __this.$input.typeahead('val', '');
        __this.$input.focus();
        __this.checkInputHvVal();
      });
    }
  }

  function setup() {
    const $typeaheadInputs = $('input.js-typeahead');

    if ($typeaheadInputs.length) {
      loadjQueryPlugin(); // load typeahead
    }

    $typeaheadInputs.each(function () {
      let $this = $(this),
        solrBaseUrl = $this.data('solr-base-url');

      new solrSearch({
        $input: $this,
        baseUrl: solrBaseUrl,
      });

      if ($this.val()) {
        $this.addClass('js-input-hv-val');
      }
    });

    // mainSearchBox is set with position: absolute, so resultBlk's margin-top needs to be adjusted
    const mainSearchBox = document.querySelector('.main-content .search-box'); // Not the search box in the header
    const resultBlk = document.querySelector('.result-blk');

    if (mainSearchBox && resultBlk) {
      new ResizeObserver((entries) => {
        const entry = entries[0];
        // If the search box is resized in the unexpanded state, adjust resultBlk's margin-top
        if (!entry.target.querySelector('.tt-open')) {
          resultBlk.style.marginTop = `${entry.borderBoxSize[0].blockSize}px`;
        } // else if the search box has been expanded, keep resultBlk in its original position
      }).observe(mainSearchBox);
    }
  }

  return { init: setup };
})();

export default typeaheadSearch;