const tooltip = (function () {
  var $tooltip;

  function setup() {
    $tooltip = $('.source-info');

    $tooltip.tooltip({
      html: true,
    });

    $tooltip.find('img').attr('aria-hidden', 'true');
  }

  return {
    init: setup,
  };
})();

export default tooltip;
