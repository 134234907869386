import AOS from 'aos';

const animateOnScroll = (function () {
  function setup() {
    var duration = 1000, offset = 0;

    AOS.init({
      once: true,
      offset: offset,
      duration: duration,
      // startEvent: 'DOMContentLoaded',
      easing: 'cubic-bezier(.36,.02,.6,.99)'
    });
  }

  return {
    init: setup,
  };
})();

export default animateOnScroll;
