const caseSection = (() => {

    const setupAnimatedClass = (component) => {

        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setTimeout(() => {
                        component.classList.add('animated');
                    }, parseInt($(entry.target).attr("data-aos-delay")) || 0);

                    observer.unobserve(component);
                }
            });
        });

        observer.observe(component);
    }

    const setup = () => {
        const caseSections = document.querySelectorAll('.case-section');

        caseSections.forEach((component, index) => {
            $(component).find('.case-section-blk').each((index, element) => {
                setupAnimatedClass(element);
            });
        });
    }

    return {init: setup}
})();

export default caseSection;