const backToTop = (function () {
  var $btnTop,
    $btnTopBox,
    $footer,
    $wrapper,
    scrollTop,
    scrollLeft,
    bottom = 30,
    fadeTime = 300,
    scrollTime = 400,
    showTop = 120,
    csstop = -45,
    btnHeight;

  function setup() {
    $btnTop = $('.js-top');
    $btnTopBox = $('.btn-top-box');
    $footer = $('.js-top-container');
    $wrapper = $('.wrapper');
    btnHeight = $btnTop.height();

    if (!$btnTop.length) {
      return;
    }

    $btnTop.hide();

    $btnTop.on('click', function () {
      $('html, body').animate({ scrollTop: 0 }, scrollTime);
      $wrapper.focus();
    });

    $(window).on('scroll', function () {
      scrollTop = $(window).scrollTop();
      scrollLeft = $(window).scrollLeft();

      updateBack2Top();
    });
  }

  function updateBack2Top() {
    var footer_top = $footer.offset().top - parseInt($footer.css('margin-top')),
      windowHeight = $(window).height();

    if (scrollTop > showTop) {
      $btnTop.fadeIn(fadeTime);
      $btnTopBox.show();
    } else {
      $btnTop.fadeOut(fadeTime, function () {
        $btnTopBox.hide();
      });
    }

    if (scrollTop + windowHeight >= footer_top + bottom + csstop + btnHeight) {
      $btnTop.css({
        position: 'relative',
        bottom: 0,
        'margin-left': 0,
      });
    } else {
      $btnTop.css({
        position: 'fixed',
        bottom: bottom,
        'margin-left': -scrollLeft,
      });
    }
  }

  return {
    init: setup,
  };
})();

export default backToTop;
