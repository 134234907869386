/*
 * Dependencies:
 *
 *
 */

const bfTriangle = (() => {
    const elemName = {
      triangle: 'bf-triangle',
      textBlk: 'bf-text-blk',
      isometric: 'bf-isometric',
      isometricBtns: 'bf-isometric-btns',
      isometricBtnsMob: 'bf-isometric-btns-mob',
    };
  
    const totalAnimeStep = 7;
  
    const waitForSecond = async (second) => {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, second * 1000);
      });
    };
  
    const extractStringAfterDoubleDash = (className) => {
      var regexPattern = /--(.+)/;
  
      var matches = regexPattern.exec(className);
  
      if (matches && matches.length > 1) {
        return matches[1];
      }
  
      return null;
    };
  
    const handleCloseBtnClick = (e, $triangle) => {
      let $closeBtn = $(e.target);
  
      if (!$closeBtn) return;
  
      if ($triangle.find('.bf-text-blk--animating').length > 0) return;
  
      let $textBlk = $triangle.find($(`.${elemName.textBlk}`));
  
      $textBlk.each(async (i, blk) => {
        let $blk = $(blk);
  
        $blk.addClass(`${elemName.textBlk}--animating`);
  
        if ($blk.hasClass(`${elemName.textBlk}--default`)) {
          await waitForSecond(0.4);
          $blk.removeClass(`${elemName.textBlk}--hidden`);
          await waitForSecond(0.1);
          $blk.removeClass(`${elemName.textBlk}--fade-out`);
        } else {
          $blk.addClass(`${elemName.textBlk}--fade-out`);
          await waitForSecond(0.4);
          $blk.removeClass(`${elemName.textBlk}--hidden`);
          $blk.addClass(`${elemName.textBlk}--hidden`);
        }
  
        await waitForSecond(0.4);
        $blk.removeClass(`${elemName.textBlk}--animating`);
  
        if ($('html').attr('data-whatinput') != 'keyboard') return;
        if ($triangle.find(`.${elemName.isometricBtns} button`).first()) {
          $triangle.find(`.${elemName.isometricBtns} button`).first().focus();
        } else {
          $triangle.find(`.${elemName.isometricBtnsMob} button`).first().focus();
        }
      });
  
      $triangle.find(`.${elemName.isometric}`).attr('class', `${elemName.isometric}`);
    };
  
    const handleMagnifierClick = (e, $triangle) => {
      let $magnifier = $(e.target);
  
      if (!$magnifier) return;
  
      if ($triangle.find('.bf-text-blk--animating').length > 0) return;
  
      if (!$magnifier.is('button')) {
        if ($magnifier.find('button').length > 0) {
          $magnifier = $magnifier.find('button');
        } else {
          $magnifier = $magnifier.closest('button');
        }
  
        if (!$magnifier) return;
      }
  
      let modifier = extractStringAfterDoubleDash($magnifier.attr('class'));
      if (!modifier) return;
  
      $triangle
        .find($(`.${elemName.isometric}`))
        .attr('class', `${elemName.isometric} ${elemName.isometric}--select-${modifier}`);
  
      $triangle.find(`.${elemName.textBlk}`).each(async (i, blk) => {
        let $blk = $(blk);
  
        $blk.addClass(`${elemName.textBlk}--animating`);
  
        if ($blk.hasClass(`${elemName.textBlk}--${modifier}`)) {
          await waitForSecond(0.4);
          $blk.removeClass(`${elemName.textBlk}--hidden`);
          await waitForSecond(0.1);
          $blk.removeClass(`${elemName.textBlk}--fade-out`);
        } else {
          $blk.addClass(`${elemName.textBlk}--fade-out`);
          await waitForSecond(0.4);
          $blk.removeClass(`${elemName.textBlk}--hidden`);
          $blk.addClass(`${elemName.textBlk}--hidden`);
        }
  
        await waitForSecond(0.4);
        $blk.removeClass(`${elemName.textBlk}--animating`);
  
        if ($('html').attr('data-whatinput') == 'keyboard') {
          $triangle.find(`.bf-text-blk__close-btn`).focus();
        }
      });
    };
  
    const setupTriangleAnimation = async ($triangle) => {
      if (!$triangle.hasClass(`${elemName.triangle}--animate`)) return;
      if ($triangle.hasClass(`${elemName.triangle}--animated`)) return;
  
      const resetStep = ($triangle) => {
        let steps = [];
        for (let i = 0; i < totalAnimeStep; i++) {
          steps.push(`${elemName.triangle}--step-${i + 1}`);
        }
  
        $triangle.removeClass(steps.join(' '));
      };
  
      const setStep = ($triangle, step = 0) => {
        resetStep($triangle);
        $triangle.addClass(`${elemName.triangle}--step-${step}`);
      };
  
      // start
      let i = 0;
      while (i < totalAnimeStep) {
        setStep($triangle, i++ + 1);
        await waitForSecond(0.8);
      }
  
      // end
      resetStep($triangle);
      $triangle.addClass(`${elemName.triangle}--animated`);
    };
  
    const setup = () => {
      $(`.${elemName.triangle}`).each((i, triangle) => {
        let $triangle = $(triangle);
  
        $triangle
          .find($(`.${elemName.isometricBtns}__btn button`))
          .on('click', (e) => handleMagnifierClick(e, $triangle));
        $triangle
          .find($(`button.${elemName.isometricBtnsMob}__btn`))
          .on('click', (e) => handleMagnifierClick(e, $triangle));
        $triangle.find($(`.${elemName.textBlk}__close-btn`)).on('click', (e) => handleCloseBtnClick(e, $triangle));
  
        setupTriangleAnimation($triangle);
      });
    };
  
    return { init: setup };
  })();
  
  export default bfTriangle;
  