import Masonry from "masonry-layout";

const storiesListing = (function() {
  function initMasonry(list) {
    list.masonry = new Masonry(list, {
      itemSelector: '.stories-listing__item',
      horizontalOrder: true,
    });
  }

  function destroyMasonry(list) {
    list.masonry.destroy();
    list.masonry = null;
  }

  function setup() {
    const lists = document.querySelectorAll('.stories-listing__list');
    if (!lists.length) return;

    const itemsShownOnLoad = 6;
    const mobileMQ = window.matchMedia('(max-width: 575px)');
    const showMoreLists = Array.from(lists).filter(list => list.querySelectorAll('.stories-listing__item').length > itemsShownOnLoad);
    
    showMoreLists.forEach((list) => {
      list.classList.add("show-more");

      if (!mobileMQ.matches) {
        initMasonry(list);
      }

      list.parentElement.querySelector('.js-show-more-btn')?.addEventListener('click', (e) => {
        list.classList.add('shown');
        list.masonry?.layout();
        // if not mouse click (i.e. keyboard click), focus the first show-more item so that user can continue to navigate
        if (e.detail === 0) {
          list.querySelector(`.stories-listing__item:nth-child(${itemsShownOnLoad + 1}) a`)?.focus();
        }
      });
    });

    mobileMQ.addEventListener("change", function(e) {
      if (e.matches) {
        showMoreLists.forEach((list) => {
          destroyMasonry(list);
        });
      } else {
        showMoreLists.forEach((list) => {
          initMasonry(list);
        });
      }
    });
  }

  return { init: setup};
})();

export default storiesListing;