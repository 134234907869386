var responsive2xImg = (function () {
  var $resImg,
    regex = /\/\d+p\d+\//;

  function setup() {
    $resImg = $('.respon-img, .js-responsive-img img');

    setImg2xSrcset($resImg);
  }

  function setImg2xSrcset($img) {
    $img.each(function () {
      var $this = $(this),
        imgPath = $this.attr('src'),
        originalSrc = /\s/.test(imgPath) ? encodeURI(imgPath) : imgPath,
        matched = originalSrc.match(regex);

      if (matched) {
        var srcArr = originalSrc.split('/'),
          srcArrLength = srcArr.length,
          lastSrcArr = srcArr[srcArrLength - 2],
          imgW = getBeforeAfterChar(lastSrcArr, 'p', 'before'),
          imgH = getBeforeAfterChar(lastSrcArr, 'p', 'after');

        if (imgW !== '0' || imgH !== '0') {
          var imgWH = imgW * 2 + 'p' + imgH * 2,
            srcsetPath = originalSrc.replace(regex, '/' + imgWH + '/');

          $this.attr({
            srcset: srcsetPath + ' 2x',
          });
        }
      }
    });
  }

  function getBeforeAfterChar(str, char, pos) {
    var idx = str.indexOf(char);

    if (idx > 0) {
      if (pos == 'before') {
        return str.substring(0, idx);
      } else if (pos == 'after') {
        return str.substring(idx + 1);
      } else {
        return str;
      }
    } else {
      return '';
    }
  }

  return {
    init: setup,
  };
})();

export default responsive2xImg;

// var responsiveImg = (function() {
//   var $resImg,
//     state,
//     t = null,
//     regex = /\/\d+p\d+\//,
//     rwdimg_size = '750';

//   function setup() {
//     $resImg = $('.js-responsive-img img');

//     $resImg.each(function() {
//       setImgData($(this));
//     });

//     state = queryTest.getCurrentState();
//     stateChangeAction(state);

//     $(window).on('resize load', function() {
//       {
//         if (t !== null) {
//           clearTimeout(t);
//           t = null;
//         }

//         t = setTimeout(function() {
//           var old_state = state,
//             new_state = queryTest.getCurrentState();

//           if (old_state !== new_state) {
//             stateChangeAction(new_state);
//           }

//           state = new_state;
//         }, 30);
//       }
//     });
//   }

//   function setImgData($_img) {
//     $_img.attr('data-img-ori', $_img.attr('src'));
//     $_img.attr('data-img-rwd', $_img.attr('src').replace(regex, '/' + rwdimg_size + 'p0/'));
//   }

//   function updateSrc(_state) {
//     // incorrect
//     $resImg.each(function() {
//       var $img = $(this);
//       // imgLoaded($img, function(){
//       if (_state == 'sm') {
//         $img.attr('src', $img.attr('data-img-rwd'));
//       } else {
//         $img.attr('src', $img.attr('data-img-ori'));
//       }
//       // })
//     });
//   }

//   function stateChangeAction(_state) {
//     updateSrc(_state);
//   }

//   return {
//     init: setup
//   };
// })();
