var jsMoveArea = (function () {
  var moveBox = '.js-movearea-panel';

  function setup() {
    document.addEventListener('mousemove', mainMove, false);
    // document.removeEventListener('mousemove', mainMove, false);
  }

  function mainMove(e) {
    if ($(e.target).closest('.move-area').length <= 0) {
      return false;
    }
    var $sectionTitle = $('.js-movearea').find('.section-title');
    
    var winWidth = $('.move-area').innerWidth();
    var winHeight = $('.move-area').innerHeight();
    var contWidth = $(moveBox).innerWidth();
    var contHeight = $(moveBox).innerHeight();

    var nowX = (-1 + (e.pageX / winWidth) * 2).toFixed(2);
    var nowY = (1 - ((e.pageY - $('.move-area').offset().top) / winHeight) * 2).toFixed(2);

    var movePositionX = -1 * (nowX * ((contWidth - winWidth) / 2));
    var movePositionY = ((contHeight <= winHeight))? 0 : nowY * ((contHeight - winHeight));

    var $height = $('.move-area').outerHeight();
    var $c_h = $(moveBox).outerHeight();
    var tbExtraArea = ($c_h > $height)? ($c_h - $height) : 0 ;
    var y = e.pageY - $('.move-area').offset().top;
    var activeArea = $height*3/5;

    $(moveBox).css({
      'transform': 'translate(' + movePositionX + 'px, ' + movePositionY + 'px)',
    });
    
    if(tbExtraArea > 0) {
      if(y < activeArea) {
        new TweenMax.to($sectionTitle, .2, {
          opacity: 1,
          ease: "power1.out"
        }); 
      } else if(y > activeArea) {
        new TweenMax.to($sectionTitle, .2, {
          opacity: 0,
          ease: "power1.out"
        }); 
      }
    }
  }

  return {
    init: setup
  };
}());

export default jsMoveArea;