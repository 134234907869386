const dropdownSearch = (function() {
  function setup() {
    const header = document.querySelector('.page-head');
    if (!header) return;
    const searches = header.querySelectorAll('.dropdown--search');
    searches.forEach((search) => {
      $(search).on('show.bs.dropdown', function() {
        document.documentElement.classList.add('search-open');
      });
      $(search).on('hidden.bs.dropdown', function() {
        document.documentElement.classList.remove('search-open');
      });
    });
  }

  return { init: setup };
})();

export default dropdownSearch;