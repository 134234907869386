const SubscribeBtn = (() => {
  const setup = () => {

    document.querySelectorAll(".subscribe-btn-wrapper").forEach((el) => {

      let observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // console.log("Enter");
            // console.log("VISIBLE"); // do things if visible
            entry.target.querySelector(".subscribe-btn").classList.add("subscribe-btn--fixed");
            return;
          }
          console.log("Leave");
          if (entry.boundingClientRect.top > 0) {
            // console.log("BELOW"); // do things if below
            entry.target.querySelector(".subscribe-btn").classList.remove("subscribe-btn--fixed");
          } else {
            // console.log("ABOVE"); // do things if above
            entry.target.querySelector(".subscribe-btn").classList.add("subscribe-btn--fixed");
          }
        });
      });

      observer.observe(el);
    })


  };
  return { init: setup };
})();

export default SubscribeBtn;
