/* For videos which have a different aspect ratio */
import { resizeEvent } from './var.js';

const responsiveVideo = (function () {
  var $ckeIframe, iframeTimeout;

  function setup() {
    $ckeIframe = $('.ckec iframe');

    $ckeIframe.each(function () {
      var $this = $(this);

      if ($this.attr('style')) {
        if ($this.attr('style').match(/width\:100\%/) && $this.attr('style').match(/height\:100\%/)) {
          return;
        }
      }

      $this.attr('data-ori-width', $this.attr('width'));
      $this.attr('data-ori-height', $this.attr('height'));
    });

    $(window)
      .on(resizeEvent, function () {
        if (iframeTimeout) {
          clearTimeout(iframeTimeout);
          iframeTimeout = null;
        }

        iframeTimeout = setTimeout(function () {
          iframeResize();
        }, 100);
      })
      .trigger(resizeEvent);
  }

  function iframeResize() {
    $ckeIframe.each(function () {
      var $this = $(this),
        oriHeight = $this.attr('data-ori-height'),
        oriWidth = $this.attr('data-ori-width'),
        ratio = oriHeight / oriWidth,
        parentWidth = $ckeIframe.parent().width(),
        newWidth = Math.min(parentWidth, oriWidth);

      $this.css({
        width: newWidth,
        height: newWidth * ratio,
      });
    });
  }
  return {
    init: setup,
    triggerResize: iframeResize,
  };
})();

export default responsiveVideo;
