import { langArg } from './lang';
import ImagesLoaded from 'imagesloaded';

ImagesLoaded.makeJQueryPlugin();

var infiniteWithList = (function () {
  var $container,
    $btnLoadNextPage,
    finishedMsg = langArg({
      en: 'No more items to load',
      tc: '所有項目已經加載完畢',
      sc: '所有項目已经加载完毕',
    }),
    option = {
      navSelector: '.pager',
      nextSelector: '.pager__link',
      itemSelector: '.js-infinite-item',
      // Use in development site
      // pathParse: function(path, nextPage) {
      //   var re = new RegExp('^(.*?page)' + nextPage + '(.*|$)');
      //   path = path.match(re).slice(1);
      //   return path;
      // },
      loading: {
        selector: '.pager',
        finishedMsg: '',
        img: './assets/shared/img/loading.gif',
        msgText: '',
      },
      errorCallback: function () {
        $btnLoadNextPage.remove();
      },
    };

  function setup() {
    var config = $.extend({}, option);

    $container = $('.js-infinite-list');
    $btnLoadNextPage = $('.js-load-next-page');

    $container.infinitescroll(
      config,
      // trigger callback
      function (newElements) {
        // hide new items while they are loading
        var $newElems = $(newElements).css({ opacity: 0 });
        // ensure that images load before adding to masonry layout
        $newElems.imagesLoaded(function () {
          //triggered picturefill again if img use blank src for win7 ie11 issue
          picturefill();

          // show elems now they're ready
          $newElems.animate({ opacity: 1 });

          $btnLoadNextPage.blur();
        });
      }
    );
  }

  return {
    init: setup,
  };
})();

var infiniteWithMasonry = (function () {
  var $container,
    $btnLoadNextPage,
    finishedMsg = langArg({
      en: 'No more items to load',
      tc: '所有項目已經加載完畢',
      sc: '所有項目已经加载完毕',
    }),
    optionMasonry = {
      columnWidth: '.masonry-sizer',
      gutter: '.gutter-sizer',
      itemSelector: '.js-infinite-item',
      percentPosition: true,
      horizontalOrder: true,
    },
    optionInfscr = {
      navSelector: '.pager',
      nextSelector: '.pager__link',
      itemSelector: '.js-infinite-item',
      // Use in development site
      // pathParse: function(path, nextPage) {
      //   var re = new RegExp('^(.*?page)' + nextPage + '(.*|$)');
      //   path = path.match(re).slice(1);
      //   return path;
      // },
      loading: {
        selector: '.pager',
        finishedMsg: '',
        img: './assets/shared/img/loading.gif',
        msgText: '',
      },
      errorCallback: function () {
        $btnLoadNextPage.remove();
      },
    };

  function setup() {
    var configMasonry = $.extend({}, optionMasonry),
      configInfscr = $.extend({}, optionInfscr);

    $container = $('.js-infinite-masonry');
    $btnLoadNextPage = $('.js-load-next-page');

    $container.find('.js-infinite-item').css({ opacity: 0, transform: 'scale(0)' });

    $container.imagesLoaded(function () {
      $container.find('.line-clamp:not(.line-clamp--2)').addClass('line-clamp--2');
      $container.find('.js-infinite-item').animate(
        { opacity: 1, transform: 'scale(1)' },
        {
          duration: 400,
          easing: 'swing',
          step: function (now, fx) {
            $(this).css('transform', 'scale(' + now + ')');
            $(this).css('opacity', now);
          },
        }
      );
      $container.masonry(configMasonry);
    });

    $container.infinitescroll(
      configInfscr,
      // trigger callback
      function (newElements) {
        // hide new items while they are loading
        var $newElems = $(newElements).css({ opacity: 0 });
        // ensure that images load before adding to masonry layout
        $newElems.imagesLoaded(function () {
          //triggered picturefill again if img use blank src for win7 ie11 issue
          picturefill();
          $container.find('.line-clamp:not(.line-clamp--2)').addClass('line-clamp--2');
          // show elems now they're ready
          $newElems.animate({ opacity: 1 });
          $container.masonry('appended', $newElems);

          $btnLoadNextPage.blur();
        });
      }
    );
  }

  return {
    init: setup,
  };
})();

var clickToLoadMore = (function () {
  var $container, $btnLoadNextPage;

  function setup() {
    $container = $('.js-infinite');
    $btnLoadNextPage = $('.js-load-next-page');

    //kill scroll binding
    $(window).unbind('.infscr');

    // hook up the manual click guy.
    $btnLoadNextPage.click(function () {
      $container.infinitescroll('retrieve');
      return false;
    });

    // remove the paginator when we're done.
    // $(document).ajaxError(function(e, xhr, opt) {

    //   if (xhr.status == 404) {
    //     $btnLoadNextPage.remove();
    //   }
    // });
  }

  return {
    init: setup,
  };
})();

var infiniteFilterItems = (function () {
  function setup() {
    $(document).on('click', '.js-filter-trigger', function (e) {
      e.preventDefault();
      $(window).unbind('.infscr');

      var $btn = $(this);
      var $currentSelectedBtn = $btn.parents('.filter-tools').find('.js-filter-trigger.is-selected');
      var $path = $btn.attr('href');
      var $container = $('.js-infinite-container');
      var $loading = $('.loading-indicator');

      $currentSelectedBtn.removeClass('is-selected');
      $btn.addClass('is-selected');

      $container.fadeOut(500, function () {
        $container.find('.js-infinite-item').css({ opacity: 0, transform: 'scale(0)' });

        $loading.fadeIn(300);
        $container.children().remove();
        $.ajax({
          url: $path,
        }).done(function (data, textStatus, xhr) {
          $container.prepend(data);
          $loading.fadeOut(300);
          $container.fadeIn(500);
          infiniteWithMasonry.init();

          $container.find('.js-infinite-item').animate(
            { opacity: 1, transform: 'scale(1)' },
            {
              duration: 400,
              easing: 'swing',
              step: function (now, fx) {
                $(this).css('transform', 'scale(' + now + ')');
                $(this).css('opacity', now);
              },
            }
          );

          return false;
        });
      });
    });
  }

  return {
    init: setup,
  };
})();


export { infiniteWithList, infiniteWithMasonry, clickToLoadMore, infiniteFilterItems };
