const customCheckable = (function() {
  function setup() {
    const customCheckboxes = document.querySelectorAll(".custom-checkbox");
    const customRadios = Array.from(document.querySelectorAll(".custom-radio"));
    const radios = customRadios.map(c => c.querySelector("input[type='radio']"));
    const radioGroups = {};
    
    customRadios.forEach(customRadio => {
      const textInput = customRadio.querySelector("input[type='text']");
      const radioInput = customRadio.querySelector("input[type='radio']");
      const name = radioInput.getAttribute('name');
      if (name) {
        if (!radioGroups[name]) {
          radioGroups[name] = radios.filter(i => i.getAttribute('name') === name);
        }

        if (textInput) {
          if (!radioInput.checked || radioInput.hasAttribute('disabled')) {
            textInput.setAttribute('disabled', "");
          }

          radioInput.addEventListener("deselect", function(e) {
            textInput.setAttribute('disabled', "");
          });
        }

        // change event for radio input only covers selection, not deselection
        radioInput.addEventListener("change", function(e) {
          radioGroups[name].forEach(input => {
            // enable text input if radio input is selected
            textInput?.removeAttribute('disabled');
            // trigger custom 'deselect' event on related radio inputs
            if (input !== e.currentTarget) {
              input.dispatchEvent(new Event('deselect'));
            }
          });
        });
      }
    });

    customCheckboxes.forEach(customCheckbox => {
      const textInput = customCheckbox.querySelector("input[type='text']");
      const checkboxInput = customCheckbox.querySelector("input[type='checkbox']");

      if (textInput) {
        if (!checkboxInput.checked || checkboxInput.hasAttribute('disabled')) {
          textInput.setAttribute('disabled', "");
        }

        checkboxInput.addEventListener("change", function(e) {
          textInput.toggleAttribute('disabled', !e.currentTarget.checked);
        });
      }
    });
  }

  return { init: setup};
})();

export default customCheckable;