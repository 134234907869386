import { langArg } from './lang';
import MediaPlayer from './mediaPlayer';
import { convertYoutubeEmbedLink } from './commonFn';

const lightbox = (function () {
  var downloadTxt = langArg({
      en: 'Download',
      tc: '下載',
      sc: '下载',
    }),
    prevTxt = langArg({
      en: 'Previous',
      tc: '上一頁',
      sc: '前一页',
    }),
    nextTxt = langArg({
      en: 'Next',
      tc: '下一頁',
      sc: '后一页',
    }),
    closeTxt = langArg({
      en: 'Close',
      tc: '關閉',
      sc: '关闭',
    }),
    loadingTxt = langArg({
      en: 'Loading...',
      tc: '加載中...',
      sc: '加载中...',
    }),
    errorTxt = langArg({
      en: 'Failed to load content.',
      tc: '加載內容失敗.',
      sc: '加载内容失败.',
    }),
    a11yTxt = langArg({
      en: {
        transcript: 'Transcript',
        kbShortcut: 'Keyboard Shortcut for Video',
        shortcut: "Shortcut",
        action: "Action",
      },
      tc: {
        transcript: '視像文字稿',
        kbShortcut: '鍵盤快捷鍵',
        shortcut: "快捷鍵",
        action: "動作",
      },
      sc: {
        transcript: '视像文字稿',
        kbShortcut: '键盘快捷键',
        shortcut: "快捷键",
        action: "动作",
      },
    }),
    shortcutDict = langArg({
      en: {
        "Space": "Pause/Play video playback",
        "Enter": "Pause/Play video playback",
        "M": "Mute/Unmute video volume",
        "↑ and ↓": "Increase and decrease volume by 10%",
        "← and →": "Seek backward or forward by 5 seconds",
        "0-9": "Seek from 0% to 90% of the video",
        "F": "Enter or exit fullscreen"
      },
      tc: {
        "Space": "暫停/播放影片",
        "Enter": "暫停/播放影片",
        "M": "靜音/取消靜音影片的音量",
        "↑ 與 ↓": "增加或減少音量10%",
        "← 與 →": "向前或向後搜尋5秒",
        "0-9": "跳至不同的影片播放進度 (0% 到 90%)",
        "F": "進入或退出全屏幕"
      },
      sc: {
        "Space": "暂停/播放影片",
        "Enter": "暂停/播放影片",
        "M": "静音/取消静音影片的音量",
        "↑ 与 ↓": "增加或减少音量10%",
        "← 与 →": "向前或向后搜索5秒",
        "0-9": "跳至不同的影片播放进度 (0% 到 90%)",
        "F": "进入或退出全屏幕"
      },
    }),
    option = {
      delegate: 'a',
      removalDelay: 300,
      //mainClass: 'mfp-fade',
      gallery: {
        enabled: true,
        preload: 0,
      },
      image: {
        titleSrc: function (item) {
          return item.el.attr('data-download')
            ? item.el.attr('data-title') +
                ' <a class="btn-download-img" href="' +
                item.el.attr('data-download') +
                '" target="_blank" download>' +
                downloadTxt +
                '</a>'
            : item.el.attr('data-title');
        },
      },
      closeMarkup: '<button title="%title%" type="button" class="mfp-close"></button>',
      iframe: {
        markup: `
          <div class="d-flex justify-content-end">
            <button type="button" title="%title%" class="mfp-close"></button>
          </div>
          <div class="mfp-iframe-scaler">
            <iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>
          </div>
          <div class="mfp-footer">
            <a class="btn js-transcript" target="_blank">${a11yTxt.transcript}</a>
            <div class="video-kb-shortcut dropup">
              <button class="btn btn--outline btn--thin dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span class="video-kb-shortcut__label">${a11yTxt.kbShortcut}</span>
                <span class="panel__trigger-ico ico ico--dropdown-arrow"></span>
              </button>
              <div class="panel__content video-kb-shortcut__content dropdown-menu">
                <div class="panel__inner">
                  <table class="video-kb-shortcut__table no-general-style">
                    <thead>
                      <tr>
                        <th>${a11yTxt.shortcut}</th>
                        <th>${a11yTxt.action}</th>
                      </tr>
                    </thead>
                    <tbody>
                      ${
                        Object.entries(shortcutDict).map(([key, value]) => {
                          return `<tr><td>${key}</td><td>${value}</td></tr>`;
                        }).join('')
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        `
      },
      callbacks: {
        elementParse: function (item) {
          if (item.el.hasClass('mfp-iframe')) {
            let href = item.el.attr('href');
            if (!href) return;
            if (href.includes('.mp4') || href.includes('.m3u8')) {
              item.src = createFrameDoc(href);
            } else if (href.includes('youtu.be') || href.includes('youtube.com')) {
              item.src = convertYoutubeEmbedLink(href);
            }
          }
        },
        markupParse: function (template, values, item) {
          // Triggers each time when content of popup changes

          const keyboardShortcutButton = template.filter("div.mfp-footer").find(".video-kb-shortcut");
          const isVideo = values.iframe_src.includes(".mp4") || values.iframe_src.includes('.m3u8') || values.iframe_src.includes('youtu.be') || values.iframe_src.includes('youtube.com');

          if (isVideo) {
            keyboardShortcutButton.show();
          } else {
            keyboardShortcutButton.hide();
          }
        },
        beforeOpen: function () {
          //console.log('beforeOpen');
          //var $item = $(this.currItem.el)

          var animEffect = this.st.el.attr('data-effect');

          if (animEffect) {
            this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure mfp-with-anim');

            this.st.mainClass = animEffect;
          } else {
            this.st.mainClass = 'mfp-fade';
          }
        },
        open: function () {
          // console.log('open');
          let _this = this;

          if (this.currItem.src.includes('.mp4') || this.currItem.src.includes('.m3u8')) {
            setTimeout(() => {
              let iframe, doc, playBtn;
              iframe = _this.content.find('iframe')[0];
              if (iframe) {
                doc = iframe.contentDocument;
                playBtn = doc.querySelector('.plyr__control--overlaid[data-plyr="play"]');
                playBtn ? playBtn.focus() : doc.documentElement.focus();
              }
            }, 500);
          }

          this.content.find('.js-player').each(function () {
            if (!this.player) {
              this.player = new MediaPlayer(this);
              this.player.init();
            }
          });

          const $mfpFooter =  this.contentContainer.find('.mfp-footer');
          $mfpFooter.on('click', function(e) {
            if (e.target !== e.currentTarget) e.stopPropagation();
          });
          $mfpFooter.find('.dropdown-toggle').dropdown({
            offset: '0, 12',
            flip: false,
          });

          const transcript = this.currItem.el.attr('data-transcript');
          if (transcript) {
            $mfpFooter.find('.js-transcript').attr('href', transcript);
          } else {
            $mfpFooter.find('.js-transcript').remove();
          }
        },
        change: function () {
          //console.log('change');
        },
        close: function () {
          if (this.currItem.src.startsWith('blob:')) {
            URL.revokeObjectURL(this.currItem.src);
          }

          if (this.currItem.type === 'inline') {
            this.currItem.inlineElement.find('video').each(function () {
              this.pause();
            });
          }
        },
      },
    };

  $.extend(true, $.magnificPopup.defaults, {
    tClose: closeTxt,
    tLoading: loadingTxt,
    gallery: {
      tPrev: prevTxt,
      tNext: nextTxt,
      tCounter: '%curr% / %total%', // Markup for "1 of 7" counter
    },
    image: {
      tError: errorTxt,
    },
    ajax: {
      tError: errorTxt,
    },
  });

  function setInlineIDs() {
    $('a.mfp-inline.autoindex').each(function (idx, el) {
      var $this = $(this);
      $this.attr('href', `#mfp-inline-popup-${idx}`);
      $this.next('.mfp-hide').attr('id', `mfp-inline-popup-${idx}`);
    });
  }

  function createFrameDoc(videoSrc) {
    const origin = window.location.origin;
    const typeClass = videoSrc.includes('.mp4') ? 'native-type' : videoSrc.includes('.m3u8') ? 'stream-type' : '';
    const urlObj = new URL(videoSrc, window.location.href);
    const isAutoplay = urlObj.searchParams.get('autoplay') === '1';
    urlObj.search = '';
    urlObj.hash = '';

    let html = `
      <html lang="${document.documentElement.getAttribute('lang')}">
        <head>
          <link rel="stylesheet" href="${origin}/assets/themes/main/css/frame-video.css">
        </head>
        <body>
          <video class="js-player ${typeClass}" ${isAutoplay ? 'autoplay' : ''} controls playsinline data-src="${urlObj.toString()}"></video>
          <script src="${origin}/assets/themes/main/js/frame-video.js" type="module"></script>
        </body>
      </html>
    `;
    return URL.createObjectURL(new Blob([html], { type: 'text/html' }));
  }

  function setup() {
    var $lightbox = $('.js-lightbox-container'),
      config = $.extend({}, option);

    setInlineIDs();

    $lightbox.each(function () {
      $(this).magnificPopup(config);
    });
  }

  return {
    init: setup,
  };
})();

export default lightbox;
