// import { getURLSearchParams, setUrlSearchParam } from '@shared/js/scripts/urlSearchParams';
import animateOnScroll from '@shared/js/scripts/animateOnScroll';
import { getURLSearchParams, setUrlSearchParam } from '@shared/js/scripts/urlSearchParams';

const tabNav = (() => {

  const displayTab = (component, index) => {
    let tabs = $(component).find(".tab-nav-content");
    let btns = $(component).find(".tab-nav__tab");

    tabs.toggleClass("tab-nav-content--show", false);
    tabs.toggleClass("tab-nav-content--hidden", true);

    // update content display
    $(tabs[index]).toggleClass("tab-nav-content--show", true);
    $(tabs[index]).toggleClass("tab-nav-content--hidden", false);

    // update tab display
    btns.toggleClass("tab-nav__tab--selected", false);
    $(btns[index]).toggleClass("tab-nav__tab--selected", true);

    // update tab select in mobile
    let select = $(component).find(".tab-nav-mob select");
    select.val(index.toString());
    select.selectpicker('refresh');

    // refresh AOS
    animateOnScroll.init();

    // setup search param
    setUrlSearchParam("tab", index);
  }

  const setupTabs = (component, nav) => {
    let btns = $(nav).find(".tab-nav__btn");
    btns.each((i, e) => {
      $(e).on("click", () => {
        displayTab(component, i);
      });
    });

    let toggles = $(component).find(".tab-table-collapse__toggle");

    toggles.each((i, e) => {
      $(e).on("click", () => {
        let content = $(component).find(".tab-nav__content");
        content.toggleClass("tab-nav__content--show");
      });
    });

  }

  const setupMobNav = (component,nav) => {
    $(nav).find("select.js-bs-select").on("changed.bs.select", (e, clickedIndex, isSelected, previousValue) => {
      displayTab(component, clickedIndex);
    });
  }

  const setup = () => {
    $(".tab-table-wrapper").each((i, e) => {
      $(e).find(".tab-nav").each((i, nav) => {
        setupTabs(e,nav);
      });

      $(e).find(".tab-nav-mob").each((i, nav) => {
        setupMobNav(e,nav);
      });

      // display tab based on search param
      let tab = getURLSearchParams("tab");
      if (tab) {
        displayTab(e, parseInt(tab));
      }
    });
  };

  return { init: setup };
})();

export default tabNav;
