import collapse from "@shared/js/scripts/collapse";
import {
  getURLSearchParams,
  setUrlSearchParam,
} from "@shared/js/scripts/urlSearchParams";

const jsFilterPressRelease = (() => {
  const setup = () => {
    let data;

    const createCollapse = (child, title) => `

            <div class="tab-table-collapse js-collapse-item">
              <div class="tab-table-collapse__inner">
                <a href="#" class="tab-table-collapse__toggle js-panel-trigger" data-toggle="collapse"
                  aria-expanded="false" role="button">
                  <p class="tab-table-collapse__title panel__heading">${title}</p>
                  <span class="tab-table-collapse__trigger-ico panel__trigger-ico ico btn btn--circle"></span>
                </a>
              
                <div class="tab-table-collapse__content panel__content collapse">
                  <div class="tab-table-collapse__content-inner ckec">
                    ${child}
                  </div>
                </div>
            </div>
          </div>
    `;

    const createRows = (data) => {

      const links = (links) => links.map(link => `<a href="${link.url}" class="outline-btn-w-icon outline-btn-w-icon--link" target="${link.target}">${link.title}</a>`).join("");

      return data.data.map((item) => {
        return item.details.map(detail => `
          <tr>
            <td>
              <p>
                ${detail.desc[0]}
              </p>
            </td>
            <td>
              <p>
                ${detail.desc[1]}
              </p>
            </td>
            <td>
              <div class="tab-table__btn-group">
                ${links(detail.links)}
              </div>
            </td>
          </tr>
          `).join("");
      }).join("");
    }

    const createTable = (data) => {

      return `
        <table class="tab-table" style="
                                        --col-1-width: 18.2926829268%;
                                        --col-2-width: 49.2073170732%;
                                        --col-3-width: 32.5%;
                                    ">
          <thead>
            <tr>
              <th scope="col">
                Date
              </th>
              <th scope="col" colspan="100" class="d-none d-md-table-cell">
                Press Release
              </th>
            </tr>
          </thead>
          <tbody>
            ${createRows(data)}
          </tbody>
        </table>
      `;
    }

    const updateButtonText = (element, showCollapse) => {
      if (showCollapse) {
        element.html(
          `${element.attr(
            "data-collapse-text"
          )} <span class="tab-table-search-bar__minus ico ico--minus"></span>`
        );
      } else {
        element.html(
          `${element.attr(
            "data-expand-text"
          )} <span class="tab-table-search-bar__minus ico ico--plus"></span>`
        );
      }
    };

    const loadData = async (url) => {
      await fetch(url)
        .then((response) => response.json())
        .then((json) => {
          data = json;
        })
        .catch((error) => console.error(error));
    };

    const renderTable = async ($form, $contentArea, data) => {
      let html = "";
      $contentArea.empty();

      data.forEach((d) => {
        html += createCollapse(createTable(d), d.title);
      });

      $contentArea.html(html);

      collapse.init();

      // update collapse button text
      let $collapseBtn = $form.find(
        ".tab-table-search-bar__collapse-all button"
      );
      let hasShown =
        $contentArea.find(".tab-table-collapse__content:not(.show)").length ==
        0;
      updateButtonText($collapseBtn, hasShown);
    };

    $(".js-filter-press-release").each(async (i, component) => {
      const dataUrl = $(component).attr("data-json");
      const $form = $(component).find("form");
      const $contentArea = $(component).find(
        ".js-filter-press-release__content"
      );
      await loadData(dataUrl);
      await renderTable($form, $contentArea, data);
    });
  };

  return {
    init: setup,
  };
})();

export default jsFilterPressRelease;
