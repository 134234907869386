const cssHasPolyfill = (function() {
  // Note: this is not a true polyfill, only a limited workaround for the :has() css actually used in the project.

  function setup() {
    if (CSS.supports("selector(:has(div))")) return;

    // src/_includes/sections/who-we-are/style.scss
    document.querySelectorAll(".expand-slider").forEach(function(el) {
      el.addEventListener("mouseover", e => {
        const activeItem = el.querySelector(".swiper-slide-active .expand-slider-item");
        if (activeItem && activeItem.contains(e.target)) {
          el.classList.add("has-item-hover");
        }
      });
      el.addEventListener("mouseout", e => {
        const activeItem = el.querySelector(".swiper-slide-active .expand-slider-item");
        if (activeItem && activeItem.contains(e.target) && !activeItem.contains(e.relatedTarget)) {
          el.classList.remove("has-item-hover");
        }
      });
      el.addEventListener("focusin", e => {
        if (e.target.matches(".swiper-slide-active .expand-slider-item")) {
          el.classList.add("has-item-focus");
        }
      });
      el.addEventListener("focusout", e => {
        if (e.target.matches(".swiper-slide-active .expand-slider-item")) {
          el.classList.remove("has-item-focus");
        }
      });
    });

    // src/_includes/components/collapse-table-w-icon/style.scss
    document.querySelectorAll(".collapse-w-icon").forEach(function(el) {
      if (el.nextElementSibling?.classList.contains("collapse-w-icon")) {
        el.classList.add("has-next-collapse-w-icon");
      }
    });

    // src/_includes/components/event-search-bar/style.scss
    document.querySelectorAll(".event-search-bar .datepicker-item").forEach(function(el) {
      if (el.querySelector("#s_date") || el.querySelector("#e_date")) {
        el.classList.add("has-sdate-edate");
      }
    });

    // src/_includes/components/form-elements/_style.scss
    document.querySelectorAll(".form-grid__col").forEach(function(el) {
      if (el.querySelector(".form-grid__holder:last-child:not(.form-grid__holder + *)")) {
        el.classList.add("has-single-holder");
      }
      if (el.querySelector(".custom-checkbox") || el.querySelector(".custom-radio")) {
        el.classList.add("has-checkable");
      }
    });
    // src/_includes/components/form-elements/_style.scss
    document.querySelectorAll(".custom-radio__group").forEach(function(el) {
      if (el.querySelector(".custom-checkbox--inline") || el.querySelector(".custom-radio--inline")) {
        el.classList.add("has-checkable-inline");
      }
    });
    // src/_includes/components/form-elements/_style.scss
    if (document.documentElement.classList.contains("supports-subgrid")) {
      document.querySelectorAll(".demo\\:form .form-grid__row").forEach(function(el) {
        if (el.querySelector(".demo\\:label")) {
          el.classList.add("has-demo-label");
        }
      });
    }

    // src/_includes/components/icon-box-grid/_style.scss
    document.querySelectorAll(".icon-box-grid").forEach(function(el) {
      if (el.parentElement?.classList.contains("component-margin")) {
        el.parentElement.classList.add("component-margin--lg");
      }
    });

    // src/_includes/components/link-list-layers/_style.scss
    document.querySelectorAll(".link-list-layers").forEach(function(el) {
      if (el.parentElement?.classList.contains("component-margin")) {
        el.parentElement.classList.add("component-margin--lg");
      }
    });

    // src/_includes/components/img-glass-txt/_style.scss
    document.querySelectorAll(".img-glass-txt").forEach(function(el) {
      if (el.querySelector(".img-glass-txt__heading")) {
        el.classList.add("has-heading");
      }
    });

    // src/_includes/components/media-bg-desc/_style.scss
    document.querySelectorAll(".media-bg-desc--no-bg").forEach(function(el) {
      if (el.nextElementSibling?.classList.contains(".case-section")) {
        el.classList.add("no-bg-has-next-case-section");
      }
    });

    // src/_includes/components/tab-table-w-small-icon/style.scss
    document.querySelectorAll(".tab-table-w-small-icon-collapse").forEach(function(el) {
      if (el.nextElementSibling?.classList.contains("tab-table-w-small-icon-collapse")) {
        el.classList.add("has-next-self");
      }

      el.querySelectorAll("tbody td:nth-child(2)").forEach(function(td) {
        if (!td.querySelectorAll("a, button").length) {
          td.classList.add("not-has-clickable");
        }
      });
    });

    // src/_includes/components/text-w-right-img-gradient/style.scss
    document.querySelectorAll(".text-w-right-img-gradient").forEach(function(el) {
      if (el.nextElementSibling?.classList.contains("icon-txt-desc-blk")) {
        el.classList.add("has-next-icon-txt-desc-blk");
      }
    });

    // src/_includes/sections/general-content/_table.scss
    document.querySelectorAll('.general-content').forEach(function(gc) {
      gc.querySelectorAll('table:not(.no-general-style) a:is([href$=".pdf"], [href$=".doc"], [href$=".docx"], [href$=".xls"], [href$=".xlsx"])').forEach(function(el) {
        if (el.querySelector("img")) {
          el.classList.add("has-img");
        }
      });

      gc.querySelectorAll(".info-table table").forEach(function(table) {
        table.querySelectorAll(":scope > tbody tr").forEach(function(tr) {
          if (tr.matches(":not(.first-col-rowspaned)")) {
            let th = tr.querySelectorAll(":scope > th");
            th.forEach(function(th) {
              if (th.nextElementSibling?.tagName === "TD") {
                th.classList.add("has-next-td");
              }
            });
          }

          if (tr.querySelector("th") && !tr.querySelector("td")) {
            tr.classList.add("has-th-only");
          }
        });

        table.querySelectorAll("td").forEach(function(td) {
          if (td.querySelector("div input:nth-child(3)")) {
            td.classList.add("has-3rd-input");
          }
        });
      });

      gc.querySelectorAll("table.themeTable").forEach(function(table) {
        table.querySelectorAll(":scope > tbody tr").forEach(function(tr) {
          if (tr.querySelector("th") && !tr.querySelector("td")) {
            tr.classList.add("has-th-only");
          }
        });

        table.querySelectorAll("a").forEach(function(a) {
          if (a.querySelector("img") && a.nextElementSibling?.tagName === "A") {
            a.classList.add("has-img-next-a");
          }
        });
      });
    });

    // src/_includes/sections/press-release/style.scss
    document.querySelectorAll(".press-release__search").forEach(function(el) {
      if (el.querySelector("div.dropdown-menu.show")) {
        el.classList.add("has-dropdown-shown");
      }
    });

    const pressFn = () => {
      setTimeout(() => {
        document.querySelectorAll(".press-release__search").forEach(function(el) {
          if (el.querySelector("div.dropdown-menu.show")) {
            el.classList.add("has-dropdown-shown");
          } else {
            el.classList.remove("has-dropdown-shown");
          }
        });
      }, 100);
    }

    document.addEventListener("click", function(e) {
      pressFn();
    });
    document.querySelectorAll(".press-release__search .dropdown-toggle").forEach(function(el) {
      el.addEventListener("click", function(e) {
        pressFn();
      });
    });

    // src/_includes/sections/press-release/style.scss
    document.querySelectorAll(".press-release__item").forEach(function(el) {
      if (el.nextElementSibling?.classList.contains("press-release__item--hide")) {
        el.classList.add("has-next-hide-item");
      }
    });

    // src/_includes/sections/search-results/style.scss
    document.querySelectorAll(".search-results-item").forEach(function(el) {
      if (el.nextElementSibling?.classList.contains("search-results-item") && el.nextElementSibling.matches(":where(:hover, :focus)")) {
        el.classList.add("has-next-hover-or-focus-item");
      }
    });

    // src/_includes/sections/success-stories/_detail.scss
    document.querySelectorAll(".stories-detail__highlight").forEach(function(el) {
      if (el.querySelector(".stories-detail__media")) {
        el.classList.add("has-media");
      }
    });

    // src/assets/shared/sass/base/_project-custom.scss
    document.querySelectorAll("html.monochrome-exclude-logo main > *").forEach(function(el) {
      if (!el.querySelector(".pin-spacer")) {
        el.classList.add("not-has-pin-spacer");
      }
    });

    // src/assets/shared/sass/ckeditor/_ckeditor.scss
    document.querySelectorAll(".ckec h2, .cke_editable h2").forEach(function(el) {
      if (el.nextElementSibling?.tagName === "HR" && el.nextElementSibling?.classList.contains("text-spliter")) {
        el.classList.add("has-next-hr-spliter");
      }
    });

    // src/assets/shared/sass/ckeditor/_table.scss
    document.querySelectorAll(".striped-border-table .table-wrapper").forEach(function(el) {
      if (el.querySelector(".striped-border-table--shadow")) {
        el.classList.add("has-striped-border-table-shadow");
      }
    });

    // src/assets/shared/sass/components/_form.scss
    document.querySelectorAll(".rounded-selectbox").forEach(function(el) {
      const bsSelect = el.querySelector(".bootstrap-select");
      if (bsSelect) {
        let toggle = bsSelect.querySelector("button.dropdown-toggle");
        if (toggle) {
          toggle.addEventListener("mouseover", e => bsSelect.classList.add("has-toggle-hover"));
          toggle.addEventListener("mouseleave", e => bsSelect.classList.remove("has-toggle-hover"));
          toggle.addEventListener("focus", e => bsSelect.classList.add("has-toggle-focus"));
          toggle.addEventListener("blur", e => bsSelect.classList.remove("has-toggle-focus"));
        }
      } else {
        el.classList.add("not-has-bootstrap-select");
      }
    });

    // src/assets/shared/sass/components/_list.scss
    document.querySelectorAll("ol:not([class])").forEach(function(el) {
      if (el.querySelector(":scope > li:nth-of-type(100)")) {
        el.classList.add("has-100th-li");
      } else if (el.querySelector(":scope > li:nth-of-type(10)")) {
        el.classList.add("has-10th-li");
      }
    });

    document.querySelectorAll(".org-chart__lv--3").forEach(function(el) {
      if (el.querySelector(".org-chart__group > .org-chart__holder > .org-chart__node:empty")) {
        el.classList.add("has-empty-node");
      }
    });

    document.querySelectorAll(".has-empty-node > li:last-of-type .org-chart__holder").forEach(function(el) {
      if (el.querySelectorAll(".org-chart__lv--4 .org-chart__group+.org-chart__group").length > 0) {
        el.classList.add("has-next-lv4-group");
      }
    });

    document.querySelectorAll(`.ckec a:is([target="_blank"], [rel="external"]):not([class])`).forEach(function(el) {
      if (el.childElementCount == 1 && el.querySelector("img")) {
        el.classList.add("has-img-only");
      }
    });
  }
  
  return { init: setup };
})();

export default cssHasPolyfill;