const footer = (() => {
  const backToTopBtn = document.querySelector('.page-foot__to-top-btn');
  const setup = () => {
    if (backToTopBtn) {
      backToTopBtn.addEventListener('click', () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      });
    }
  };
  return {
    init: setup,
  };
})();

export default footer;
