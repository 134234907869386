import SwiperSlider from '@shared/js/scripts/swiperSlider';

const formPreviewSlider = (() => {
  const formPreviewSlider = document.querySelector('.image-slider__slider');

  const setup = () => {
    if (!formPreviewSlider) return;
    const options = {
      slidesPerView: 1,
      slidesPerGroup: 1,
    };
    new SwiperSlider(formPreviewSlider, options);
  };
  return {
    init: setup,
  };
})();

export default formPreviewSlider;
