/*
 * Dependencies:
 * - Swiper
 */

import Swiper from 'swiper/bundle';
import { getLang } from '@shared/js/scripts/lang';

export default class SwiperSlider {
  constructor(__swiper__dom, __options) {
    let __this = this;

    this.elems = {
      swiper__outer: __swiper__dom,
      swiper__container: __swiper__dom.querySelector('.js-swiper'),
      swiper__items: __swiper__dom.querySelectorAll('.js-swiper-item'),
      swiper__control: __swiper__dom.querySelectorAll('.js-swiper-control'),
      swiper__current: __swiper__dom.querySelectorAll('.js-swiper-current')[0],
      swiper__playpause: null,
    };

    this.text = {
      en: {
        playPauseTxt: 'Play / Stop the slider',
        playTxt: 'Play the slider',
        pauseTxt: 'Stop the slider',
        prevTxt: 'Previous',
        nextTxt: 'Next',
        pagerTxt: 'Go to slide {{index}}',
      },
      tc: {
        playPauseTxt: '開始/暫停幻燈片',
        playTxt: '開始幻燈片',
        pauseTxt: '暫停幻燈片',
        prevTxt: '上一張',
        nextTxt: '下一張',
        pagerTxt: '到幻燈片 {{index}}',
      },
      sc: {
        playPauseTxt: '开始/暂停幻灯片',
        playTxt: '开始幻灯片',
        pauseTxt: '暂停幻灯片',
        prevTxt: '上一张',
        nextTxt: '下一张',
        pagerTxt: '到幻灯片 {{index}}',
      },
    };

    this.status = {
      lang: getLang(),
      indexBeforeReBuild: null,
    };

    const defaults = {
      effect: 'fade',
      speed: 800,
      loop: true,
      slidesPerView: 1,
      threshold: 3,
      shortSwipes: true,
      loopAdditionalSlides: 0,
      followFinger: false,
      initialSlide:
        $(this.elems.swiper__outer).find('.js-swiper-self-active').index() > 0
          ? $(this.elems.swiper__outer).find('.js-swiper-self-active').index()
          : 0,
      navigation: {
        prevEl: this.elems.swiper__outer.querySelector('.js-swiper-prev'),
        nextEl: this.elems.swiper__outer.querySelector('.js-swiper-next'),
      },
      pagination: {
        el: this.elems.swiper__outer.querySelector('.js-swiper-pagination'),
        type: 'bullets',
        clickable: true,
      },
      a11y: {
        paginationBulletMessage: __this.text[__this.status.lang]['pagerTxt'],
      },
      fadeEffect: {
        crossFade: true,
      },
      init: false,
      preventInteractionOnTransition: true,
      btnPlayPause: false,
      progressPagination: false,
    };

    $(this.elems.swiper__outer).attr('data-swiper-items', $(this.elems.swiper__items).length);

    this.opts = $.extend({}, defaults, __options);

    this.initSwiper();

    return this;
  }

  initSwiper() {
    let __this = this;

    __this.swiper = new Swiper(__this.elems.swiper__container, $.extend({}, __this.opts, __this.checkViewsNumber()));

    __this.swiper
      .on('init', function () {
        let params = this;

        __this.addPlayPause(params);

        let $activeSlide = $(params.slides[params.activeIndex]);

        __this.videoStateUpdate($activeSlide, 'play', true);
        __this.setSliderTheme($activeSlide);
        __this.setProgressBarOnBullet();

        // $(__this.elems.swiper__outer).find('.js-swiper-prev, .js-swiper-next').removeClass('d-none');

        __this.videoButtonHandler();

        if (typeof __this.opts.customAfterInit == 'function') {
          __this.opts.customAfterInit();
        }

        __this.status.indexBeforeReBuild = null;

        __this.disableFocusOnDuplicatedSlide();

        if (__this.elems.swiper__current) {
          setTimeout(function () {

            __this.setCurrent(Math.ceil(params.realIndex / params.loopedSlides) + 1);
          }, 100);
        } 
      })
      .on('slideChange', function () {
        let params = this,
          $activeSlide = $(params.slides[params.activeIndex]);

        if (__this.elems.swiper__control) {
          $(__this.elems.swiper__control).addClass('is-playing-stop');
          setTimeout(function () {
            $(__this.elems.swiper__control).removeClass('is-playing-stop');
          }, 100);
        }

        let $iframe = $activeSlide.find('iframe');
        if ($iframe.length) {
          __this.iframeSetup($iframe);
        }

        __this.setSliderTheme($activeSlide);
        if (!$activeSlide.hasClass('swiper-video-paused')) {
          __this.videoStateUpdate($activeSlide, 'play', true);
        }

        if (typeof __this.opts.customAfterChange == 'function') {
          __this.opts.customAfterChange();
        }
        setTimeout(function () {

          __this.setCurrent(Math.ceil(params.realIndex / params.loopedSlides) + 1);
        }, 100);
      })
      .on('slideChangeTransitionStart', function () {
        let params = this;

        var $prevSlide = $(params.slides[params.previousIndex]);

        __this.videoStateUpdate($prevSlide, 'pause');

        if (typeof __this.opts.customStartChange == 'function') {
          __this.opts.customStartChange(params);
        }
      })
      .on('slideChangeTransitionEnd', function () {
        let params = this;

        let $activeSlide = $(params.slides[params.activeIndex]);

        let prevSlides = params.slides;

        for (var i = 0; i < prevSlides.length; i++) {
          let $iframe = $(prevSlides[i]).not($activeSlide).find('iframe');
          if ($iframe.length) {
            __this.iframeDestory($iframe);
          }
        }

        // let $iframe = $prevSlide.find('iframe');
        // if($iframe.length) {
        //     __this.iframeDestory($iframe);
        // }

      })
      .on('autoplayStop', function () {
        if (__this.elems.swiper__control) {
          $(__this.elems.swiper__control).toggleClass('is-playing', __this.swiper.autoplay.running);
        }
      })
      .on('autoplayStart', function () {
        if (__this.elems.swiper__control) {
          $(__this.elems.swiper__control).toggleClass('is-playing', __this.swiper.autoplay.running);
        }
      })
      // .on('breakpoint', function () {
      //   __this.setProgressBarOnBullet();

      //   let params = arguments[0];

      //   if (__this.swiper && __this.swiper.initialized) {
      //     setTimeout(function () {
      //       __this.swiper.destroy();

      //       __this.restoreOriDom();

      //       __this.initSwiper();
      //     }, 700);
      //   }
      // })
      .on('loopFix', function () {});

    if (__this.opts.init == false) {
      __this.swiper.init();
    }
  }

  restoreOriDom() {
    let __this = this,
      $container = $(__this.elems.swiper__outer);

    $container.find('.swiper-slide-duplicate').remove();

    $container.find('.slider__play-pause').remove();
    __this.elems.swiper__playpause = null;

    $container
      .find('.rect-arrow')
      .removeAttr('tabindex')
      .removeAttr('role')
      .removeAttr('aria-label')
      .removeAttr('aria-disabled')
      .addClass('d-none');
  }

  addPlayPause(__slide__instance) {
    let __this = this;

    if (
      __slide__instance.params.btnPlayPause &&
      __slide__instance.params.autoplay &&
      __slide__instance.params.autoplay.delay &&
      !__this.elems.swiper__playpause
    ) {
      let $btn_pp = $('<button></button>', {
        type: 'button',
        class: 'swiper-play-pause',
        html: `<span class="sr-only sr-play">${__this.text[__this.status.lang].playTxt}</span>\n
                <span class="sr-only sr-pause">${__this.text[__this.status.lang].pauseTxt}</span>`,
      });

      __this.elems.swiper__playpause = $btn_pp[0];

      __this.togglePlayClass();

      $(__this.elems.swiper__control).append($btn_pp);

      $btn_pp.on('click', function (e) {
        e.preventDefault();
        var $b = $(this);
        __this.pausePlayEvent();
      });
    }
  }

  setSliderTheme($__slide) {
    let __this = this;
    if ($__slide.attr('data-swiper-theme')) {
      $(__this.elems.swiper__outer).attr('data-swiper-theme', $__slide.attr('data-swiper-theme'));
    }
  }

  pausePlayEvent() {
    let __this = this;
    let $playpause = $(__this.elems.swiper__playpause);

    if (__this.swiper.autoplay.running) {
      __this.swiper.autoplay.stop();
    } else {
      __this.swiper.autoplay.start();
    }

    __this.togglePlayClass();
  }

  togglePlayClass() {
    let __this = this;

    $(__this.elems.swiper__control).toggleClass('is-playing', __this.swiper.autoplay.running);
  }

  setProgressBarOnBullet() {
    let __this = this;

    if (__this.opts.autoplay && __this.opts.autoplay.delay && __this.opts.progressPagination) {
      $(__this.elems.swiper__outer)
        .find('.swiper-pagination-bullet')
        .css({
          '--animation-duration': `${__this.opts.autoplay.delay}ms`,
        });
    }
  }

  iframeSetup($__iframe) {
    $__iframe.attr('src', $__iframe.attr('data-src'));
  }

  iframeDestory($__iframe) {
    $__iframe.attr('src', '//about:blank');
  }

  videoSetup($__video) {
    let __this = this;
    $__video[0].removeEventListener('ended', __this.videoEnded);
    setTimeout(function () {
      $__video[0].addEventListener('ended', __this.videoEnded($__video), false);
    }, 1);
  }

  videoStateUpdate($__slide, __play, __startFromStart) {
    let __this = this;

    $__slide.find('video').each(function () {
      var $video = $(this);

      if (__play == 'play' && !$video.is('.js-swiper-video-noauto')) {
        $video[0].pause();
        if (__startFromStart) {
          $video[0].currentTime = 0;
        }
        setTimeout(function () {
          if (!queryTest.isXS() && !queryTest.isSM()) {
            $video[0].play();
          }
        }, 20);
      } else if (__play == 'pause') {
        $video[0].pause();
        videoCover.restore($__slide);
      }

      __this.videoSetup($video);
    });
  }

  videoEnded($__video) {
    let __this = this;
    $v = $__video;

    if (__this.swiper.autoplay && !__this.swiper.autoplay.running) {
      __this.videoStateUpdate($v, 'play', true);
    } else if (__this.swiper.autoplay && __this.swiper.autoplay.running) {
      // __this.swiper.params.autoplay.disableOnInteraction = true;
      // __this.swiper.slideNext();
    }
  }

  videoButtonHandler() {
    let __this = this;
    let $__videoButton = $('.swiper-video-button');
    $__videoButton.each(function () {
      $(this).on('click', function (event) {
        let $activeSlide = __this.swiper.$el.find('.swiper-slide-active');
        let $activeSlideDup = __this.swiper.$el.find('.swiper-slide-duplicate-active');
        if ($activeSlide.hasClass('swiper-video-paused')) {
          __this.videoStateUpdate($activeSlide, 'play', true);
          $activeSlide.removeClass('swiper-video-paused');
          $activeSlide.find('.ico--play').removeClass('ico--play').addClass('ico--pause fade-in');
          $activeSlide.find('.btn-text').text('Pause video').addClass('fade-in');
          $activeSlideDup.removeClass('swiper-video-paused');
          $activeSlideDup.find('.ico--play').removeClass('ico--play').addClass('ico--pause fade-in');
          $activeSlideDup.find('.btn-text').text('Pause video').addClass('fade-in');
        } else {
          __this.videoStateUpdate($activeSlide, 'pause', true);
          $activeSlide.addClass('swiper-video-paused');
          $activeSlide.find('.ico--pause').removeClass('ico--pause').addClass('ico--play fade-in');
          $activeSlide.find('.btn-text').text('Play video').addClass('fade-in');
          $activeSlideDup.addClass('swiper-video-paused');
          $activeSlideDup.find('.ico--pause').removeClass('ico--pause').addClass('ico--play fade-in');
          $activeSlideDup.find('.btn-text').text('Play video').addClass('fade-in');
        }
        event.stopPropagation();
        event.preventDefault();
      });
      $('.ico--pause, .ico--play, .btn-text', this).on(animationEnd, function () {
        $(this).removeClass('fade-in');
      });
    });
  }

  checkViewsNumber() {
    let __this = this;

    let options = __this.opts;

    let extra__option = {};

    let screen__width = $(window).width(),
      breakpoints = typeof options.breakpoints !== undefined ? options.breakpoints : null,
      default__pre__view = options.slidesPerView;

    let view__number = default__pre__view ? default__pre__view : 1;

    if (breakpoints) {
      for (const [key, value] of Object.entries(breakpoints)) {
        if (
          parseInt(key) <= screen__width &&
          typeof value.slidesPerView !== undefined &&
          value.slidesPerView !== 'auto'
        ) {
          view__number = value.slidesPerView;
        }
      }
    }

    if (view__number !== 'auto' && $(__this.elems.swiper__items).length <= view__number) {
      extra__option.loop = false;
      extra__option.autoplay = false;
      extra__option.btnPlayPause = false;
      $(__this.elems.swiper__outer).addClass('js-swiper--no-need-loop');
    } else {
      $(__this.elems.swiper__outer).removeClass('js-swiper--no-need-loop');
    }

    return extra__option;
  }

  destroy() {
    this.swiper.destroy();
    $(this.elems.swiper__container).removeAttr('style');
    this.elems.swiper__control.innerHTML = '';
  }

  disableFocusOnDuplicatedSlide() {
    const swiperOuterDom = this.elems.swiper__outer;
    const duplicateSlides = swiperOuterDom.querySelectorAll(".swiper-slide-duplicate").forEach(slide => {
      slide.tabIndex = -1;

      slide.querySelectorAll("button, a").forEach(focusable => {
        focusable.tabIndex = -1;
      })
    });
  }

  setCurrent(current) {
    let __this = this;
    if(!__this.elems.swiper__current) return;
    __this.elems.swiper__current.innerHTML = `<span>${current}</span><span> / </span><span>${ __this.elems.swiper__items.length}</span>`;
  }
}
