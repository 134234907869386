import SwiperSlider from '@shared/js/scripts/swiperSlider';
import { gsap } from "gsap";
// import { SplitTextAnimation } from '@shared/js/scripts/splitTextAnimation.js'
import { ScrollTrigger } from "gsap/ScrollTrigger";

const whoweare = (()=>{

    const setupBgSwiper = (dom) => {
      return new SwiperSlider(dom, {
        btnPlayPause: false,
        autoplay: false,
        loop: true,
        effect: 'fade',
      });
    };

    const setupPicSwiper = (dom, subSwiper) => {
      return new SwiperSlider(dom, {
        btnPlayPause: true,
        autoplay: { delay: 8000, waitForTransition: true, disableOnInteraction: false },
        loop: true,
        effect: 'swipe',
        slidesPerView: 1.001,
        spaceBetween: 100,
        // Responsive breakpoints >= value then apply
        breakpoints: {
            991: {
            spaceBetween: 30
            },
            1250: {
            spaceBetween: 35
            },
            1450: {
            spaceBetween: 40
            },
            1650: {
            spaceBetween: 45
            },
            1750: {
            spaceBetween: 50
            },
            1850: {
            spaceBetween: 55
            }
        },
        // controller: {
        //     control: subSwiper,
        //     by: 'slide'
        // }
      });
    };

    const syncSlider = (mainSwiper, subSwiper) => {
        subSwiper.swiper.slideToLoop(mainSwiper.swiper.realIndex, 0);
    
        mainSwiper.swiper.on('slideNextTransitionStart', (s) => {
            subSwiper.swiper.slideToLoop(mainSwiper.swiper.realIndex);
        });

        mainSwiper.swiper.on('slidePrevTransitionStart', (s) => {
            subSwiper.swiper.slideToLoop(mainSwiper.swiper.realIndex);
        });
    }

    const updateBgMobileHeight = (component) => {
        setTimeout(() => {
            let height = component.querySelector(".expand-slider__content-wrapper").offsetHeight;
            component.querySelector(".expand-slider-bg").style.setProperty("--bg-mobile-height", `${height}px`);
        }, 10);

    }

    const asyncTimeout = (ms = 100) => {
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve();
          }, ms);
        });
      };               

    const getDistanceBetweenTwoElement = (el1, el2) => {
      // get the bounding rectangles
      var div1rect = el1.getBoundingClientRect();
      var div2rect = el2.getBoundingClientRect();

      // get div1's center point
      var div1x = div1rect.left + div1rect.width/2;
      var div1y = div1rect.top + div1rect.height/2;

      // get div2's center point
      var div2x = div2rect.left + div2rect.width/2;
      var div2y = div2rect.top + div2rect.height/2;

      // calculate the distance using the Pythagorean Theorem (a^2 + b^2 = c^2)
      var distanceSquared = Math.pow(div1x - div2x, 2) + Math.pow(div1y - div2y, 2);
      var distance = Math.sqrt(distanceSquared);
    }

    const setupSloganAnimation = (component) => {
        const desc = component.querySelector(`.expand-slider__desc`);
        const slogan = component.querySelector(`.expand-slider-slogan`)
        const contentWrapper = component.querySelector(`.expand-slider__content-wrapper`)
        const sliderBox = component.querySelector(`.expand-slider-box`)

        let isInView = false;

        const goDown = async () => {
            // console.log("goDown");
            if(!slogan.classList.contains("expand-slider-slogan--bottom")) {
                slogan.classList.add("expand-slider-slogan--bottom");
            }
        }

        const goUp = async () => {
            // console.log("goUp");
            slogan.classList.remove("expand-slider-slogan--bottom");
        }

        const mm = gsap.matchMedia();

        const tlFn = (context) => {

          // console.log(getDistanceBetweenTwoElement(component.querySelector(".expand-slider-box"), component.querySelector(".expand-slider__desc")));
          
          const tl = gsap
            .timeline({
              scrollTrigger: {
                trigger: () => sliderBox,
                start: () => "center center", // when the #{first value} of the trigger hits the #{second value} of the viewport
                // end: () => "+=1600", // end after scrolling #{val} beyond the start
                // end: () => component.querySelector(".expand-slider-box").clientHeight / 2 + component.querySelector(".expand-slider__bottom").clientHeight / 2,
                // end: () => getDistanceBetweenTwoElement(component.querySelector(".expand-slider-box"), component.querySelector(".expand-slider__desc")),
                scrub: true,
                pin: false,
                invalidateOnRefresh: true,
                // markers: true,  // for debug
              }
            });

            tl.fromTo(slogan, {
              marginTop: `${(-14 / 1600) * 100}%`,
              fontSize: `min(2.708vw, 52px)`,
              width: `${800/52}em`,
              height: `${468/52}em`,
              paddingInline: `${(70/1600) * 100}% ${(80/1600) * 100}%`,
              paddingBottom: "0vw",
            },
            {
              marginTop: "45.375%",
              fontSize: `min(2.292vw, 44px)`,
              paddingInline: `${(50 / 1600) * 100}% 0vw`,
              width: `${630/44}em`,
              paddingBottom: `${50 / 1920 * 100}vw`,
              ease: "power1.inOut",
            });

            // window.addEventListener("resize", () => {
            //   if(isInView) {
            //     component.scrollIntoView();
            //   } else {
            //     tl.scrollTrigger.refresh();
            //   }
            // })

            // window.addEventListener("scroll", () => {
            //   console.log("tl progress:", tl.progress());
            // })
          }

        // set up animations for larger screens
        mm.add("(min-width: 991px)", tlFn);


        const observer = new window.IntersectionObserver(([entry]) => {
            // console.log(entry.boundingClientRect)
            if (entry.isIntersecting) {
            //   console.log('Enter')
            //   console.log("VISIBLE")
              // goDown();
              isInView = true;
              return
            } else {
              isInView = false;
            }
            // console.log('Leave')
            // if ((entry.boundingClientRect.top + entry.boundingClientRect.height) > window.innerHeight * 0.375) {
            if ((entry.boundingClientRect.top + entry.boundingClientRect.height) > 0) {
            //   console.log("BELOW")
              // goUp();
            } else {
            //   console.log("ABOVE")
              // goDown();
            }
          }, {
            root: null,
            threshold: 0,
            // rootMargin: "50% 0%"
            // rootMargin: '-50% 0% -50% 0%',
            // rootMargin: '-37.5% 0% -37.5% 0%',
          })
          
        observer.observe(slogan);
    }

    const setupBgImg = (component) => {

        const innerBg = component.querySelector(".expand-slider__inner-bg");
        
        gsap.to(innerBg, {
          scrollTrigger: {
            trigger: innerBg,
            start: () => "top top",
            end: () => "bottom bottom",
            pin: true,
            scrub: true,
            // markers: true,
            anticipatePin: 1,
          }
        })

    }

    // const setupSplitText = (component) => {
    //   const title = component.querySelector(".expand-slider__title");

    //   new SplitTextAnimation(title);
    // }
    
    const setup = () => {

        const componentName = `expand-slider`;

        gsap.registerPlugin(ScrollTrigger);

        document.querySelectorAll(`.${componentName}`).forEach((component) => { 
            const picSwiper = component.querySelector(`.expand-slider-box__pic-swiper`)
            const bgSwiper = component.querySelector(`.expand-slider-bg`);
            
            const picSwiperInstance = setupPicSwiper(picSwiper);
            const bgSwiperInstance = setupBgSwiper(bgSwiper);

            syncSlider(picSwiperInstance, bgSwiperInstance);

            setupSloganAnimation(component);

            setupBgImg(component);

            updateBgMobileHeight(component);

            window.addEventListener("resize", () => {
              updateBgMobileHeight(component);
            })

            // setupSplitText(component);

        });

    }

    return {init: setup}
})()


export default whoweare;