import { langArg } from '@shared/js/scripts/lang';

const breadcrumb = (function () {
  function setup() {
    setupBreadcrumb();
  }

  function setupBreadcrumb() {
    var $menu = $('.js-breadcrumb-menu'),
      $ul = $('.js-breadcrumb-menu > ul'),
      $li = $('.js-breadcrumb-menu li');

    var lv1_links = document.querySelectorAll('a.breadcrumb-menu__link--lv1');

    var clickedTwice = true;
    $li.on({
      mouseenter: function () {
        var $this = $(this);
        $this.addClass('is-active');
      },
      mouseleave: function () {
        var $this = $(this);
        $this.removeClass('is-active');
      },
      touchstart: function () {
        var $this = $(this);
        if ($this.hasClass('is-active')) {
          clickedTwice = true;
        } else {
          clickedTwice = false;
        }
      },
    });

    $ul
      .find('.breadcrumb-menu__link')
      .on('focus', function () {
        var $this = $(this);
        $this.parents($ul).find('li').removeClass('is-active');
        $this.parents('li').addClass('is-active');
      })
      .on('blur', function () {
        var $this = $(this);
        $this.parents('li').removeClass('is-active');
      });
    lv1_links.forEach((el) => {
      el.addEventListener('click', function (e) {
        if (
          $(e.target).closest('.breadcrumb-menu__item').hasClass('has-sub') &&
          !clickedTwice
        ) {
          e.preventDefault();
        }
      });
    });

    // Workaround for dropdown menu hover on touch devices
    $menu.each(function () {
      $(this).touchMenuHover({
        forceiOS: true,
        childTag: 'div',
      });
    });

    // Press esc key to close dropdown menu
    $(document).on('keyup.menu', function (e) {
      if (e.keyCode === 27) {
        $li.removeClass('is-active');
      }
    });

    //Accessibility
    $li.filter('.is-selected').find('> a').attr('aria-current', 'true');
  }

  return {
    init: setup,
  };
})();

export default breadcrumb;
