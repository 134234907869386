import { resizeEvent } from './var.js';

const queryTest = (function ($) {
  var $xsBlk, $smBlk, $mdBlk, $lgBlk, $xlBlk, $xxlBlk, $2xBlk, current_query, new_query;

  function setup() {
    if (!$('.query-crt-xs').length) {
      $('body').append(
        '<div class="query-crt-xs query-crt"></div><div class="query-crt-sm query-crt"></div><div class="query-crt-md query-crt"></div><div class="query-crt-lg query-crt"></div><div class="query-crt-xl query-crt"></div><div class="query-crt-xxl query-crt"></div><div class="query-crt-2x query-crt"></div>'
      );
    }

    $xsBlk = $('.query-crt-xs');
    $smBlk = $('.query-crt-sm');
    $mdBlk = $('.query-crt-md');
    $lgBlk = $('.query-crt-lg');
    $xlBlk = $('.query-crt-xl');
    $xxlBlk = $('.query-crt-xxl');
    $2xBlk = $('.query-crt-2x');
    current_query = checkState();

    updateClass(current_query);
    stateChange(current_query, true);

    $(window)
      .on(resizeEvent, function () {
        new_query = checkState();

        if (new_query !== current_query) {
          updateClass(new_query);
          stateChange(new_query);
        }
        current_query = new_query;
      })
      .trigger(resizeEvent);
  }

  function checkState() {
    var state;

    if ($xsBlk.css('display') == 'block') {
      state = 'xs';
    } else if ($smBlk.css('display') == 'block') {
      state = 'sm';
    } else if ($mdBlk.css('display') == 'block') {
      state = 'md';
    } else if ($lgBlk.css('display') == 'block') {
      state = 'lg';
    } else if ($xlBlk.css('display') == 'block') {
      state = 'xl';
    } else if ($xxlBlk.css('display') == 'block') {
      state = 'xxl';
    }

    return state;
  }

  function checkMode() {
    var state = checkState();

    var isMobile = state == 'xs' || state == 'sm',
      isTablet = state == 'md';
    // isDesktop = state == 'lg' || state == 'xl' || state == 'xxl';

    if (isMobile) {
      return 'mobile';
    } else if (isTablet) {
      return 'tablet';
    } else {
      return 'desktop';
    }
  }

  function updateClass(_state) {
    $('body')
      .removeClass('js-xs js-sm js-md js-lg js-xl js-xxl')
      .addClass('js-' + _state);
  }

  function stateChange(_newQuery, entresite) {
    // if (!entresite && (_newQuery !== 'xs' || _newQuery !== 'sm')) {
    // }
    // if (!entresite) {
    // }
  }

  setup();

  return {
    init: function () {
      setup();
    },
    getCurrentState: function () {
      return checkState();
    },
    getCurrentMode: function () {
      return checkMode();
    },
    isXS: function () {
      return checkState() == 'xs';
    },
    isSM: function () {
      return checkState() == 'sm';
    },
    isMD: function () {
      return checkState() == 'md';
    },
    isLG: function () {
      return checkState() == 'lg';
    },
    isXL: function () {
      return checkState() == 'xl';
    },
    isXXL: function () {
      return checkState() == 'xxl';
    },
    is2x: function () {
      return $2xBlk.css('display') == 'block';
    },
    argF: function (opts) {
      var state = checkState();

      if (state == 'xs') {
        return opts['xs'];
      } else if (state == 'sm') {
        return opts['sm'];
      } else if (state == 'md') {
        return opts['md'];
      } else if (state == 'lg') {
        return opts['lg'];
      } else if (state == 'xl') {
        return opts['xl'];
      } else if (state == 'xxl') {
        return opts['xxl'];
      }
    },
    argModeF: function (opts) {
      var mode = checkMode();

      if (mode) {
        return opts[mode];
      }
    },
  };
})(jQuery);

export default queryTest;
