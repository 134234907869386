import SwiperSlider from '@shared/js/scripts/swiperSlider';

const imageSlider = (() => {
  const imageSlider = document.querySelector('.gallery-slider__slider');

  const setup = () => {
    if (!imageSlider) return;
    const options = {
      slidesPerView: 1,
      slidesPerGroup: 1,
    };
    new SwiperSlider(imageSlider, options);
  };
  return {
    init: setup,
  };
})();

export default imageSlider;
