const tab = (function () {
  var filePath = location.pathname,
    //filePath = (/\/$/.test(location.pathname)) ? location.pathname : location.pathname + '/',
    locationHash = encodeURI(location.hash.substring(1)),
    $navTabsLink,
    $tabDropdownTxt,
    iconDownHTML = '<span class="ico ico--down-lg" aria-hidden="true"></span>';
  //$langBtn;

  function setup() {
    $navTabsLink = $('[data-toggle="tab"]');
    $tabDropdownTxt = $('.tabs-to-dropdown .dropdown__btn');
    //$langBtn = $('.lang__item');

    //storeLangHref();
    removeBaseHref();
    triggerPushState();
    triggerPopState();
    showTabByHash();
    updateDropdownTxt();
  }

  function removeBaseHref() {
    $navTabsLink.each(function () {
      var $this = $(this),
        hash = $this.attr('href').split('#')[1];

      if (hash) {
        $this.attr('href', '#' + hash);
      }
    });
  }

  function triggerPushState() {
    $navTabsLink.on('show.bs.tab', function (e) {
      var hashIndex = $(this).attr('href').indexOf('#'),
        hash = encodeURI($(this).attr('href').substring(hashIndex));

      history.pushState(null, null, filePath + hash);
      //changeLangHref();
    });
  }

  function triggerPopState() {
    window.addEventListener('popstate', function () {
      var activeHash = encodeURI(location.hash.substring(1)),
        $activeTab = $('[data-toggle="tab"][aria-controls="' + $.escapeSelector(activeHash) + '"]');

      if ($activeTab.length) {
        $activeTab.tab('show');
        $tabDropdownTxt.html($activeTab.text() + iconDownHTML);
      }
    });
  }

  function showTabByHash() {
    var $firstTab = $('.nav-tabs > li:first .nav-tabs__link'),
      $firstTabContent = $('.tabs__content-pane:first'),
      $currentTab;

    if (location.hash) {
      $currentTab = $('[data-toggle="tab"][aria-controls="' + $.escapeSelector(locationHash) + '"]');

      if ($currentTab.length) {
        var tabHeight = $currentTab.closest('.nav-tabs').outerHeight(),
          headerHeight = $('.page-head').css('position') === 'fixed' ? $('.page-head').outerHeight() : 0,
          breathingRoom = 10;

        $('html').css({ 'scroll-padding-top': tabHeight + headerHeight + breathingRoom });
        $currentTab.tab('show');
        $tabDropdownTxt.html($currentTab.text() + iconDownHTML);

        // setTimeout(function() {
        //   window.scrollTo(0, 0);
        // }, 100);
      } else {
        $firstTab.addClass('active');
        $firstTabContent.addClass('active show');
        $tabDropdownTxt.html($firstTab.text() + iconDownHTML);
      }
    } else {
      $firstTab.addClass('active');
      $firstTabContent.addClass('active show');
      $tabDropdownTxt.html($firstTab.text() + iconDownHTML);
    }
  }

  function updateDropdownTxt() {
    $navTabsLink.on('show.bs.tab', function () {
      var $this = $(this),
        selectedItem = $this.text();

      $tabDropdownTxt.html(selectedItem + iconDownHTML);
    });
  }

  // function storeLangHref () {
  //   $langBtn.each(function() {
  //     var $this = $(this),
  //         thisHref = $this.attr('href');

  //     $this.attr('data-base', thisHref);
  //   });
  // }

  // function changeLangHref() {
  //   $langBtn.each(function() {
  //     var $this = $(this),
  //         baseHref = $this.data('base');

  //     $this.attr('href',baseHref+encodeURI(location.hash));
  //   });
  // }

  return {
    init: setup,
  };
})();

export default tab;
