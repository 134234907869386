const galleryListing = (()=>{

    const setupShowMoreButton = (component) => {
        const btn = $(component).find(".gallery-listing__more-btn");
        const items = $(component).find(".gallery-listing-item");

        // Hide all items after the 9th
        items.slice(9).toggleClass("gallery-listing-item--hide", true);

        btn.on("click", (e) => {
            e.preventDefault();
            const items = $(component).find(".gallery-listing-item--hide");

            // Show 9 more items
            const itemsToShow = items.slice(0, 9);
            itemsToShow.toggleClass("gallery-listing-item--hide", false);
            itemsToShow.first().focus();

            // Hide the button if there are no more items to show
            if ($(component).find(".gallery-listing-item--hide").length === 0) {
                btn.toggleClass("gallery-listing__more-btn--hide", true);
            }
        });
    }    

    const setupResetBtn = (section) => {
        const resetBtns = section.querySelectorAll(".gallery-listing__search button[type='reset']");
        const bsSelects = section.querySelectorAll(".js-bs-select");

        if (!resetBtns || !bsSelects) return;

        resetBtns.forEach((btn) => {
            btn.addEventListener("click", (e) => {
                $(bsSelects).val("");
                $(bsSelects).selectpicker("refresh");
            });
        })
    }

    const setup = ()=>{
        $(".gallery-listing").each((i, component) => {
            setupShowMoreButton(component);
            setupResetBtn(component);
        });
    }

    return {init: setup}
})();

export default galleryListing;