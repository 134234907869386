function updateLv3ScrollToBottom ($lv3Wrapper, $lv3List) {
  let offset = 10;
  $lv3Wrapper.toggleClass('menu__list--lv3-wrapper-scrolled-all', $lv3List[0].scrollTop + $lv3List[0].clientHeight >= parseInt($lv3List[0].scrollHeight) - offset);
}

function updateLv3ScrollToTop ($lv3Wrapper, $lv3List) {
  let offset = 10;
  $lv3Wrapper.toggleClass('menu__list--lv3-wrapper-scrolled-top', $lv3List[0].scrollTop <= offset);
}

function setupLv3Scroll($menu) {
  let $lv3Wrappers = $menu.find(".menu__list--lv3-wrapper");

  $lv3Wrappers.each((i, lv3Wrapper) => {
    let $lv3Wrapper = $(lv3Wrapper);
    let $lv3List = $lv3Wrapper.find(".menu__list--lv3");

    let shouldScroll = $lv3List[0].scrollHeight > $lv3List.outerHeight();

    $lv3Wrapper.toggleClass("menu__list--lv3-wrapper-scroll", shouldScroll);

    if (shouldScroll) {
      updateLv3ScrollToTop($lv3Wrapper, $lv3List);
      updateLv3ScrollToBottom($lv3Wrapper, $lv3List);

      const handleOnScroll = () => {
        updateLv3ScrollToTop($lv3Wrapper, $lv3List);
        updateLv3ScrollToBottom($lv3Wrapper, $lv3List);
      }

      $lv3List.on("scroll", handleOnScroll);
    }
  })
}


function updateLv2ScrollToTop ($lv2Wrapper, $lv2List, $lv2WrapperWrapper) {
  let offset = 10;
  $lv2WrapperWrapper.toggleClass('menu__list--lv2-scrolled-top', $lv2Wrapper.scrollTop() <= offset);
}

function updateLv2ScrollToBottom ($lv2Wrapper, $lv2List, $lv2WrapperWrapper) {
  let offset = 10;
  $lv2WrapperWrapper.toggleClass('menu__list--lv2-scrolled-all', $lv2Wrapper[0].getBoundingClientRect().bottom + offset > $lv2List[0].getBoundingClientRect().bottom);
}

function setupLv2Scroll($menu) {
  let $lv2Wrappers = $menu.find(".menu__list--lv2-wrapper");

  $lv2Wrappers.each((i, lv2Wrapper) => {
    let $lv2Wrapper = $(lv2Wrapper);
    let $lv2WrapperWrapper = $lv2Wrapper.closest(".menu__list--lv2-wrapper-wrapper");
    let $lv2List = $lv2Wrapper.find(".menu__list--lv2");

    let shouldScroll = $lv2List.outerHeight() > $lv2Wrapper.outerHeight();

    $lv2WrapperWrapper.toggleClass("menu__list--lv2-wrapper-wrapper-scroll", shouldScroll);

    if (shouldScroll) {
      updateLv2ScrollToTop($lv2Wrapper, $lv2List, $lv2WrapperWrapper);
      updateLv2ScrollToBottom($lv2Wrapper, $lv2List, $lv2WrapperWrapper);

      const handleOnScroll = () => {
        updateLv2ScrollToTop($lv2Wrapper, $lv2List, $lv2WrapperWrapper);
        updateLv2ScrollToBottom($lv2Wrapper, $lv2List, $lv2WrapperWrapper);
      }

      $lv2Wrapper.on("scroll", handleOnScroll);
    }
  })
}

function setupLv3VerticalPos($menu) {
  const fn = () => {
    $menu.find(".menu__list--lv2-wrapper").each((i, lv2) => {
      let $lv2 = $(lv2);
      let $lv3s = $lv2.find(".menu__list--lv3-wrapper");

      $lv3s.each((i, lv3) => {
        let $lv3 = $(lv3);
        $lv3.toggleClass("menu__list--lv3-bottom", false);

        let lv2BottomPos = lv2.getBoundingClientRect().bottom;
        let lv3BottomPos = lv3.getBoundingClientRect().bottom;

        $lv3.toggleClass("menu__list--lv3-bottom", lv2BottomPos < lv3BottomPos);
      });

    });
  }

  fn();

  $menu.find(".menu__link--lv2").on("mouseenter focus", (e) => {
    fn();
  });
}

function setupDropdownHorizontalPos ($menu) {
  const fn = () => {
    $menu.find(".menu__list--lv2").each((i, lv2) => {
      let $lv2 = $(lv2);
      let $lv2WrapperWrapper = $lv2.closest(".menu__list--lv2-wrapper-wrapper");
      let $lv3 = $lv2.find(".menu__list--lv3-wrapper");
      $lv2WrapperWrapper.toggleClass("menu__list--lv2-wrapper-wrapper-left", false);
      $lv3.toggleClass("menu__list--lv3-left", false);

      let lv2RightPos = lv2.getBoundingClientRect().right;
      let lv3RightPos = lv2RightPos + $lv2.width();
      let windowWidth = $(window).width();

      $lv2WrapperWrapper.toggleClass("menu__list--lv2-wrapper-wrapper-left", lv2RightPos > windowWidth);
      $lv3.toggleClass("menu__list--lv3-left", lv3RightPos > windowWidth);
    });
  }

  fn();

  $menu.find(".menu__link--lv1").on("mouseenter focus", (e) => {
    fn();
  });

}

function toggleIsActiveClasses ($ul, $li, $menu) {
  $li.on({
    mouseenter: function () {
      var $this = $(this);
      $this.addClass('is-active');
      $menu.trigger("menu.change");

      setTimeout(() => {
        setupLv2Scroll($menu);
        setupLv3Scroll($menu);
      }, 400);
    },
    mouseleave: function () {
      var $this = $(this);
      $this.removeClass('is-active');
      $menu.trigger("menu.change");
    },
  });

  $ul
    .find('li a')
    .on('focus', function () {
      var $this = $(this);
      $this.parents($ul).find('li').removeClass('is-active');
      $this.parents('li').addClass('is-active');
    })
    .on('blur', function () {
      var $this = $(this);
      $this.parents('li').removeClass('is-active');
    });
}

function escKeyCloseMenu ($li) {
  // Press esc key to close dropdown menu
  $(document).on('keyup.menu', function (e) {
    if (e.keyCode === 27) {
      $li.removeClass('is-active');
    }
  });

}

function handleTouchDevices ($menu) {
  // Workaround for dropdown menu hover on touch devices
  $menu.each(function () {
    $(this).touchMenuHover({
      forceiOS: true,
      childTag: 'div',
    });
  });
}

function disableLv3WrapperPointerEventWhenNotActive ($menu) {
  const $wrappers = $menu.find(".menu__list--lv3-wrapper");
  $wrappers.toggleClass("pe-none", true);
  
  $menu.on("menu.change", e => {
    const $wrappers = $menu.find(".menu__list--lv3-wrapper");
    
    $wrappers.each((i, wrapper) => {
      $(wrapper).toggleClass("pe-none", !$(wrapper).closest(".menu__item--lv2.has-sub").hasClass("is-active"));
    });
  })
}

function disableLv2WrapperPointerEventWhenNotActive ($menu) {
  const $wrappers = $menu.find(".menu__list--lv2-wrapper-wrapper, .menu__list--lv2-wrapper");
  $wrappers.toggleClass("pe-none", true);
  
  $menu.on("menu.change", e => {
    const $wrappers = $menu.find(".menu__list--lv2-wrapper-wrapper, .menu__list--lv2-wrapper");
    
    $wrappers.each((i, wrapper) => {
      $(wrapper).toggleClass("pe-none", !$(wrapper).closest(".menu__item--lv1.has-sub").hasClass("is-active"));
    });
  })
}

function watchIsMenuActive($menu) {
  $menu.on("menu.change", e => {
    document.documentElement.classList.toggle("menu-active", $menu.find(".is-active").length > 0);
  });
}

function watchIsLv2WithActiveSublevel($menu) {
  const $lv2Wrappers = $menu.find(".menu__list--lv2-wrapper");
  $menu.on("menu.change", e => {
    $lv2Wrappers.removeClass("has-active-sublevel");
    let activeLv2ItemWithSublist = e.currentTarget.querySelector(".menu__list--lv2-wrapper .has-sub.is-active");
    if (activeLv2ItemWithSublist) {
      activeLv2ItemWithSublist.closest('.menu__list--lv2-wrapper')?.classList.add("has-active-sublevel");
    }
  });
}

export default function desktopMenu() {
  var $menu = $('.js-menu'),
    $ul = $('.js-menu > ul'),
    $li = $('.js-menu li');

  toggleIsActiveClasses($ul, $li, $menu);
  handleTouchDevices($menu);
  escKeyCloseMenu($li);
  disableLv2WrapperPointerEventWhenNotActive($menu);
  disableLv3WrapperPointerEventWhenNotActive($menu);
  setupDropdownHorizontalPos($menu);
  setupLv3VerticalPos($menu);
  setupLv2Scroll($menu);
  setupLv3Scroll($menu);
  watchIsMenuActive($menu);
  watchIsLv2WithActiveSublevel($menu);

  //Accessibility
  $li.filter('.is-selected').find('> a').attr('aria-current', 'true');
}