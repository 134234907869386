const generalContentTable = (()=> {

  function findChildIndex(parent, child) {
    const children = parent.children;
    for (let i = 0; i < children.length; i++) {
        if (children[i] === child) {
            return i; // Return the index if the child is found
        }
    }
    return -1; // Return -1 if the child is not found
}

    const setupRolSpan = (table) => {
      $(table).find("td[rowspan]").each((i, td) => {
        let $td = $(td);
        let rowspan = parseInt($td.attr("rowspan"));
        let $tr = $td.closest("tr");
        let tdIndex = findChildIndex($tr[0], $td[0]);

        if(tdIndex == 0) {
          for (let i = 0; i < rowspan - 1; i++) {
            $tr = $tr.find(`+tr`);
            $tr.addClass("first-col-rowspaned");
          } 
        }
      })
    }

    const setup = () => {
        $(".general-content table").each((i, table) => {
          setupRolSpan(table);
        })
    }

    return {init: setup}
})()

export default generalContentTable;