import { isScrolledIntoView } from '@shared/js/scripts/commonFn';

const jsShowInview = (function() {
  const trigger = function() {
    $('.js-inview-elem:not(.is-visible)').each(function() {
      let $elem = $(this);

      if (isScrolledIntoView($elem)) {
        $elem.addClass('is-visible');
      }
    });
  }

  function setup() {
    if (document.documentElement.classList.contains('live-preview')) {
      $('.js-inview-elem:not(.is-visible)').addClass('is-visible');
    } else {
      trigger(); // Do it once in case this init() runs later than 'load' event
      $(window).on('scroll load touchmove', trigger);
    }
  }

  return {
    init: setup
  };
}());

export default jsShowInview;