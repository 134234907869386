var jsScrollFloating = (function () {
  function setup(){
    var $floatItem = $('.js-floating-itm');
    var t = null;
    $(window).on('load scroll', function(){
      if(t) {
        t = null;
        clearTimeout(t);
        return;
      }

      t = setTimeout(function(){

        $floatItem.each(function(){
          var $f = $(this),
              $line = $f.closest('.js-floating-line'),
              $linePos = $line.offset().top,
              $scrollTop = $(window).scrollTop(),
              $linebottom = $linePos + $line.outerHeight() - $f.outerHeight(),
              $headerHeight = $('.page-head').outerHeight();

          if ($scrollTop > $linePos) {
            if($scrollTop < $linebottom - $headerHeight){
              var topVal = $scrollTop - $linePos + $headerHeight;
              if(topVal < $linebottom){
                $f.stop().animate({
                    top: topVal
                }, 800);
              }
            }else{
              if($line.outerHeight() - $f.outerHeight()- 20 > 0){
                $f.stop().animate({
                  top: $line.outerHeight() - $f.outerHeight()- 20
                }, 800);
              }
            }
          } else {
            $f.stop().animate({
              top: 0
            }, 800);
          }
        });

      }, 100);
    });

  }

  return {
    init: setup
  }
}());

const jsScrollToFixed = (function() {
  function setup() {
    const $floatItem = $('.js-floating-itm');
    const $floatItemParent = $floatItem.parent('.detail-area__r');
    const $pageHead = $('.page-head');

    if ($floatItem.length && $floatItemParent.length && $pageHead.length) {
      $floatItem.scrollToFixed({
        marginTop: () => ($pageHead.outerHeight(true) - 30),
        limit: () => ($floatItemParent.offset().top + $floatItemParent.outerHeight(true) - $floatItem.outerHeight(true)), // the bottom of the parent element
        removeOffsets: true,
      });
    }
  }

  return {
    init: setup
  };
}());

export default jsScrollToFixed;