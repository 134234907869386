/* Current Lang */
function getLang() {
  var curLang,
    langAttr = document.documentElement.getAttribute('lang').toLowerCase();

  if (langAttr == 'en') {
    curLang = 'en';
  } else if (langAttr == 'zh-hk') {
    curLang = 'tc';
  } else if (langAttr == 'zh-cn') {
    curLang = 'sc';
  } else {
    curLang = 'en';
  }

  return curLang;
}

function getLangCaption() {
  var curLangCaption,
    langAttr = document.documentElement.getAttribute('lang').toLowerCase();

  if (langAttr == 'en') {
    curLangCaption = 'en';
  } else if (langAttr == 'zh-hk') {
    curLangCaption = 'zh';
  } else if (langAttr == 'zh-cn') {
    curLangCaption = 'zh';
  } else {
    curLangCaption = 'en';
  }

  return curLangCaption;
}

function langArg(_arg) {
  var cur_lang = getLang();

  return _arg[cur_lang];
}

export { getLang, langArg, getLangCaption };
