import SwiperSlider from '@shared/js/scripts/swiperSlider';

const layersSlider = (()=>{
    const componentName = `layers-slider`;

    const setupSwiper = (dom) => {
      return new SwiperSlider(dom, {
        btnPlayPause: true,
        autoplay: false,
        loop: true,
        effect: 'fade',
      });
    };

    const setup = () => {
        document.querySelectorAll(`.${componentName}`).forEach((component) => {
            const slider = setupSwiper(component);
        })
    }

    return {
        init: setup
    }
})();

export default layersSlider;