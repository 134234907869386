const formGridSelect = (function () {
  function setup() {
    $(".form-grid select, .form-grid__select-btn").each(function () {
      var $this = $(this);
      var options = {
        style: "",
        styleBase: "form-grid__select-btn",
        noneSelectedText:
          $this.find("option[disabled][selected]").text() || "---",
      };

      $this.selectpicker(options);
    });
  }

  return {
    init: setup,
  };
})();

export default formGridSelect;
