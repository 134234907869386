const linkRel = (() => {

    const setup = () => {
        document.querySelectorAll('a[rel*="external"]').forEach(function(link) {
            link.target = '_blank';
        });
    }

    return {
        init: setup
    }
})()

export default linkRel;