import { isTouch } from './browserInfo';

export const clickEvent = isTouch ? 'touchend' : 'click',
  resizeEvent = 'orientationchange' in window ? 'orientationchange' : 'resize',
  animationEnd = (function (el) {
    const animations = {
      animation: 'animationend',
      OAnimation: 'oAnimationEnd',
      WebkitAnimation: 'webkitAnimationEnd',
    };

    for (var t in animations) {
      if (el.style[t] !== undefined) {
        return animations[t];
      }
    }
  })(document.createElement('div')),
  transitionEnd = (function (el) {
    const transitions = {
      transition: 'transitionend',
      OTransition: 'oTransitionEnd',
      WebkitTransition: 'webkitTransitionEnd',
    };

    for (var t in transitions) {
      if (el.style[t] !== undefined) {
        return transitions[t];
      }
    }
  })(document.createElement('div'));
