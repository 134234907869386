import { resizeEvent } from './var.js';

const responsiveTable = (function () {
  var timeout = null,
    buff = 3;

  function setup($_container) {
    var $c = $_container ? $_container : $('body'),
      $allTable = $(
        '.ckec table:not(.js-disable-respon-table), .ckec-style table:not(.js-disable-respon-table), .js-respon-table, .general-content table',
        $c
      );

    $allTable.each(function () {
      wrap($(this));
    });

    $(window)
      .on(resizeEvent, function () {
        if (timeout !== null) {
          clearTimeout(timeout);
          timeout = null;
        }

        timeout = setTimeout(function () {
          $allTable.each(function () {
            var $this = $(this),
              $wrapper = $this.closest('.table-wrapper');

            $wrapper.removeClass('is-loaded');

            if ($wrapper.width() - $this.outerWidth() >= 0) {
              $wrapper.addClass('right-end');
            } else {
              $wrapper.removeClass('right-end');
            }
          });
        }, 100);
      })
      .trigger(resizeEvent);
  }

  function wrap(_table) {
    var $this = _table;
    // var parentWidth = $this.parent().width();
    // var tableWidth = $this.width();

    if ($this.find('td').add('th').length < 2) {
      $this.addClass('shadow-none');
      return;
    }
    if ($this.data('table-responsive') !== undefined) {
      return;
    }

    var $table = $('<div class="table-responsive"></div>'),
      $tableWrapper = $('<div class="table-wrapper left-end"></div>');

    $table.scroll(function (e) {
      var $thisWrapper = $(this),
        $table = $this,
        $outer = $thisWrapper.parent();

      if ($table.position().left !== 0) {
        $outer.removeClass('left-end');
      } else {
        $outer.addClass('left-end');
      }

      if ($table.position().left > -($table.outerWidth() - $thisWrapper.width()) + buff) {
        $outer.removeClass('right-end');
      } else {
        $outer.addClass('right-end');
      }
    });

    $this.wrap($table);
    $this.parent().wrap($tableWrapper);
    $this.parents('.table-wrapper').addClass('is-loaded');
    $this.data('table-responsive', 1);
  }

  return {
    init: setup,
    reinit: setup,
  };
})();

export default responsiveTable;
