import SwiperSlider from '@shared/js/scripts/swiperSlider';

const breakpoints = {
  md: 768,
  lg: 992,
};

const showMoreSlider = (() => {
  const showMoreSlider = document.querySelector('.show-more__slider');
  const setup = () => {
    if (!showMoreSlider) return;
    const options = {
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 40,
      effect: 'slide',
      breakpoints: {
        [breakpoints.md]: {
          slidesPerView: 2,
        },
        [breakpoints.lg]: {
          slidesPerView: 3,
        },
      },
    };
    const swiper = new SwiperSlider(showMoreSlider, options);
  };
  return {
    init: setup,
  };
})();

export default showMoreSlider;
