import SwiperSlider from '@shared/js/scripts/swiperSlider';
import lightbox from '@shared/js/scripts/lightbox';

const videoGallerySlider = (() => {
  const videoGallerySlider = document.querySelector('.video-sec__slider');
  const breakpoints = {
    md: 768,
    lg: 992,
  };
  const setup = () => {
    if (!videoGallerySlider) return;
    const options = {
      slidesPerView: 1,
      slidesPerGroup: 1,
      effect: 'slide',
      spaceBetween: 80,
      breakpoints: {
        [breakpoints.md]: {
          slidesPerView: 2,
          slidesPerGroup: 2,
        },
        [breakpoints.lg]: {
          slidesPerView: 3,
          slidesPerGroup: 3,
        },
      },
    };
    const swiper = new SwiperSlider(videoGallerySlider, options);

    swiper.swiper.on('slideChange', () => {
      lightbox.init();
    });
  };
  return {
    init: setup,
  };
})();

export default videoGallerySlider;
