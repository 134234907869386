const tabTableSearchBar = (() => {

    const updateButtonText = (element, showCollapse) => {
        if (showCollapse) {
            element.html(`${element.attr("data-collapse-text")} <span class="tab-table-search-bar__minus ico ico--minus"></span>`);
        } else {
            element.html(`${element.attr("data-expand-text")} <span class="tab-table-search-bar__minus ico ico--plus"></span>`);
        }
    }

    const setupCollapseAllButton = (button, content, tabTable) => {
        button.on("click", () => {
            // let hasShown = $(content).find(".tab-table-collapse__content.show").length > 0;
            let hasShown = $(content).find(".tab-table-collapse__content:not(.show)").length == 0;
            if (hasShown) {
                $(content).find(".tab-table-collapse__content.show").collapse('hide');
            } else {
                $(content).find(".tab-table-collapse__content").collapse('show');
            }

            updateButtonText(button, !hasShown);
        });

        const collapseFn = () => {
            // let hasShown = $(content).find(".tab-table-collapse__content.show").length > 0;
            let hasShown = $(content).find(".tab-table-collapse__content:not(.show)").length == 0;
            updateButtonText(button, hasShown);
        }

        $(content).on("shown.bs.collapse", collapseFn);
        $(content).on("hidden.bs.collapse", collapseFn);
    };

    const setupResetButton = (component) => {
        $(component).find("button[type='reset']").on("click", () => {
            let $select = $(component).find("select.selectbox-input");
            $select.val("");
            $select.selectpicker('refresh');
        });
    }

    const setup = () => {
        const tabTable = $(".tab-table-wrapper");

        if (!tabTable.length) return;

        tabTable.each((index, tabTable) => {
            $(tabTable).find(".tab-nav-content").each((i, content) => {
                // updateButtonText($(content).find(".tab-table-search-bar__collapse-all button"), $(content).find(".panel__content.show").length > 0);
                updateButtonText($(content).find(".tab-table-search-bar__collapse-all button"), $(content).find(".panel__content:not(.show)").length == 0);
                setupResetButton(content);
                setupCollapseAllButton($(content).find(".tab-table-search-bar__collapse-all button"), content, tabTable);
            });
        });
    }

    return {
        init: setup
    }
})()

export default tabTableSearchBar;