const svg2inline = (function () {
  const idGenerator = {
    count: 0,
    get: function () {
      return ++this.count;
    },
  };

  function setup() {
    const elems = document.querySelectorAll('img.js-inline-svg, svg.inlined-svg');
    elems.forEach(async function (elem) {
      if (elem.tagName === 'IMG') {
        const inlinedSVG = await convert2Inline(elem);
        parseSVG(inlinedSVG);
        elem.parentNode.replaceChild(inlinedSVG, elem);
      } else if (elem.tagName === 'SVG') {
        parseSVG(elem);
      }
    });
  }

  async function convert2Inline(svgImg) {
    const imgURL = svgImg.getAttribute('src');
    if (!imgURL || !imgURL.includes('.svg')) return;
    const attributes = svgImg.attributes;

    const xml = await fetch(imgURL).then((response) => response.text());
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xml, 'text/html');
    const inlinedSVG = xmlDoc.querySelector('svg');

    Array.prototype.slice.call(attributes).forEach(function (attribute) {
      if (attribute.name !== 'src' && attribute.name !== 'alt') {
        inlinedSVG.setAttribute(attribute.name, attribute.value);
      }
    });

    inlinedSVG.classList.add('inlined-svg');
    inlinedSVG.classList.remove('js-inline-svg');
    return inlinedSVG;
  }

  function parseSVG(inlinedSVG) {
    var uniqid = `svg-` + idGenerator.get();

    // Remove some of the attributes that aren't needed
    inlinedSVG.removeAttribute('xmlns:a');
    inlinedSVG.removeAttribute('x');
    inlinedSVG.removeAttribute('y');
    inlinedSVG.removeAttribute('enable-background');
    inlinedSVG.removeAttribute('xmlns:xlink');
    inlinedSVG.removeAttribute('xml:space');
    inlinedSVG.removeAttribute('version');

    inlinedSVG.setAttribute('role', 'img');

    if (inlinedSVG.id) {
      inlinedSVG.setAttribute('id', inlinedSVG.id + '-' + uniqid);
    }

    // set viewBox to enable scaling the svg size
    var setWidth = inlinedSVG.getAttribute('width');
    var setHeight = inlinedSVG.getAttribute('height');
    var setViewBox = inlinedSVG.getAttribute('viewBox');

    if (setWidth && setHeight && !setViewBox) {
      inlinedSVG.setAttribute('viewBox', `0 0 ${setWidth} ${setHeight}`);
    }

    // add aria label if alt text is provided via <title>
    var title = inlinedSVG.querySelector('title');
    if (title) {
      inlinedSVG.setAttribute('aria-labelledby', uniqid + '-title');
      title.setAttributeNS(null, 'id', uniqid + '-title');
    }

    // Set clip path id to unique value to avoid rendering failure
    var clipPathElems = inlinedSVG.querySelectorAll('clipPath');

    clipPathElems.forEach(function (clipPath) {
      const originalID = clipPath.id;
      clipPath.id = clipPath.id + '-' + uniqid;

      inlinedSVG.querySelectorAll(`[clip-path="url(#${originalID})"]`).forEach(function (el) {
        el.setAttribute('clip-path', `url(#${clipPath.id})`);
      });
    });
  }

  return {
    init: setup,
  };
})();

export default svg2inline;
