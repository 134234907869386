import { langArg } from './lang';
import desktopMenu from "./desktopMenu"

const menu = (function () {
  function setup() {
    desktopMenu();
    mobiMenu();
  }
  
  function mobiMenu() {
    var openSubmenuTxt = langArg({
        en: 'Open Submenu',
        tc: '打開子菜單',
        sc: '打开子菜单',
      }),
      closeSubmenuTxt = langArg({
        en: 'Close Submenu',
        tc: '關閉子菜單',
        sc: '关闭子菜单',
      }),
      //easing = 'ease-out',
      duration = 300;

    // Append toggle submenu button
    $('.mobi-panel li.has-sub').each(function (i) {
      var $this = $(this);

      if (!$this.find('> .js-toggle-mobi-submenu').length) {
        $this
          .find('> .mobi-menu__link:not(.mobi-menu__link--lv2)')
          .after(
            '<button class="btn-toggle-mobi-submenu js-toggle-mobi-submenu" type="button" aria-expanded="' +
              ($this.hasClass('is-active') ? 'true' : 'false') +
              '"><span class="ico ico--plus" aria-hidden="true"></span><span class="sr-only">' +
              ($this.hasClass('is-active') ? closeSubmenuTxt : openSubmenuTxt) +
              '</span></button>'
          );
        $this
          .find('> .mobi-menu__link--lv2')
          .after(
            '<button class="btn-toggle-mobi-submenu js-toggle-mobi-submenu" type="button" aria-expanded="' +
              ($this.hasClass('is-active') ? 'true' : 'false') +
              '"><span class="ico ico--icon-menu-arrow" aria-hidden="true"></span><span class="sr-only">' +
              ($this.hasClass('is-active') ? closeSubmenuTxt : openSubmenuTxt) +
              '</span></button>'
          );
      }

      // close mobi menu when change from mobile view to desktop view
      const mm = window.matchMedia("(min-width: 992px)");
      mm.addEventListener("change", (e) => {
        if (e.matches) {
          $("#mobile-menu.show").modal('toggle');
        }
      })
    });

    // Accessibility
    $('.js-toggle-mobi-submenu').each(function (i) {
      var $this = $(this),
        $collapseItem = $this.next('.mobi-menu__list');

      $this.attr({
        'aria-controls': 'collapse-mobi-menu-' + i,
      });

      $collapseItem.attr('id', 'collapse-mobi-menu-' + i);

      if ($this.parent().hasClass('is-active')) {
        $collapseItem.show();
        $this.find('.ico.ico--plus').removeClass('ico--plus').addClass('ico--minus');
      }
    });

    $('.mobi-menu .selected > a').attr('aria-current', 'true');
    $('.mobi-menu__item.has-sub:not(.is-active) > a').attr('aria-label', function () {
      return this.innerText.trim() + ', ' + openSubmenuTxt;
    });

    // Toggle submenu
    $(document).on('click', '.mobi-menu__item', function (e) {
      var $this = $(this);
      if (!$this.hasClass('has-sub')) return;

      if (!$this.hasClass('is-active')) {
        $this.addClass('is-active');
        $this.find('> a').removeAttr('aria-label');
        var $toggleBtn = $this.find('> .js-toggle-mobi-submenu');
        $toggleBtn.attr('aria-expanded', 'true');
        $toggleBtn.next().slideDown(duration);
        $toggleBtn.find('.ico.ico--plus').removeClass('ico--plus').addClass('ico--minus');
        $toggleBtn.find('.sr-only').html(closeSubmenuTxt);
      }
    });

    $(document).on('click', '.mobi-menu__link', function (e) {
      var $parent = $(this).parent();
      if ($parent.hasClass('has-sub') && !$parent.hasClass('is-active')) {
        e.preventDefault();
      }
    });

    $(document).on('click', '.js-toggle-mobi-submenu', function (e) {
      var $this = $(this),
        $thisNext = $this.next(),
        $thisParents = $this.parent(),
        $thisIcon = $this.find('.ico.ico--minus');

      if ($thisParents.hasClass('is-active')) {
        e.stopPropagation();
        $this.attr('aria-expanded', 'false');
        $this.find('.sr-only').html(openSubmenuTxt);
        $thisParents.removeClass('is-active');
        $thisParents.find('> a').attr('aria-label', function () {
          return this.innerText.trim() + ', ' + openSubmenuTxt;
        });
        $thisNext.slideUp(duration);
        $thisIcon.removeClass('ico--minus').addClass('ico--plus');
      }
    });
  }

  return {
    init: setup,
  };
})();

export default menu;
