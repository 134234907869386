import 'jquery-ui';
import 'jquery-ui/ui/widgets/datepicker';

const eventCalendar = (() => {
  // const lang = document.documentElement.lang;
  const lang = (() => {
    if ($("html").hasClass("lang-tc")) {
      return "zh-hk";
    } else if ($("html").hasClass("lang-sc")) {
      return "zh-cn";
    } else if ($("html").hasClass("lang-en")) {
      return "en";
    } else {
      return "en";
    }
  })();

  const beforeShowDay = (date, events) => {
    var neededClass = '';

    var tmptoday = new Date();
    tmptoday.setHours(0, 0, 0, 0);

    var isToday = false;
    if (tmptoday.getTime() === date.getTime()) {
      isToday = true;
    }

    var i;
    var needHighlight = false;
    for (i = 0; i < events.length; ++i) {
      var thisItem = events[i];
      if (date >= thisItem.startDate && date <= thisItem.endDate) {
        needHighlight = true;
      }
    }

    if (needHighlight) {
      neededClass += 'ui-state-custom-highlight';
    }

    if (isToday) {
      neededClass += ' real-today';
    }
    return [true, neededClass, ''];
  };

  function getDaysName(length) {
    var daysName, daysNameShort;
    if (lang == 'zh-hk' || lang == 'zh-cn') {
      daysName = ['日', '一', '二', '三', '四', '五', '六'];
      daysNameShort = daysName;
    } else {
      daysName = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      daysNameShort = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
    }
    if (length == 'long') {
      return daysName;
    } else {
      return daysNameShort;
    }
  }

  function getMonthName() {
    var monthName;
    if (lang == 'zh-hk' || lang == 'zh-cn') {
      monthName = [
        '1月',
        '2月',
        '3月',
        '4月',
        '5月',
        '6月',
        '7月',
        '8月',
        '9月',
        '10月',
        '11月',
        '12月',
      ];
    } else {
      monthName = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];
    }
    return monthName;
  }
  function getTodayTxr() {
    var todayTxt;
    if (lang == 'zh-hk' || lang == 'zh-cn') {
      todayTxt = '今天';
    } else {
      todayTxt = 'Today';
    }
    return todayTxt;
  }
  function getTxtByLang(arrayTxt) {
    var currentLang = 0;
    switch (lang) {
      case 'zh-hk':
        currentLang = 1;
        break;
      case 'zh-cn':
        currentLang = 2;
        break;
      default:
        currentLang = 0;
    }
    return arrayTxt[currentLang];
  }
  
  function setupBsSelect (calendar) {
    let $calendar = $(calendar);
    $calendar.find(".ui-datepicker-month, .ui-datepicker-year").selectpicker();
    // $calendar.find(".ui-datepicker-month").selectpicker();
  }

  function addTodayBtn() {
    const $todayBtn = $(`<button class="btn btn--event-calendar">${getTodayTxr()}</button>`);
    const $calendar = $('.event-calendar');
    const $calendarHeader = $calendar.find('.ui-datepicker-title');
    
    if ($calendarHeader.find(".btn--event-calendar").length > 0) {
      return;
    }

    $calendarHeader.append($todayBtn);

    $todayBtn.on('click', () => {
      const today = new Date();
      $calendar.datepicker('setDate', today);
      $('.event-calendar').each((i, calendar) => {
        setupBsSelect(calendar);
        addTodayBtn();
      });
    });
  }

  function updateAriaLabelText () {
    const monthAriaLabel = {
      "zh-hk": "選擇月份",
      "zh-cn": "选择月份",
      "en": "Select Month"
    }
    const yearAriaLabel = {
      "zh-hk": "選擇年份",
      "zh-cn": "选择年份",
      "en": "Select Year"
    }
    $(".ui-datepicker-month[aria-label]").attr("aria-label", monthAriaLabel[lang]);
    $(".ui-datepicker-year[aria-label]").attr("aria-label", yearAriaLabel[lang]);
  }

  const setup = () => {
    let events = [];
    var $eventCalendar = $('.event-calendar');
    var $eventCalendarSection = $('.event-calendar-section');
    $('.event-calendar').each((i, calendar) => {
      var $this = $(this);
      var $thisCalendarHolder = $this.find('.event-calendar__calendar-holder');
      var $thisCalendar = $this.find('.event-calendar__calendar');
      var $thisCalendarList = $('.event-calendar-list');
      var $thisEvenItems = $eventCalendarSection.find('.event-calendar-item');

      //var targetH = $thisCalendarHolder.outerHeight();

      $thisEvenItems.each(function (key) {
        var $thisItem = $(this);
        var thisStartDate = [0, 0, 0],
          thisEndtDate = [0, 0, 0];
        if ($thisItem.data('start-date')) {
          thisStartDate = $thisItem.data('start-date').split('-');
        }
        if ($thisItem.data('end-date')) {
          thisEndtDate = $thisItem.data('end-date').split('-');
        }
        var thisInfo = {
          target: $thisItem,
          startDate: new Date(
            thisStartDate[0],
            thisStartDate[1] * 1 - 1,
            thisStartDate[2],
            0,
            0,
            0
          ),
          endDate: new Date(
            thisEndtDate[0],
            thisEndtDate[1] * 1 - 1,
            thisEndtDate[2],
            0,
            0,
            0
          ),
        };
        events.push(thisInfo);
      });

      $(calendar).datepicker({
        showOtherMonths: false,
        selectOtherMonths: true,
        changeYear: true,
        changeMonth: true,
        dateFormat: 'yy-mm-dd',
        dayNamesMin: getDaysName('long'),
        monthNames: getMonthName(),
        monthNamesShort: getMonthName(),
        minDate: '-2Y',
        maxDate: '+2Y',
        prevText: getTxtByLang(['Previous Month', '上一月', '上一月']),
        nextText: getTxtByLang(['Next Month', '下一月', '下一月']),
        onSelect: function (date) {
          var thisDate = date.split('-');
          thisDate = new Date(
            thisDate[0],
            thisDate[1] * 1 - 1,
            thisDate[2],
            0,
            0,
            0
          );
          var targets = null;
          targets = [];
          var i;
          for (i = 0; i < events.length; ++i) {
            var thisItem = events[i];
            if (
              thisDate >= thisItem.startDate &&
              thisDate <= thisItem.endDate
            ) {
              targets.push(thisItem.target);
            }
          }
          if (targets.length > 0) {
            var i;
            $thisEvenItems.removeClass('is-active');
            for (i = 0; i < targets.length; ++i) {
              targets[i].addClass('is-active');
            }

            $thisCalendarList.animate(
              {
                scrollTop:
                  targets[0][0].offsetTop - $thisCalendarList[0].offsetTop,
              },
              1000
            );
          }
          setTimeout(() => {
            setupBsSelect(calendar);
            addTodayBtn();
            updateAriaLabelText();
          }, 1);
        },
        beforeShowDay: (date) => beforeShowDay(date, events),
        onChangeMonthYear: function (year, month, datePicker) {
          var newDate = $('.event-calendar').datepicker('getDate');
          newDate.setMonth(month - 1);
          newDate.setFullYear(year);
          setTimeout(() => {
            setupBsSelect(calendar);
            addTodayBtn();
            updateAriaLabelText
          }, 1);
        },
      });

      setupBsSelect(calendar);
      addTodayBtn();
      updateAriaLabelText();
    });
    if ($eventCalendar.length < 1) {
      return false;
    }

  };

  return {
    init: setup,
  };
})();

export default eventCalendar;
