const pagePrint = (function () {
  function setup() {
    window.addEventListener('beforeprint', function (e) {
      document.querySelectorAll('[loading="lazy"]').forEach(el => el.removeAttribute('loading'))
    });

    document.querySelectorAll('.js-print').forEach(el => {
      el.addEventListener('click', e => window.print());
    });
  }

  return {
    init: setup,
  };
})();

export default pagePrint;
