import Plyr from 'plyr';
import Hls from 'hls.js';
import { getLang, getLangCaption } from '@shared/js/scripts/lang';

export default class MediaPlayer {
  constructor(__video, __options = {}) {
    const i18n = {
      en: {
        restart: 'Restart',
        rewind: 'Rewind {seektime}s',
        play: 'Play',
        pause: 'Pause',
        fastForward: 'Forward {seektime}s',
        seek: 'Seek',
        seekLabel: '{currentTime} of {duration}',
        played: 'Played',
        buffered: 'Buffered',
        currentTime: 'Current time',
        duration: 'Duration',
        volume: 'Volume',
        mute: 'Mute',
        unmute: 'Unmute',
        enableCaptions: 'Enable captions',
        disableCaptions: 'Disable captions',
        download: 'Download',
        enterFullscreen: 'Enter fullscreen',
        exitFullscreen: 'Exit fullscreen',
        frameTitle: 'Player for {title}',
        captions: 'Captions',
        settings: 'Settings',
        pip: 'PIP',
        menuBack: 'Go back to previous menu',
        speed: 'Speed',
        normal: 'Normal',
        quality: 'Quality',
        loop: 'Loop',
        start: 'Start',
        end: 'End',
        all: 'All',
        reset: 'Reset',
        disabled: 'Disabled',
        enabled: 'Enabled',
        advertisement: 'Ad',
        qualityBadge: {
          2160: '4K',
          1440: 'HD',
          1080: 'HD',
          720: 'HD',
          576: 'SD',
          480: 'SD',
        },
      },
      tc: {
        restart: '重新開始',
        rewind: '快退{seektime}秒',
        play: '播放',
        pause: '暫停',
        fastForward: '快進{seektime}秒',
        seek: '尋找',
        seekLabel: '{currentTime} / {duration}',
        played: '已播放',
        buffered: '已緩衝',
        currentTime: '當前時間',
        duration: '時長',
        volume: '音量',
        mute: '靜音',
        unmute: '取消靜音',
        enableCaptions: '開啟字幕',
        disableCaptions: '關閉字幕',
        download: '下載',
        enterFullscreen: '全屏',
        exitFullscreen: '退出全屏',
        frameTitle: '播放器 - {title}',
        captions: '字幕',
        settings: '設定',
        pip: '畫中畫',
        menuBack: '返回上一級菜單',
        speed: '速度',
        normal: '正常',
        quality: '質量',
        loop: '循環',
        start: '開始',
        end: '結束',
        all: '全部',
        reset: '重置',
        disabled: '禁用',
        enabled: '啟用',
        advertisement: '廣告',
        qualityBadge: {
          2160: '超高清',
          1440: '高清',
          1080: '高清',
          720: '高清',
          576: '標清',
          480: '標清',
        },
      },
      sc: {
        restart: '重新开始',
        rewind: '快退{seektime}秒',
        play: '播放',
        pause: '暫停',
        fastForward: '快进{seektime}秒',
        seek: '寻找',
        seekLabel: '{currentTime} / {duration}',
        played: '已播放',
        buffered: '已缓冲',
        currentTime: '当前时间',
        duration: '时长',
        volume: '音量',
        mute: '静音',
        unmute: '取消静音',
        enableCaptions: '开启字幕',
        disableCaptions: '关闭字幕',
        download: '下载',
        enterFullscreen: '全屏',
        exitFullscreen: '退出全屏',
        frameTitle: '播放器 - {title}',
        captions: '字幕',
        settings: '设置',
        pip: '画中画',
        menuBack: '返回上一级菜单',
        speed: '速度',
        normal: '正常',
        quality: '质量',
        loop: '循环',
        start: '开始',
        end: '结束',
        all: '全部',
        reset: '重置',
        disabled: '禁用',
        enabled: '启用',
        advertisement: '广告',
        qualityBadge: {
          2160: '超高清',
          1440: '高清',
          1080: '高清',
          720: '高清',
          576: '标清',
          480: '标清',
        },
      },
    };

    const plyrDefaults = {
      i18n: i18n[getLang()],
      captions: { language: getLangCaption() },
      storage: { key: 'plyr_storage' },
      ratio: '16:9',
      controls: [
        'play-large',
        'play',
        'progress',
        'current-time',
        'mute',
        'volume',
        'captions',
        'pip',
        'airplay',
        'fullscreen',
      ],
      // load SVG sprite locally
      iconUrl: window.location.origin + '/assets/shared/img/svg/plyr.svg',
    };

    this.video = __video;
    this.originalDom = __video.cloneNode(true);
    this.isStream = __video.classList.contains('stream-type');
    this.isNative = __video.classList.contains('native-type');
    this.playerSrc = __video.getAttribute('data-src');
    this.options = __options;
    this.config = this.isNative ? Object.assign({}, plyrDefaults, __options) : Object.assign({}, __options);
    this.plyr = null;
    this.hls = null;
  }

  init() {
    if (this.isStream) {
      if (Hls.isSupported()) {
        this.plyr = new Plyr(this.video, this.config);
        this.hls = new Hls(this.config);
        this.hls.loadSource(this.playerSrc);
        this.hls.attachMedia(this.video);
        this.video.classList.add('js-player--init');
      } else if (this.video.canPlayType('application/vnd.apple.mpegurl')) {
        this.video.src = this.playerSrc;
        this.plyr = new Plyr(this.video, this.config);
        this.video.classList.add('js-player--init');
      }
    } else if (this.isNative) {
      var sourceElem = document.createElement('source');
      sourceElem.setAttribute('src', this.playerSrc);
      sourceElem.setAttribute('type', 'video/mp4');
      this.video.appendChild(sourceElem);
      this.plyr = new Plyr(this.video, this.config);
      this.video.classList.add('js-player--init');
    }
  }

  destroy() {
    if (!this.video.classList.contains('js-player--init')) return;
    const plyrDom = this.video.closest('.plyr');
    const newDom = this.originalDom.cloneNode(true);
    plyrDom.replaceWith(newDom);
    this.video = newDom;
    this.plyr = null;
    this.hls = null;
  }
}
