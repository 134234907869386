import device from "current-device";

export const ua = navigator.userAgent.toLowerCase(),
  isWindow = device.windows(),
  isMac = device.macos(),
  isMobile = device.mobile(),
  isTablet = device.tablet(),
  isAndroid = device.android(),
  isIOS = device.ios(),
  isChrome = ua.indexOf('chrome') > -1,
  isSafari = /^((?!chrome|android).)*safari/i.test(ua),
  isFirefox = typeof InstallTrigger !== 'undefined',
  isTouch = $('.touchevents').length;
//isTouch = 'ontouchstart' in window || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0)

export const prefix = (function () {
  const styles = window.getComputedStyle(document.documentElement, ''),
    pre = (Array.prototype.slice
      .call(styles)
      .join('')
      .match(/-(moz|webkit|ms)-/) ||
      (styles.OLink === '' && ['', 'o']))[1],
    dom = 'WebKit|Moz|MS|O'.match(new RegExp('(' + pre + ')', 'i'))[1];
  return {
    dom: dom,
    lowercase: pre,
    css: '-' + pre + '-',
    js: pre[0].toUpperCase() + pre.substr(1),
  };
})();

if (isChrome) {
  $('html').addClass('chrome');
}

if (isSafari) {
  $('html').addClass('safari');
}

if (isFirefox) {
  $('html').addClass('firefox');
}
