import collapse from "@shared/js/scripts/collapse";
import { getURLSearchParams, setUrlSearchParam } from '@shared/js/scripts/urlSearchParams';

const jsFilterPaper = (() => {
  const setup = () => {
    let data,
      deptKeys,
      deptOpts,
      filterName = "meeting-filter";

    const createOption = (value, text) =>
      `<option value="${value}">${text}</option>`;

    const createCollapse = (
      title,
      child,
      meta = { year: "", count: "" }
    ) => `
            <div class="tab-table-collapse js-collapse-item" data-year="${meta.year}" data-count="${meta.count}">
                <div class="tab-table-collapse__inner">

                    <a href="#" class="tab-table-collapse__toggle js-panel-trigger" data-toggle="collapse" aria-expanded="false"
                        role="button">
                        <p class="tab-table-collapse__title panel__heading">${title}</p>
                        <span class="tab-table-collapse__trigger-ico panel__trigger-ico ico btn btn--circle"></span>
                    </a>

                    <div class="tab-table-collapse__content panel__content collapse">
                        <div class="tab-table-collapse__content-inner ckec">
                            ${child}
                        </div>
                    </div>
                </div>
            </div>
      `;
    
    const createDateRow = (data) => {

      let detailRows = "";
      let year = new Date(data.date).getFullYear();

      data.details.forEach(detail => {
        let links = "";

        detail.links.forEach(link => {
          links += `
            <a href="${link.url}" target="${link.target}" class="outline-btn-w-icon outline-btn-w-icon--bf-view">
                ${link.title}
            </a>
          `;
        });

        detailRows += `
          <tr>
              <td>
                  <p>
                    ${detail.desc}
                  </p>
              </td>
              <td>
                  <div class="tab-table__btn-group">
                      ${links}
                  </div>
              </td>
          </tr>
        `;
      });      

      return (
        `
          <table class="tab-table" data-year="${year}">
              <thead>
                  <tr>
                      <th scope="col" colspan="100"> ${data.title[0]} <span class="tab-table-spliter"></span> ${data.title[1]} </th>
                  </tr>
              </thead>
              <tbody>
                  ${detailRows}
              </tbody>
          </table>    
        `
      );
    };

    const updateButtonText = (element, showCollapse) => {
        if (showCollapse) {
            element.html(`${element.attr("data-collapse-text")} <span class="tab-table-search-bar__minus ico ico--minus"></span>`);
        } else {
            element.html(`${element.attr("data-expand-text")} <span class="tab-table-search-bar__minus ico ico--plus"></span>`);
        }
    }

    const loadData = async (url) => {
      await fetch(url)
        .then((response) => response.json())
        .then((json) => {
          data = json.data;
          deptKeys = json.deptKeys;
          deptOpts = json.deptOpts;
        })
        .catch((error) => console.error(error));
    };

    const renderTable = async ($form, $contentArea, data) => {
      let html = "";
      $contentArea.empty();

      data.forEach((d) => {
        let tablehtml = "";
        
        d.data.forEach(item => {
          tablehtml += createDateRow(item);
        });

        html += createCollapse(
            d.title,
            tablehtml,
            {
              year: [...new Set(d.data.map((item) => new Date(item.date).getFullYear()))].join(", "),
              count: [...new Set(d.data.map((item) => item.count))].join(", "),
            }
          )
      });

      $contentArea.html(html);

      collapse.init();

      // update collapse button text
      let $collapseBtn = $form.find(".tab-table-search-bar__collapse-all button");
      let hasShown = $contentArea.find(".tab-table-collapse__content:not(.show)").length == 0;
      updateButtonText($collapseBtn, hasShown);
    };

    const filterData = (opts) => {
      let filteredData = data;

      if (opts.year) {
        filteredData = filteredData.filter((d) =>
          d.data.some(
            (dd) =>
              new Date(dd.date).getFullYear().toString() === opts.year
          )
        );
      }

      if (opts.count) {
        filteredData = filteredData.filter((d) =>
          d.data.some((dd) => dd.count.toString() === opts.count.toString())
        );

        filteredData = filteredData.map((d) => {
          return {
            ...d,
            data: d.data.filter((dd) => dd.count.toString() === opts.count),
          };
        });
      }

      return filteredData;
    };

    const getAllCount = (data) => {
      return [...new Set(data.map((d) => d.data.map((dd) => dd.count)).flat())];
    };

    const getAllYear = (data) => {
      return [
        ...new Set(
          data
            .map((d) => d.data.map((dd) => dd.date))
            .flat()
            .map((date) => new Date(date).getFullYear().toString())
        ),
      ].sort((a, b) => b - a);
    };

    const updateFormDropdown = async ($form, data, field = ["year", "count"]) => {
      const $yearSelect = $form.find("select[name='year']");
      const $countSelect = $form.find("select[name='count']");
      const $defaultYearOpts = $form.find(
        "select[name='year'] option[value='']"
      );
      const $defaultCountOpts = $form.find(
        "select[name='count'] option[value='']"
      );

      // year
      if (field.includes("year")) {
        $yearSelect.empty().append($defaultYearOpts);
        let allyears = getAllYear(data);

        allyears.forEach((year) => {
          $yearSelect.append(createOption(year, year));
        });

        $yearSelect.selectpicker("refresh");
      }

      // count
      if (field.includes("count")) {
        $countSelect.empty().append($defaultCountOpts);
        let allcounts = getAllCount(data);

        allcounts.forEach((count) => {
          $countSelect.append(createOption(count, count));
        });

        $countSelect.selectpicker("refresh");
      }
    };

    const setupResetButton = async ($form) => {
      const $resetBtn = $form.find("button[type='reset']");
      const $yearSelect = $form.find("select[name='year']");
      const $countSelect = $form.find("select[name='count']");

      $resetBtn.on("click", () => {
        $yearSelect.selectpicker("val", "");
        $countSelect.selectpicker("val", "");

        $form.trigger("submit");
      });
    };

    const setupFilter = async ($form, $contentArea) => {
      $form.on("submit", (e) => {
        e.preventDefault();

        // Get form data
        const fd = new FormData(e.target);
        const filterOpts = {
          year: fd.get("year") || undefined,
          count: fd.get("count") || undefined,
        };

        // Update URL search params
        if (filterOpts.year) {
          setUrlSearchParam("year", filterOpts.year);
        } else {
          setUrlSearchParam("year", "");
        }

        if (filterOpts.count) {
          setUrlSearchParam("count", filterOpts.count);
        } else {
          setUrlSearchParam("count", "");
        }

        const filteredData = filterData(filterOpts);
        renderTable($form, $contentArea, filteredData);

      });
    }

    const setupFilterDropdownOptions = async ($form) => {
      const $yearSelect = $form.find("select[name='year']");
      const $countSelect = $form.find("select[name='count']");
      const $defaultYearOpts = $form.find(
        "select[name='year'] option[value='']"
      );
      const $defaultCountOpts = $form.find(
        "select[name='count'] option[value='']"
      );

      $yearSelect.on("changed.bs.select", (e, clickedIndex, isSelected, previousValue) => {
        const selectedYear = $yearSelect.val();
        const selectedCount = $countSelect.val();

        const filterOpts = {
          year: selectedYear,
          count: undefined,
        };

        const filteredData = filterData(filterOpts);
        updateFormDropdown($form, filteredData, ["count"]);
      });
    };

    $(".js-filter-paper").each(async (i, component) => {
      const dataUrl = $(component).attr("data-json");
      const $form = $(component).find("form");
      const $contentArea = $(component).find(".js-filter-paper__content");
      const params = {
        year: getURLSearchParams("year"),
        count: getURLSearchParams("count"),
      };

      await loadData(dataUrl);
      await updateFormDropdown($form, data);
      await setupResetButton($form);
      await setupFilter($form, $contentArea);
      await setupFilterDropdownOptions($form);

      let paperData = data;
      if (params.year || params.count) {
        paperData = filterData(params);

        // update form dropdown to select the correct options
        $form.find("select[name='year']").selectpicker("val", params.year ?? "");
        $form.find("select[name='count']").selectpicker("val", params.count ?? "");
      }
      await renderTable($form, $contentArea, paperData);
    });
  };

  return {
    init: setup,
  };
})();

export default jsFilterPaper;