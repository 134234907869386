var orgAnchor = (function () {
  var ROOM = 50,
    topHeight = 0;

  function setup() {
    $('.js-org-anchor').each(function () {
      var $this = $(this);

      $this.on('click touchstart', function (e) {
        e.preventDefault();

        var $thisHref = $this.attr('href'),
          $target = $($thisHref),
          headerHeight = $('.page-head').outerHeight();

        if ($target.length) {
          const offset = $target.offset().top - headerHeight - ROOM;

          $('html, body').animate(
            {
              scrollTop: offset,
            },
            300,
            function () {
              $target.attr('tabindex', '-1');
              $target.focus();
            }
          );
        }
      });
    });

    $('#skip').on('click', function (e) {
      e.preventDefault();
      $('#web-access').focus();
    });
  }

  return {
    init: setup,
  };
})();

export default orgAnchor;
