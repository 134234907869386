const bsDropdown = (function () {
  function setup() {
    $('.search').on('shown.bs.dropdown', function () {
      var $this = $(this);

      $this.find('.search-form__box').focus();
    });
  }

  return {
    init: setup,
  };
})();

export default bsDropdown;
