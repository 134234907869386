import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const homeScrollspy = (function() {
  function setup() {
    const scrollspy = document.getElementById('home-scrollspy');
    if (!scrollspy) return;

    const validLinks = [];
    
    const targets = [...scrollspy.querySelectorAll('a')].reduce((acc, link) => {
      const href = link.getAttribute('href');
      const target = document.querySelector(href);
      if (target) {
        validLinks.push(link);
        acc.push(target);
      };
      return acc;
    }, []);

    gsap.registerPlugin(ScrollTrigger);

    const setupTracking = () => {
      targets.forEach((target, i) => {
        ScrollTrigger.create({
          trigger: target,
          start: () => `top top+=${scrollspy.getBoundingClientRect().top}px`,
          end: () => `bottom top+=${scrollspy.getBoundingClientRect().top}px`,
          toggleClass: { targets: [scrollspy, validLinks[i]], className: 'active' },
        });
      });
    };

    const mm = gsap.matchMedia();
    // breakpoints to match with ScrollTriggers of tracked sections for correct position recalculations
    mm.add("(min-width: 1200px)", setupTracking);
    mm.add("(min-width: 992px) and (max-width: 1199px)", setupTracking);
  }

  return {init: setup};
})();

export default homeScrollspy;