import { gsap } from "gsap";

function randInt(max) {
    return Math.floor(Math.random() * max);
  }

class SplitTextAnimation {

    
    constructor(
        __textDom, 
        __options = {}
    ) {

        this.textDom = __textDom;
        
        let defaultOpts = {
            animateWhenVisible: true,
        }

        this.opts = Object.assign({}, defaultOpts, __options);

        if(this.opts.animateWhenVisible) {
            let obsOpts = {
                root: null,
                threshold: 0,
                // rootMargin: "50% 0%"
              };

            let callback = (entries, observer) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        this.animate();
                        observer.unobserve(this.textDom);
                    }
                });
            }
            let observer = new IntersectionObserver(callback, obsOpts);
            observer.observe(this.textDom);
        } else {
            this.animate();
        }
    }

    animate() {
        let randRange = 50;
        const treeWalker = document.createTreeWalker(
            this.textDom,
            NodeFilter.SHOW_TEXT,
        );

        let textDomCopy = this.textDom.cloneNode(this.textDom);
        textDomCopy.innerHTML = "";
        $(textDomCopy).toggleClass("split-text__clone", true);

        let wrappers = [];
          
        while (treeWalker.nextNode()) {
            const node = treeWalker.currentNode;
            let chars = node.data.split("");
            let $parentNode = $(node.parentNode);

            chars.forEach(char => {
                let textNode = document.createTextNode(char)
                let wrapper = document.createElement("span");
                wrapper.appendChild(textNode);

                if($parentNode[0] == this.textDom) {
                    textDomCopy.appendChild(wrapper);
                } else {
                    let rootCopy = $parentNode.clone()[0];
                    rootCopy.innerHTML = "";
                    rootCopy.appendChild(wrapper);
                    textDomCopy.appendChild(rootCopy); 
                }

                wrappers.push(wrapper);
            });
        }
        
        this.textDom.after(textDomCopy);

        $(this.textDom).hide();
        
        wrappers.forEach((wrapper, index) => { 
            let tl = gsap.timeline(); 
            
            if (index % 2 == 0) {
                tl.from(wrapper, 
                {
                    opacity: 0.3,
                })
                .to(wrapper, 
                {
                    opacity: 1,
                    duration: randInt(randRange) / 100,
                })
                .to(wrapper, 
                {
                    opacity: 0,
                    duration: randInt(randRange) / 100,
                })
                .to(wrapper, 
                {
                    opacity: 1,
                    duration: randInt(randRange) / 100,
                })
            } else {
                tl.from(wrapper, 
                {
                    opacity: 1,
                })
                .to(wrapper, 
                {
                    opacity: 0,
                    duration: randInt(randRange) / 100,
                })
                .to(wrapper, 
                {
                    opacity: 1,
                    duration: randInt(randRange) / 100,
                })
            }
        })

        setTimeout(() => {
            $(this.textDom).show();
            $(textDomCopy).hide();

            $(this.textDom).toggleClass("split-text--animated", true);
            $(textDomCopy).toggleClass("split-text--animated", true);
        }, (randRange / 100) * 4 * 1000);
    }
};

const init = (() => {

    const setup = () => {
        let splitTexts = document.querySelectorAll(".split-text");

        splitTexts.forEach(splitText => {
            new SplitTextAnimation(splitText);
        });
    }

    return {init: setup};
})();

export { SplitTextAnimation, init };