import SwiperSlider from "@shared/js/scripts/swiperSlider";

// override set theme method to also set theme on the html element for global styling
class ThemeSwiperSlider extends SwiperSlider {
  setSliderTheme($__slide) {
    let __this = this;
    const theme = $__slide.attr('data-swiper-theme');
    if (theme) {
      $(__this.elems.swiper__outer).attr('data-swiper-theme', theme);
      document.documentElement.setAttribute('data-top-theme', theme);
    } else {
      $(__this.elems.swiper__outer).removeAttr('data-swiper-theme');
      document.documentElement.removeAttribute('data-top-theme');
    }
  }
}

const keyvisual = (function() {
  function setup() {
    const kv = document.querySelector('.keyvisual');
    if (!kv) return;

    const items = kv.querySelectorAll('.js-swiper-item');
    if (items.length < 2) return;

    const kvSlider = kv.querySelector('.js-swiper');
    const progress = kv.querySelector('.keyvisual__progress');
    const nextImg = kv.querySelector('.keyvisual__next-img');
    const nextTitle = kv.querySelector('.keyvisual__next-title');
    const nextSubtitle = kv.querySelector('.keyvisual__next-subtitle');
    const btnPrev = kv.querySelector('.keyvisual__arrow--prev');
    const btnNext = kv.querySelector('.keyvisual__arrow--next');
    const slideDuration = parseInt(kvSlider.getAttribute('data-autoplay-interval')) || 5000;
    const isAutoPlay = kvSlider.getAttribute('data-autoplay') !== 'false';
    const nextTitleStyle = getComputedStyle(nextTitle);

    const setProgressAnimation = (swiper) => {
      progress._animation = new Animation(new KeyframeEffect(progress, [
        {transform: "translate3d(-100%, 0, 0)"},
        {transform: "translate3d(0, 0, 0)"},
      ], { duration: slideDuration, easing: "ease-out", fill: "forwards" }));

      if (!swiper || swiper.autoplay.running) {
        progress._animation.play();
      }
    };

    const cancelProgressAnimation = (reset=true) => {
      if (progress._animation) {
        if (reset) {
          progress.style.transform = "translate3d(-100%, 0, 0)";
        } else {
          progress._animation.commitStyles();
        }
        progress._animation.cancel();
      }
    };

    const getNextSlide = (swiper) => {
      const i = (swiper.realIndex + 1) % items.length;
      return items.item(i);
    };

    const drawNextImg = (swiper) => {
      const nextSlide = swiper ? getNextSlide(swiper) : items.item(1);
      const img = nextSlide.querySelector('.keyvisual__img img');
      nextImg.style.backgroundImage = `url(${img.src})`;
    };

    const displayNextText = (swiper) => {
      const nextSlide = swiper ? getNextSlide(swiper) : items.item(1);
      const nextTitleText = nextSlide.querySelector('.keyvisual__title')?.textContent || '';
      const nextSubtitleText = nextSlide.querySelector('.keyvisual__subtitle')?.textContent || '';
      
      nextSubtitle.innerHTML = nextSubtitleText;
      nextSubtitle.parentElement.classList.toggle('show-dash', nextSubtitleText);
      nextTitle.innerHTML = nextTitleText;
      nextTitle.parentElement.classList.toggle('show-dash', !nextSubtitleText);

      const isNextTitleWrapped = parseFloat(nextTitleStyle.getPropertyValue('height'), 10) > parseFloat(nextTitleStyle.getPropertyValue('line-height'), 10);
      nextTitle.parentElement.classList.toggle('multiline', isNextTitleWrapped);
    };

    const setPaginationClass = (keyvisual) => {
      const pagination = keyvisual.querySelector('.js-swiper-pagination');
      if (pagination?.children.length > 25) {
        pagination.classList.add('js-swiper-pagination--25plus');
      } else if (pagination?.children.length > 20) {
        pagination.classList.add('js-swiper-pagination--20plus');
      }
    };
    
    const options = {
      effect: 'slide',
      btnPlayPause: true,
      navigation: {
        prevEl: btnPrev,
        nextEl: btnNext,
      }
    };

    if (isAutoPlay) {
      options.autoplay = {
        delay: slideDuration
      };
      setProgressAnimation();
    }

    displayNextText();
    drawNextImg();
    const kvSwiper = new ThemeSwiperSlider(kv, options);
    setPaginationClass(kv);
    
    kvSwiper.swiper.on('autoplayStop', function () {
      cancelProgressAnimation();
    })
    .on('slideChange', function() {
      cancelProgressAnimation();
    })
    .on('autoplayStart slideChangeTransitionEnd', function () {
      setProgressAnimation(this);
    })
    .on('slideChangeTransitionStart', function () {
      drawNextImg(this);
      displayNextText(this);
    });

    nextImg.addEventListener('click', function() {
      kvSwiper.swiper.slideNext();
    });
  }

  return { init: setup };
})();

export default keyvisual;