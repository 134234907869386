const socialShare = (function () {
  function setup() {
    $('.js-social-whole')
      .find('a')
      .on('click', function () {
        var href,
          text = encodeURIComponent(document.title),
          url = encodeURIComponent(location.href),
          m = $(this).data('media');

        if (m == 'facebook') {
          href = 'https://www.facebook.com/sharer/sharer.php?u=' + url;
        } else if (m == 'twitter') {
          href = 'https://twitter.com/intent/tweet?text=' + text + '&url=' + url;
        } else if (m == 'linkedin') {
          href = 'https://www.linkedin.com/shareArticle?mini=true&url=' + url + '&title=' + text;
        } else if (m == 'googleplus') {
          href = 'https://plus.google.com/share?url=' + url;
        } else if (m == 'whatsapp') {
          href = 'https://api.whatsapp.com/send?text=' + text + ' ' + url;
        } else if (m == 'wechat') {
          href = 'https://www.addtoany.com/ext/wechat/share/#url=' + url + '&title=' + text;
        } else if (m == 'weibo') {
          href = 'http://service.weibo.com/share/share.php?url=' + url + '&title=' + text;

          if ($('meta[property="og:image"]').length > 0) {
            var img = $('meta[property="og:image"]').eq(0).attr('content');
            if (img != '') {
              href += '&searchPic=false&pic=' + encodeURIComponent(img);
            }
          }
        } else if (m == 'pinterest') {
          href = 'http://pinterest.com/pin/create/button/?url=' + url + '&description=' + text;
        } else if (m == 'email') {
          href = 'mailto:?subject=' + text + '&body=' + url;
        } else if (m == 'youtube') {
          return;
        }

        window.open(
          href,
          'Share',
          'height=540, width=640, toolbar=no, menubar=no, scrollbars=no, resizable=no, location=no, directories=no, status=no'
        );

        return false;
      });

    $('.js-social-self')
      .find('a')
      .on('click', function () {
        var href = $(this).data('fmt'),
          p = $(this).closest('.social__list'),
          url = p.data('url'),
          text = p.data('desc');

        if (url.indexOf('://') == -1) {
          var arr = window.location.href.split('/');
          var slash = url.charAt(0) == '/' ? '' : '/';
          url = arr[0] + '//' + arr[2] + slash + url;
        }

        href = href.replace('{url}', encodeURIComponent(url));

        if (text != '') {
          href = href.replace('{text}', encodeURIComponent(text));
        } else {
          href = href.replace('subject={text}&', '');
        }

        window.open(
          href,
          'Share',
          'height=540, width=640, toolbar=no, menubar=no, scrollbars=no, resizable=no, location=no, directories=no, status=no'
        );

        return false;
      });
  }

  return {
    init: setup,
  };
})();

export default socialShare;
