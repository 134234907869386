import svgPanZoom from 'svg-pan-zoom';

const svgZoom = (function () {
  function setup() {
    const option = {
      zoomScaleSensitivity: 0.2,
      mouseWheelZoomEnabled: false,
      dblClickZoomEnabled: false,
      maxZoom: 2.5,
      minZoom: 0.75,
    };

    const $zoomInBtn = $('.js-org-zoom-in');
    const $zoomOutBtn = $('.js-org-zoom-out');
    const $resetBtn = $('.js-org-zoom-reset');
    const $window = $(window);

    $('.js-svg-zoom').each(function (idx) {
      const panZoom = svgPanZoom(this, option);

      $zoomInBtn.eq(idx).on('click', function (e) {
        e.preventDefault();
        panZoom.zoomIn();
      });

      $zoomOutBtn.eq(idx).on('click', function (e) {
        e.preventDefault();
        panZoom.zoomOut();
      });

      $resetBtn.eq(idx).on('click', function (e) {
        e.preventDefault();
        panZoom.resetZoom();
      });

      // Resize svg when window resizes
      $window.on('resize', function () {
        panZoom.resize();
        panZoom.fit();
        panZoom.center();
      });
    });
  }

  return {
    init: setup,
  };
})();

export default svgZoom;
