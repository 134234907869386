import '@shared/js/scripts/browserInfo';
import '@shared/js/scripts/var';
import '@shared/js/scripts/lang';
import '@shared/js/scripts/commonFn';
import enableJS from '@shared/js/scripts/enableJS';
import queryTest from '@shared/js/scripts/queryTest';
// import cookieAlert from '@shared/js/scripts/cookieAlert';
import socialShare from '@shared/js/scripts/socialShare';
import jsNav from '@shared/js/scripts/jsNav';
import menu from '@shared/js/scripts/menu';
import videoPlayer from '@shared/js/scripts/videoPlayer';
import lightbox from '@shared/js/scripts/lightbox';
import tab from '@shared/js/scripts/tab';
import tooltip from '@shared/js/scripts/tooltip';
import bsDropdown from '@shared/js/scripts/bsDropdown';
import bsModal from '@shared/js/scripts/bsModal';
import bsSelect from '@shared/js/scripts/bsSelect';
import backToTop from '@shared/js/scripts/backToTop';
import responsive2xImg from '@shared/js/scripts/responsiveImg';
import responsiveTable from '@shared/js/scripts/responsiveTable';
import responsiveVideo from '@shared/js/scripts/responsiveVideo';
import animateOnScroll from '@shared/js/scripts/animateOnScroll';
import jsShowInview from '@shared/js/scripts/jsShowInview';
import jsMoveArea from '@shared/js/scripts/jsMoveArea';
import jsShowHideList from '@shared/js/scripts/jsShowHideList';
import {
  infiniteWithList,
  infiniteWithMasonry,
  clickToLoadMore,
  infiniteFilterItems,
} from '@shared/js/scripts/loadMore';
import jsMasonry from '@shared/js/scripts/jsMasonry';
import jsAjaxFilter from '@shared/js/scripts/jsAjaxFilter';
import jsScrollToFixed from '@shared/js/scripts/jsScrollFloating';
import pagePrint from '@shared/js/scripts/print';
import svgZoom from '@shared/js/scripts/svgZoom';
import orgAnchor from '@shared/js/scripts/orgAnchor';
import popupMessage from '@shared/js/scripts/popupMessage';
// import jumpToForm from '@shared/js/scripts/jumpToForm'
// import slick from '@shared/js/scripts/slick'
import collapse from '@shared/js/scripts/collapse';
import svg2inline from '@shared/js/scripts/svg2inline';
import typeaheadSearch from '@shared/js/scripts/solrSearch';
import linkRel from '@shared/js/scripts/linkRel';
import headerJS from '@components/header/header';
import dropdownSearch from '@shared/js/scripts/dropdownSearch';
import cssHasPolyfill from '@shared/js/scripts/cssHasPolyfill';

/* Homepage */
import keyInitiatives from '@sections/key-initiatives/keyInit';
import whoweare from '@sections/who-we-are/index';
import keyvisual from '@sections/keyvisual/keyvisual';
import { init as SplitTextAnimation } from '@shared/js/scripts/splitTextAnimation.js';
import galleryListing from '@sections/gallery-listing/index';

/* Components */
import footer from '@components/footer';
import footerSlider from '@components/footer/slider/index';
import homeScrollspy from '@components/scrollspy/scrollspy';
import sectionBanner from '@components/section-banner/index';
import highlightSlider from '@components/highlight-slider';
import layersSlider from '@sections/layers-slider/index';
import pressRelease from '@sections/press-release/index';
import showMoreSlider from '@components/show-more-slider/index';
import imageSlider from '@components/image-slider/index';
import videoGallerySlider from '@components/video-gallery/index';
import breadcrumb from '@components/breadcrumb/index';
import figureAccordion from '@components/accordion-2bg/index';
import formPreviewSlider from '@components/form-preview-slider';
import generalContentTable from '@sections/general-content/table.js';
import storiesSlider from '@components/stories-slider';
import storiesSliderWithDesc from '@components/stories-slider-w-desc';
import formGridSelect from '@components/form-elements/selectInput';
import customCheckable from '@components/form-elements/custom-checkable';
import eventSearchBar from '@components/event-search-bar/index';
import eventCalendar from '@components/event-calendar/index';
import storiesListing from '@sections/success-stories/stories-listing';
import galleryHighlightSlider from '@components/gallery-highlight-slider/index';
import caseSection from '@components/case-sections/index';
import glassSlider from '@components/glass-slider/index';
import orgChart from '@sections/org-chart/org-chart';
import SubscribeBtn from '@components/subscribe-btn/index';
import bfTriangle from '@components/bf-triangle/index';
import tabTableSearchBar from '@components/tab-table-search-bar/index';
import tabNav from '@components/tab-nav/index';
import jsFilterPaper from '@shared/js/scripts/jsFilterPaper';
import jsFilterPressRelease from '@shared/js/scripts/jsFilterPressRelease';

// import videoBlk from '@component/def_03/js/index';
// import footerSlider from '@component/footerSlider/js/index';
// import historySlider from '@component/prj_44/js/index';
// import heroSlider from '@component/heroSlider/js';
// import sliderWork from '@component/prj_17/js/index';
// import sliderHighlight from '@component/prj_10/js/index';
// import awardSlider from '@component/prj_45/js/index';
// import successSlider from '@component/prj_46/js/index';
// import caseSlider from '@component/prj_47/js/index';
// import figureIconTextBlock from '@component/prj_37/js/index';

(function ($, root, undefined) {
  $(function () {
    'use strict';

    enableJS.init();

    queryTest.init();

    // cookieAlert.init();

    // socialShare.init();

    // jsNav.init();

    headerJS.init();

    svg2inline.init();

    menu.init();

    videoPlayer.init();

    lightbox.init();

    tab.init();

    // collapse.init();

    tooltip.init();

    bsDropdown.init();

    bsModal.init();

    bsSelect.init();

    cssHasPolyfill.init();

    backToTop.init();

    responsive2xImg.init();

    responsiveTable.init();

    responsiveVideo.init();

    animateOnScroll.init();

    jsShowInview.init();

    jsMoveArea.init();

    jsShowHideList.init();

    // infiniteWithMasonry.init();

    // infiniteFilterItems.init();

    // jsMasonry.init();

    jsAjaxFilter.init();

    // jsScrollFloating.init();
    jsScrollToFixed.init();

    // numberAnimate.init();

    pagePrint.init();

    svgZoom.init();

    orgAnchor.init();

    popupMessage.init();

    collapse.init();

    footer.init();

    footerSlider.init();

    highlightSlider.init();

    showMoreSlider.init();

    imageSlider.init();

    videoGallerySlider.init();

    formPreviewSlider.init();

    // videoBlk.init();

    // footerSlider.init();

    // historySlider.init();

    // heroSlider.init();

    // sliderWork.init();

    // jumpToForm.init();

    // sliderHighlight.init();

    // awardSlider.init();

    // successSlider.init();

    // caseSlider.init();

    figureAccordion.init();

    // figureIconTextBlock.init();

    // slick.init();

    // infiniteWithList.init();

    // infiniteWithMasonry.init();

    // clickToLoadMore.init();

    typeaheadSearch.init();

    keyvisual.init();

    keyInitiatives.init();

    whoweare.init();

    homeScrollspy.init();

    SplitTextAnimation.init();

    sectionBanner.init();

    layersSlider.init();

    pressRelease.init();

    breadcrumb.init();

    generalContentTable.init();    
    storiesSlider.init();

    formGridSelect.init();

    customCheckable.init();

    eventSearchBar.init();

    eventCalendar.init();

    storiesListing.init();

    galleryHighlightSlider.init();

    galleryListing.init();

    caseSection.init();

    glassSlider.init();

    linkRel.init();

    orgChart.init();

    SubscribeBtn.init();

    bfTriangle.init();

    tabTableSearchBar.init();

    tabNav.init();

    storiesSliderWithDesc.init();

    dropdownSearch.init();

    jsFilterPaper.init();

    jsFilterPressRelease.init();

  });
})(jQuery, this);
