import SwiperSlider from '@shared/js/scripts/swiperSlider';

const highlightSlider = (() => {
  const highlightSlider = document.querySelector('.highlight-slider');
  const setup = () => {
    if (!highlightSlider) return;
    const options = {
      slidesPerView: 1,
      slidesPerGroup: 1,
      effect: 'fade',
      btnPlayPause: true,
      autoplay: {
        delay: 8000,
        disableOnInteraction: false,
      },
    };
    const swiper = new SwiperSlider(highlightSlider, options);
  };
  return {
    init: setup,
  };
})();

export default highlightSlider;
