import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const keyInitiatives = (function() {
  function setup() {
    const pinSection = document.querySelector('.js-keyinit-pin');
    if (!pinSection) return;
    
    gsap.registerPlugin(ScrollTrigger);

    // DOM elements
    const itemsContainer = pinSection.querySelector('.key-init__items-container');
    const itemsContainerInner = itemsContainer.firstElementChild;
    const textContainer = pinSection.querySelector('.key-init__text-container');
    const button = pinSection.querySelector('.key-init__button');
    const items = [...itemsContainer.querySelectorAll('.key-init__item')];
    const leftColumn = pinSection.querySelector('.key-init__column--left');
    const rightColumn = pinSection.querySelector('.key-init__column--right');

    const mm = gsap.matchMedia();

    // set up animations for larger screens
    mm.add({
      isDesktopWide: "(min-width: 1200px)",
      isDesktopTight: "(min-width: 992px) and (max-width: 1199px)",
    }, (context) => {
      const { isDesktopWide } = context.conditions;

      // spacing values, in px
      const floatSidePadding = 26;
      const floatVerticalMargin = isDesktopWide ? 60 : 30;
      const itemsStartOffset = isDesktopWide ? 60 : 40;
      const xMax = 548;

      // groupings for setting animation sequence and x translation direction
      const leftItems = new Set();
      const rightItems = new Set();

      // callback to set x position of items based on their y position
      function setX () {
        const outerRect = pinSection.getBoundingClientRect();
        const innerRect = textContainer.getBoundingClientRect();
        const floatInComplete = innerRect.bottom;
        const floatInStart = outerRect.bottom - itemsStartOffset;
        const floatOutStart = innerRect.top;
        const floatOutComplete = outerRect.top;
        const xMagnitude = Math.min(itemsContainerInner.getBoundingClientRect().left - floatSidePadding, xMax);
        
        items.forEach((item) => {
          const itemRect = item.getBoundingClientRect();
          const xDirection = rightItems.has(item) ? 1 : -1;
          const xEnd = xMagnitude * xDirection;
          const animate = {};

          if (itemRect.top > floatInStart) {
            animate.x = 0;
          } else if (itemRect.top <= floatInStart && itemRect.top >= floatInComplete) {
            animate.x = gsap.utils.mapRange(floatInStart, floatInComplete, 0, xEnd, itemRect.top);
          } else if (itemRect.top < floatInComplete && itemRect.bottom > floatOutStart) {
            animate.x = xEnd;
          } else if (itemRect.bottom <= floatOutStart && itemRect.bottom >= floatOutComplete) {
            animate.x = gsap.utils.mapRange(floatOutStart, floatOutComplete, xEnd, xEnd * 0.25, itemRect.bottom);
          }
    
          gsap.set(item, animate);
        });
      }
      
      // set timeline to coordinate items' y position, controlled by scroll
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: pinSection,
          start: () => `top top`,
          end: () => `+=${pinSection.clientHeight + itemsContainer.clientHeight}`,
          scrub: true, // use scroll to play/rewind the timeline
          pin: pinSection,
          anticipatePin: 1,
          onUpdate: setX,
          // markers: true
        }
      });

      // move the second item up to the top of container to prepare for floating
      if (items.at(1)) {
        gsap.set(items.at(1), {y: () => `-=${items.at(1).offsetTop}`});
      }

      // set animation for all items in sequence upfront
      items.forEach((item, index) => {
        // add item label
        tl.addLabel(`item_${index}`);

        // since items float up in pairs, determine the start label for the current item
        const startLabel = index % 2 ? `item_${index - 1}` : `item_${index}`;

        // move the current item up into floating position
        tl.to(item, {
          y: () => `-=${item.clientHeight + floatVerticalMargin}`,
        }, startLabel);

        // at the same time, move the next pair of items up into starting position
        if (index % 2 !== 1) {
          const nextPair = items.filter((item, idx) => idx === index + 2 || idx === index + 3);
          
          nextPair.forEach((laterItem) => {
            tl.to(laterItem, {
              y: () => `-=${laterItem.offsetTop}`,
            }, startLabel);
          });
        }

        // determine whether the left or right side should the current item be added to
        // at the same time, move previous items up, which are on the same side, to create space
        const leftHeight = Array.from(leftItems).reduce((acc, item) => acc + item.clientHeight, 0);
        const rightHeight = Array.from(rightItems).reduce((acc, item) => acc + item.clientHeight, 0);
        const sideToAdd = leftHeight <= rightHeight ? leftItems : rightItems;
        for (const prevItem of sideToAdd) {
          tl.to(prevItem, {
            y: () => `-=${item.clientHeight + floatVerticalMargin}`,
          }, startLabel);
        }
        sideToAdd.add(item);
      });

      items.forEach((item, index) => {
        item.querySelectorAll("button, a").forEach(focusable => {
          focusable.addEventListener("focus", (e) => {
            if (!window.matchMedia("(min-width: 991px)").matches) return;

            e.preventDefault();

            const rowsOfAnimation = (leftItems.size > rightItems.size ? leftItems.size : rightItems.size) + 1;
            const newProgress = 1 / rowsOfAnimation * Math.ceil((index + 1) / 2);
            tl.progress(newProgress);
            setX();
            e.target.focus({
              preventScroll: true
            });
          });
        })
      })


      button.addEventListener("focus", (e) => {
        if (!window.matchMedia("(min-width: 991px)").matches) return;

        e.preventDefault();
        e.target.focus({
          preventScroll: true
        });
      })

      // outro animation for all items
      tl.to(items, {
        y: () => `-=${pinSection.clientHeight}`,
      });

      return () => {
        // unset all x translations when this media query no longer matches
        gsap.set(items, {x: 0});
      };
    });

    // set up animations for tablet and mobile
    // orientation change on mobile may fail to refresh scrollTrigger, multiple close breakpoints are used to catch changes
    const mobileQueries = {
      mq1: "(min-width: 892px) and (max-width: 991px)",
      mq2: "(min-width: 792px) and (max-width: 891px)",
      mq3: "(min-width: 692px) and (max-width: 791px)",
      mq4: "(min-width: 592px) and (max-width: 691px)",
      mq5: "(min-width: 492px) and (max-width: 591px)",
      mq6: "(min-width: 392px) and (max-width: 491px)",
      mq7: "(max-width: 391px)",
    };

    // helper functions to calculate distances to scroll on mobile
    const mobileItemScrollDistance = () => itemsContainer.clientHeight + itemsContainer.offsetTop;
    const mobileOutroDistance = () => {
      const pinSectionCenterHeight = pinSection.clientHeight / 2;
      const textCenterOffset = textContainer.offsetTop + textContainer.clientHeight / 2;
      return pinSectionCenterHeight - textCenterOffset;
    };

    mm.add(mobileQueries, () => {
      // set two-column layout for items
      items.forEach((item, idx) => {
        if (idx % 2) {
          rightColumn.appendChild(item);
        } else {
          leftColumn.appendChild(item);
        }
      });
      
      // hide button
      gsap.set(button, {y: "300%", opacity: 0});
      
      // create scroll controlled timeline
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: pinSection,
          start: () => `top top`,
          end: () => `+=${mobileItemScrollDistance() + mobileOutroDistance()}`,
          scrub: true, // use scroll to play/rewind the timeline
          pin: pinSection,
          anticipatePin: 1,
          // markers: true,
        }
      });

      // enable tweaking the duration ratio of item translation in timeline from html
      const itemScrollScale = parseFloat(pinSection.getAttribute('data-item-scroll-scale')) || 1.05;
      
      // scroll items up and fade out title
      tl.addLabel('itemScroll')
      .to(items, {
        y: () => `-=${mobileItemScrollDistance()}`,
        duration: () => itemScrollScale * mobileItemScrollDistance() / mobileOutroDistance(),
        ease: "power1.inOut",
      })
      .to(textContainer, {
        opacity: 0.05,
        duration: 1,
      }, 'itemScroll');

      // outro animation for text and button
      tl.addLabel('outro')
      .set(textContainer, {zIndex: 3})
      .to(textContainer, {
        y: () => `+=${mobileOutroDistance()}`,
        opacity: 2,
        duration: 1,
      })
      .to(button, {y: 0, opacity: 1}, 'outro');

      // refresh scrollTrigger after a short delay to ensure correct positioning
      // especially in case the page resizes drastically, e.g. go from fullscreen mode to small window
      setTimeout(() => {
        // if a resize shoots past multiple breakpoints, by the time the callback runs, 
        // the scrollTrigger of a previous breakpoint may have been reverted, so check first
        if (tl && tl.scrollTrigger) {
          tl.scrollTrigger.refresh();
        }
      }, 10);

      return () => {
        // revert set styles when this media query no longer matches
        gsap.set(textContainer, {zIndex: 0});
        gsap.set(button, {y: 0, opacity: 1});
        // revert two-column layout
        items.forEach((item) => {
          itemsContainerInner.appendChild(item);
        });
      };
    });
  }

  return {init: setup};
})();

export default keyInitiatives;