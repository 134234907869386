var videoPlayer = (function () {
  var localizeSet = {
    en: {
      restart: 'Restart',
      rewind: 'Rewind {seektime}s',
      play: 'Play',
      pause: 'Pause',
      fastForward: 'Forward {seektime}s',
      seek: 'Seek',
      seekLabel: '{currentTime} of {duration}',
      played: 'Played',
      buffered: 'Buffered',
      currentTime: 'Current time',
      duration: 'Duration',
      volume: 'Volume',
      mute: 'Mute',
      unmute: 'Unmute',
      enableCaptions: 'Enable captions',
      disableCaptions: 'Disable captions',
      download: 'Download',
      enterFullscreen: 'Enter fullscreen',
      exitFullscreen: 'Exit fullscreen',
      frameTitle: 'Player for {title}',
      captions: 'Captions',
      settings: 'Settings',
      pip: 'PIP',
      menuBack: 'Go back to previous menu',
      speed: 'Speed',
      normal: 'Normal',
      quality: 'Quality',
      loop: 'Loop',
      start: 'Start',
      end: 'End',
      all: 'All',
      reset: 'Reset',
      disabled: 'Disabled',
      enabled: 'Enabled',
      advertisement: 'Ad',
      qualityBadge: {
        2160: '4K',
        1440: 'HD',
        1080: 'HD',
        720: 'HD',
        576: 'SD',
        480: 'SD',
      },
    },
    tc: {
      restart: '重新開始',
      rewind: '快退{seektime}秒',
      play: '播放',
      pause: '暫停',
      fastForward: '快進{seektime}秒',
      seek: '尋找',
      seekLabel: '{currentTime} / {duration}',
      played: '已播放',
      buffered: '已緩衝',
      currentTime: '當前時間',
      duration: '時長',
      volume: '音量',
      mute: '靜音',
      unmute: '取消靜音',
      enableCaptions: '開啟字幕',
      disableCaptions: '關閉字幕',
      download: '下載',
      enterFullscreen: '全屏',
      exitFullscreen: '退出全屏',
      frameTitle: '播放器 - {title}',
      captions: '字幕',
      settings: '設定',
      pip: '畫中畫',
      menuBack: '返回上一級菜單',
      speed: '速度',
      normal: '正常',
      quality: '質量',
      loop: '循環',
      start: '開始',
      end: '結束',
      all: '全部',
      reset: '重置',
      disabled: '禁用',
      enabled: '啟用',
      advertisement: '廣告',
      qualityBadge: {
        2160: '超高清',
        1440: '高清',
        1080: '高清',
        720: '高清',
        576: '標清',
        480: '標清',
      },
    },
    sc: {
      restart: '重新开始',
      rewind: '快退{seektime}秒',
      play: '播放',
      pause: '暫停',
      fastForward: '快进{seektime}秒',
      seek: '寻找',
      seekLabel: '{currentTime} / {duration}',
      played: '已播放',
      buffered: '已缓冲',
      currentTime: '当前时间',
      duration: '时长',
      volume: '音量',
      mute: '静音',
      unmute: '取消静音',
      enableCaptions: '开启字幕',
      disableCaptions: '关闭字幕',
      download: '下载',
      enterFullscreen: '全屏',
      exitFullscreen: '退出全屏',
      frameTitle: '播放器 - {title}',
      captions: '字幕',
      settings: '设置',
      pip: '画中画',
      menuBack: '返回上一级菜单',
      speed: '速度',
      normal: '正常',
      quality: '质量',
      loop: '循环',
      start: '开始',
      end: '结束',
      all: '全部',
      reset: '重置',
      disabled: '禁用',
      enabled: '启用',
      advertisement: '广告',
      qualityBadge: {
        2160: '超高清',
        1440: '高清',
        1080: '高清',
        720: '高清',
        576: '标清',
        480: '标清',
      },
    },
  };

  function setup(_container) {
    var $c = _container ? _container : $('body');
    _competePop($c);
  }

  function _competePop(_container) {
    var $c = _container ? _container : $('body'),
      $video = $c.find('.js-video');

    if (!$video.length) {
      return;
    }

    $video.each(function (i) {
      var $this = $(this),
        vId = $this.attr('id');

      var player = new Plyr('#' + vId, {
        // tooltips: { controls: true, seek: true },
        // i18n: localizeSet[getLang()],
      });

      player.on('ready', function(event) {
        var instance = event.detail.plyr;
        var controls = $(instance.elements.controls);
        var roleMenu = controls.find('[hidden]').find('[role="menu"]');

        roleMenu.each(function() {

          if(!$(this).children().length) {
            $(this).removeAttr('role');
          }
        });

      });
    });
  }

  function videoDomGenerate(_file, _cover, _caption) {
    return (
      '<div class="mfp-hide js-video" id="video-dummy">\
              <video id="video-player" class="popup-video" poster="' +
      _cover +
      '" controls autoplay controlslist="nodownload">\
                <source type="video/mp4" src="' +
      _file +
      '">\
              </video>\
            </div>'
    );
  }

  return {
    init: setup,
    domGenerate: videoDomGenerate,
  };
})();

export default videoPlayer;
