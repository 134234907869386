

const sectionBanner = (()=>{

    const setupMenuTheme = (banner) => {
        if (banner.classList.contains("section-banner--dark")) {
            document.documentElement.setAttribute('data-top-theme', "dark");
        }
    }

    const setup = () => {
        const banner = document.querySelector(".section-banner");

        if (!banner) return;

        setupMenuTheme(banner);
    }

    return {init: setup}
})();

export default sectionBanner;