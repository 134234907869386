import SwiperSlider from '@shared/js/scripts/swiperSlider';

const breakpoints = {
  md: 768,
  lg: 992,
};

const footerSlider = (() => {
  const footerSlider = document.querySelector('.footer-slider');
  const setup = () => {
    if(!footerSlider) return;
    const options = {
      slidesPerView: 1,
      slidesPerGroup: 1,
      effect: 'slide',
      btnPlayPause: true,
      spaceBetween: 24,
      loop: false,
      autoplay: {
        delay: 8000,
        disableOnInteraction: false,
      },
      breakpoints: {
        [breakpoints.md]: {
          slidesPerView: 3,
          slidesPerGroup: 3,
        },
        [breakpoints.lg]: {
          slidesPerView: 7,
          slidesPerGroup: 7,
        },
      },
    };
    const swiper = new SwiperSlider(footerSlider, options);
  };
  return {
    init: setup,
  };
})();

export default footerSlider;
