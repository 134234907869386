import SwiperSlider from "@shared/js/scripts/swiperSlider";

const glassSlider = (() => {
  const setupSwiper = (component) => {
    const swiper = new SwiperSlider(component, {
      btnPlayPause: true,
        autoplay: {
          delay: 8000,
          waitForTransition: true,
          disableOnInteraction: false,
        },
      loop: true,
      effect: "swipe",
      slidesPerView: 1.001,
    });

    swiper.swiper.on("slideChange", function () {
      let index = swiper.swiper.realIndex;

      $(component).find(`.glass-slider__slider-desc-text[data-slide='${index}']`).toggleClass("glass-slider__slider-desc-text--hide", false);
      $(component).find(`.glass-slider__slider-desc-text:not([data-slide='${index}'])`).toggleClass("glass-slider__slider-desc-text--hide", true);
    });

    $(component).find(`.glass-slider__slider-desc-text[data-slide='0']`).toggleClass("glass-slider__slider-desc-text--hide", false);
    $(component).find(`.glass-slider__slider-desc-text:not([data-slide='0'])`).toggleClass("glass-slider__slider-desc-text--hide", true);

    return swiper;
  };

  const setup = () => {
    const $glassSlider = $(".glass-slider");

    if (!$glassSlider.length) return;

    $glassSlider.each((i, component) => {
      setupSwiper(component);
    })

  };

  return { init: setup };
})();

export default glassSlider;
