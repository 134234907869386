const bsSelect = (function () {
  var $selectmenu;

  function setup() {
    $selectmenu = $('.js-bs-select');

    $selectmenu.each(function () {
      var $this = $(this);

      $this.selectpicker();
    });
  }

  return {
    init: setup,
  };
})();

export default bsSelect;
