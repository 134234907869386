import { isScrolledIntoView } from './commonFn';

var numberAnimate = (function () {
  function setup($elem = null) {
    if (!$elem) $elem = $('.js-up-num');

    if (!$elem.length) return;

    $(window).on('scroll.numbering load touchmove.numbering', function () {
      $elem.not('.js-num-init').each(function () {
        var $num = $(this);
        if (isScrolledIntoView($num)) {
          animateNum($num);
        }
      });
    });
  }

  function animateNum($elem = null) {
    var comma_separator = $.animateNumber.numberStepFactories.separator(','),
      speed = 900,
      decimal_places = 1,
      decimal_factor = decimal_places === 0 ? 1 : Math.pow(10, decimal_places);

    if (!$elem) $elem = $('.js-up-num');

    $elem.each(function () {
      var $this = $(this);
      speed = $this.data('speed') || speed;

      $this.addClass('js-num-init');

      if (
        ($this.closest('.panel__content').length && !$this.closest('.panel__content').hasClass('show')) ||
        ($this.closest('.js-swiper-item').length && !$this.closest('.js-swiper-item').hasClass('swiper-slide-visible'))
      ) {
        // console.log($this);
        return;
      }

      if (!$this.hasClass('js-num-animated')) {
        var plus = $this.data('plus') || 0;
        var endVal = $this.data('end');

        $this.animateNumber(
          {
            number: (endVal - plus) * decimal_factor,
            numberStep: function (now, tween) {
              var floored_number = Math.floor(now) / decimal_factor,
                target = $(tween.elem);

              if (decimal_places > 0) {
                floored_number = floored_number.toFixed(decimal_places);

                floored_number = floored_number
                  .toString()
                  .replace('.0', '')
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
              }

              target.text(floored_number);
            },
          },
          speed,
          function () {
            // $this.addClass('js-num-animated');

            if (plus) {
              $this.animateNumber(
                {
                  number: endVal * decimal_factor,
                  numberStep: function (now, tween) {
                    var floored_number = Math.floor(now) / decimal_factor,
                      target = $(tween.elem);

                    if (decimal_places > 0) {
                      floored_number = floored_number.toFixed(decimal_places);

                      floored_number = floored_number
                        .toString()
                        .replace('.0', '')
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                    }

                    target.text(floored_number);
                  },
                },
                400
              );
            }
          }
        );
        return;
      } else return;
    });
  }

  return {
    init: setup,
    update: animateNum,
  };
})();

export default numberAnimate;
