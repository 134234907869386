import numberAnimate from '@shared/js/scripts/numberAnimate';

const figureAccordion = (function () {
  function setup() {
    const $collapseItemContent = $('.accordion-2bg .panel__content');
    numberAnimate.init($collapseItemContent.find('.js-up-num'));

    // reset and restart number animation when collapse/open
    $collapseItemContent.on('shown.bs.collapse', function () {
      numberAnimate.update($(this).find('.js-up-num'));
    });
    $collapseItemContent.on('hidden.bs.collapse', function () {
      $(this).find('.js-num-init').html('0');
    });
  }

  return { init: setup };
})();

export default figureAccordion;