var jsMasonry = (function () {
  // var $target;
  function setup($_container) {
    var $_c = $_container ? $_container : $('body');
    var $target = $_c.find('.js-masonry');

    if ($target.length) {
      var $masonry = $target.masonry({
        // options
        itemSelector: '.js-masonry-item',
        horizontalOrder: true,
        // stamp: '.js-masonry-stamp'
      });

      $target[0].masonry = $masonry;
    }
  }

  function updateLayout(horizontalOrder) {
    $('.js-masonry')[0].masonry.masonry({ horizontalOrder: horizontalOrder });
  }

  return {
    init: setup,
    update: updateLayout,
  };
})();

export default jsMasonry;