import responsiveTable from './responsiveTable';
import responsiveVideo from './responsiveVideo';
import { langArg } from './lang';

const collapse = (function () {
  function setup() {
    // grouping();
    addAttr();
    toggleAll();
    autoOpenByAnchor();
  }

  // each controller block (with 'expand all' button) controls the consecutive, non-controller blocks immediately below it
  // this function identifies the start (top) and end of groups, and stores the control information in controller blocks
  function grouping() {
    var $control, controlCount;
    var $collapseBlk = $('.collapse-blk');

    $collapseBlk.each(function (idx) {
      var $blk = $(this);

      if (
        !$blk.prev().hasClass('collapse-blk') ||
        $blk.hasClass('collapse-blk--controller') ||
        $blk.prev().attr('data-type') !== $blk.attr('data-type')
      ) {
        $blk.addClass('collapse-blk--top');
      }

      if (
        !$blk.next().hasClass('collapse-blk') ||
        $blk.next().hasClass('collapse-blk--controller') ||
        ($blk.attr('data-type') !== '4' && $blk.next().attr('data-type') == '4')
      ) {
        $blk.addClass('collapse-blk--end');

        if ($blk.next().hasClass('trailing-text')) {
          $blk.addClass('has-trailing-text');
        }
      }

      if ($blk.hasClass('collapse-blk--top')) {
        // a new top-blk means the end of the previous group, save the count of items controlled by the previous controller element
        if ($control && controlCount !== undefined) {
          $control.data('control-next', controlCount);
        }

        // set/reset controller element and items controlled count
        $control = $blk.hasClass('collapse-blk--controller') ? $blk : undefined;
        controlCount = $blk.hasClass('collapse-blk--controller')
          ? 0
          : undefined;
      } else if (controlCount !== undefined) {
        controlCount++;

        // last collapse blk reached, save the count of items controlled by the last controller element
        if ((idx = $collapseBlk.length - 1)) {
          if ($control && controlCount !== undefined) {
            $control.data('control-next', controlCount + 1);
          }
        }
      }
    });
  }

  function addAttr() {
    var $collapseItem = $('.js-collapse-item');

    $collapseItem.each(function (i) {
      var $this = $(this),
        $trigger = $this.find('.js-panel-trigger'),
        $collapseItemHeading = $this.find('.panel__heading:first'),
        $collapseItemContent = $this.find('.panel__content:first');

      var collapseId = $collapseItemContent.attr('id');
      if (!collapseId) {
        collapseId = 'collapse-' + i;
      }

      $trigger.attr({
        href: '#' + collapseId,
        'aria-controls': collapseId,
      });

      $collapseItemHeading.attr('id', collapseId + '-heading');

      $collapseItemContent.attr({
        id: collapseId,
        // 'aria-labelledby': collapseId + '-heading',
      });
    });
  }

  function toggleAll() {
    var $toggleAllBtn = $('.js-btn-toggle-all');
    var $btnTxtExpand = langArg({
      en: 'Expand All',
      tc: '顯示全部',
      sc: '显示全部',
    });
    var $btnTxtCollapse = langArg({
      en: 'Collapse All',
      tc: '關閉全部',
      sc: '关闭全部',
    });

    $toggleAllBtn.each(function () {
      var $this = $(this),
        // $blk = $this.closest('.collapse-blk--controller'),
        $panelContent = $this.closest('.panel-parent').find('.panel__content'),
        $btnText = $this.find('.btn__text');

      if ($panelContent.filter('.show').length === $panelContent.length) {
        $this.addClass('is-expand-all');
        $btnText.html($btnTxtCollapse);
      }

      $this.on('click', function () {
        if ($this.hasClass('is-expand-all')) {
          $this.removeClass('is-expand-all');
          $panelContent.collapse('hide');
          $btnText.html($btnTxtExpand);
        } else {
          $this.addClass('is-expand-all');
          $panelContent.collapse('show');
          $btnText.html($btnTxtCollapse);
        }
      });

      // if all controlled items are shown, set button action to 'collapse all'
      $panelContent.on('shown.bs.collapse', function () {
        if ($panelContent.filter('.show').length === $panelContent.length) {
          $this.addClass('is-expand-all');
          $btnText.html($btnTxtCollapse);
        }
      });

      // if any controlled items is hidden, set button action to 'expand all'
      $panelContent.on('hidden.bs.collapse', function () {
        $this.removeClass('is-expand-all');
        $btnText.html($btnTxtExpand);
        // if (!$panelContent.filter('.show').length) {
        // }
      });

      //Retrigger responsiveTable inside panel
      $panelContent.on('show.bs.collapse', function () {
        if ($(this).find('table').length) {
          responsiveTable.reinit();
        }
      });
    });
  }

  function autoOpenByAnchor() {
    var hash = encodeURI(location.hash),
      hashtag = hash.substring(1),
      $linkWithAnchor = $(
        'a[href*="#"]:not([href$="#"]):not([data-toggle="collapse"])'
      ),
      $targetElem;

    // Anchor in current page
    $linkWithAnchor.on('click', function () {
      var href = $(this).attr('href'),
        hrefHashtag = encodeURI(href.substring(1)),
        $goToElem;

      if (
        $(
          'a[data-toggle="collapse"][id="' +
            $.escapeSelector(hrefHashtag) +
            '"]'
        ).length
      ) {
        $goToElem = $(
          'a[data-toggle="collapse"][id="' +
            $.escapeSelector(hrefHashtag) +
            '"]'
        );
      }

      if (
        $('a[data-toggle="collapse"][href*="' + $.escapeSelector(href) + '"]')
          .length
      ) {
        $goToElem = $(
          'a[data-toggle="collapse"][href*="' + $.escapeSelector(href) + '"]'
        );
      }

      if (!$goToElem) {
        return;
      }

      if ($goToElem.length) {
        var goToElemOffsetTop =
          $goToElem.offset().top - $('.page-head').outerHeight();
        console.log(goToElemOffsetTop);
        $('html, body').animate(
          {
            scrollTop: goToElemOffsetTop,
          },
          500,
          function () {
            if ($goToElem.attr('aria-expanded') === 'false') {
              $(href).collapse('show');
            }
          }
        );
      }
    });

    // Anchor in new page
    // 1. Add custom anchor in CKE (Static)
    if (
      $('a[data-toggle="collapse"][id="' + $.escapeSelector(hashtag) + '"]')
        .length
    ) {
      $targetElem = $(
        'a[data-toggle="collapse"][id="' + $.escapeSelector(hashtag) + '"]'
      );
    }

    // 2. Default anchor with pattern #collapse-* (Dynamic)
    if (
      $('a[data-toggle="collapse"][href*="' + $.escapeSelector(hash) + '"]')
        .length
    ) {
      $targetElem = $(
        'a[data-toggle="collapse"][href*="' + $.escapeSelector(hash) + '"]'
      );
    }

    if (
      $('div[data-toggle="collapse"][href*="' + $.escapeSelector(hash) + '"]')
        .length
    ) {
      $targetElem = $(
        'div[data-toggle="collapse"][href*="' + $.escapeSelector(hash) + '"]'
      );
    }

    if (!$targetElem) {
      return;
    }

    if (hash && $targetElem.length) {
      var triggerElemOffsetTop = $targetElem.offset().top;
      console.log(triggerElemOffsetTop);

      $('html, body').animate(
        {
          scrollTop: triggerElemOffsetTop,
        },
        500,
        function () {
          if ($targetElem.attr('aria-expanded') === 'false') {
            $(hash).collapse('show');
          }
        }
      );
    }
  }

  return {
    init: setup,
  };
})();

export default collapse;
