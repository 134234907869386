const popupMessage = (function () {
  function setup() {
    const $targetNotice = $('.modal-notice');
    if ($targetNotice.length) {
      const isViewedNotice = localStorage.getItem('isViewedNotice');
      const prevNoticeDatetime = localStorage.getItem('prevNoticeDatetime');
      const currentNoticeDatetime = $targetNotice.data('datetime');
      const forceShow = $targetNotice.data('force-show');

      // If notice was not closed, or there is a more recent notice, or force show is enabled
      if (isViewedNotice !== 'true' || new Date(currentNoticeDatetime) > new Date(prevNoticeDatetime) || forceShow) {
        $targetNotice.find('img').on('load', () => {
          $targetNotice.modal('show');
        // Start loading the image by removing loading="lazy"
        }).removeAttr('loading');

        localStorage.setItem('isViewedNotice', 'false');
        localStorage.setItem('prevNoticeDatetime', currentNoticeDatetime);

        // Closing the notice = The visitor has noted
        $targetNotice.find('.js-viewed-notice').on('click', function (e) {
          e.preventDefault();
          localStorage.setItem('isViewedNotice', 'true');
        });
      }
    }
  }

  return {
    init: setup,
  };
})();

export default popupMessage;
