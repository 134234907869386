const orgChart = (function() {
  function setup() {
    document.querySelectorAll('.org-chart').forEach(function(chart, chartIdx) {
      const expandAllToggle = chart.querySelector('.org-chart__expand-all-btn');
      
      const collapses = Array.from(chart.querySelectorAll('.collapse')).reduce((validCollapses, collapse) => {
        const toggle = collapse.previousElementSibling.querySelector('.org-chart__toggle');
        if (toggle) {
          collapse._toggle = toggle;
          validCollapses.push(collapse);
        } else {
          collapse.classList.remove('collapse');
        }
        return validCollapses;
      }, []);

      let collapseIdCounter = 0;
      let collapseId = '';
      let collapsedCounter = collapses.length;

      collapses.forEach(function(collapse) {
        const toggle = collapse._toggle;
        collapseId = `org-chart-collapse-${chartIdx}-${collapseIdCounter++}`;
        collapse.setAttribute('id', collapseId);
        toggle.setAttribute('aria-controls', collapseId);
        toggle.setAttribute('data-target', `#${collapseId}`);
        toggle.setAttribute('data-toggle', 'collapse');

        const $collapse = $(collapse);
        $collapse.collapse({ toggle: false });

        if (expandAllToggle) {
          $collapse.on('shown.bs.collapse', function(e) {
            collapsedCounter--;
            if (!collapsedCounter) {
              expandAllToggle.classList.add('is-expand-all');
            }
            e.stopPropagation();
          }).on('hidden.bs.collapse', function(e) {
            collapsedCounter++;
            expandAllToggle.classList.remove('is-expand-all');
            e.stopPropagation();
          });
        }
      });

      if (expandAllToggle) {
        expandAllToggle.addEventListener('click', (e) => {
          if (e.currentTarget.classList.contains('is-expand-all')) {
            collapses.forEach(collapse => $(collapse).collapse('hide'));
            e.currentTarget.classList.remove('is-expand-all');
          } else {
            collapses.forEach(collapse => $(collapse).collapse('show'));
            e.currentTarget.classList.add('is-expand-all');
          }
        });
      }
    });
  }

  return { init: setup };
})();

export default orgChart;