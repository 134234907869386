import jsMasonry from "@shared/js/scripts/jsMasonry";

var jsShowHideList = (function() {
  function setup() {
    $(document).on('click', '.js-show-more', function(e) {
      var $b = $(this);
      var $p = $b.closest('.js-show-section');
      var $c = $b.parent();
      var $target = $p.find('.hidden-item');

      if($p.hasClass('js-masonry-section')) {
        $target.css('opacity', 0);
      }
      $target.fadeIn(500, function() {
        if($p.hasClass('js-masonry-section')) {
          jsMasonry.update($p.hasClass('js-masonry-section--hori'));
          $target.animate({'opacity': 1}, 800);
        }
        $c.fadeOut();
      });
    });
  }

  return {
    init: setup
  };
}());
export default jsShowHideList;