/*
- Move all bootstrap modal popup to body level, prevent z-index issue
- if the modal popup has '.js-bs-hash' class and "data-bs-hash" attr, the url hash will change
- if url contains hash, and match modal popup "data-bs-hash" attr, the popup will be opened automatically on page load.
- if the modal with class name "js-modal-auto-open" exists, the popup will be opened automatically on page load.
 */

const bsModal = (function () {
  var hash = encodeURI(window.location.hash);

  function setup() {
    $('.modal').appendTo($('body'));

    $('.modal').on('show.bs.modal', function () {
      var $modal = $(this);
      if ($modal.hasClass('js-bs-hash') && $modal.data('bs-hash')) {
        window.location.hash = $modal.data('bs-hash');
      }
    });

    if (hash) {
      var hashData = hash.replace('#', '').trim();

      if (hashData !== '' && hashData !== '!') {
        var $target = $('.modal').filter('[data-bs-hash=' + $.escapeSelector(hashData) + ']');
        $target.modal('show');
      }
    }

    if ($('.js-modal-auto-open').length) {
      $('.js-modal-auto-open').modal('show');
    }

    $('.modal-sidemenu-slide')
      .on('show.bs.modal', function () {
        $('body').addClass('js-modal-sidemenu-slide');
      })
      .on('hidden.bs.modal', function () {
        $('body').removeClass('js-modal-sidemenu-slide');
      });

    $('.modal-fix-side-padding')
      .on('show.bs.modal', function () {
        $('body').addClass('js-modal-fix-side-padding');
      })
      .on('hidden.bs.modal', function () {
        $('body').removeClass('js-modal-fix-side-padding');
      });

    $('.modal-fix-scroll')
      .on('show.bs.modal', function () {
        $('body').addClass('js-modal-fix-scroll');
      })
      .on('hidden.bs.modal', function () {
        $('body').removeClass('js-modal-fix-scroll');
      });

    $('.modal-over-fancybox')
      .on('show.bs.modal', function () {
        $('body').addClass('js-modal-over-fancybox');
      })
      .on('hidden.bs.modal', function () {
        $('body').removeClass('js-modal-over-fancybox');
      });
  }

  return {
    init: setup,
  };
})();

export default bsModal;
