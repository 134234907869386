"use strict";

// use in poly u mainsite
var jsNav = function ($) {
  var $nav,
      $li,
      $ul,
      $a,
      $plate,
      t_desk_in,
      t_desk_out,
      t_desk_2_in,
      final__trigger = false,
      timeOut = 150,
      $body,
      $page__head,
      moving_direction = null,
      scroll_trigger = 30
      ;

  function setup() {
    $nav = $('.mn__nav');
    $li = $nav.find('li[class*="__item--"]');
    $a = $nav.find('a[class*="__link--"]');
    $ul = $nav.find('ul[class*="__list--"]');
    $plate = $nav.find('[class*="__plate"]');
    $body = $('body');
    $page__head = $('.page-head');

    if (!Modernizr.touchevents) {
      $li.on('mouseenter', function () {
        final__trigger = true;
        var $li = $(this);

        if ($li.find('li')) {
          timeoutActive($li);
        }
      });
      $li.on('mouseleave', function () {
        final__trigger = false;
        var $li = $(this);

        if ($li.find('li[class*="__list--"]')) {
          timeoutRemove($li);
        }
      });
      $plate.on('mouseenter', function () {
        var $p = $(this);

        if (t_desk_out) {
          clearTimeout(t_desk_out);
        }
      });
    }

    $a.on('focus', function () {
      $li.removeClass('active');
      $(this).parentsUntil($nav).addClass('active');
      $body.addClass('mn--opened');
      $page__head.addClass('header--opened');
    });
    $a.on('blur', function () {
      $(this).parentsUntil($nav).removeClass('active');
      $body.removeClass('mn--opened');
      $page__head.removeClass('header--opened');
      setSubHeight($(this).closest('li'));
    });
    $a.add($ul.not('[class*="__list--l"]')).on('touchstart', function (e) {
      e.stopPropagation();
    });
    $a.on('focus mouseover', function () {
      $li.removeClass('hover-active');
      $(this).parent().addClass('hover-active');
    });
    $a.on('blur mouseleave', function () {
      $(this).parent().removeClass('hover-active');
    });
    $a.on('touchstart', function (e) {
      var $l = $(this),
          lv = getLv($l);

      if ($l.find('ul[class*="__item--]')) {
        e.stopPropagation();

        if ($l.data('clicked')) {
          return;
        }

        e.preventDefault();
        $li.filter("[class*=\"__item--".concat(lv, "\"]")).removeClass('active');
        $a.filter("[class*=\"__link--".concat(lv, "\"]")).removeData('clicked');
        $l.data('clicked', true);
        $l.parent().addClass('active');
        $body.addClass('mn--opened');
        $page__head.addClass('header--opened');
      }
    });
    $(document).on('touchstart', function (e) {
      console.log(e);
      $li.removeClass('active');
      $a.removeData('clicked');
      $body.removeClass('mn--opened');
      if(!$(e.target).closest('.page-head').length) {
        $page__head.removeClass('header--opened');
      }
    });
    $plate.on('touchstart', function (e) {
      e.stopPropagation();
      e.preventDefault();
    });
    $page__head.on('mouseenter', function () {
      $page__head.addClass('header--opened');
    });
    $page__head.on('mouseleave', function () {
      $page__head.removeClass('header--opened');
    });
    setResizeFn();
    lv2_pos();
    setNavScroll();
  }

  function timeoutActive($_li) {
    if (t_desk_in) {
      clearTimeout(t_desk_in);
    }

    t_desk_in = setTimeout(function () {
      $_li.removeClass('inactive').addClass('active ');
      $_li.siblings('.active').removeClass('active ');
      $_li.add($_li.siblings()).find('.active').removeClass('active');
      setSubHeight($_li);
      $body.addClass('mn--opened');
      $page__head.addClass('header--opened');
      $_li.attr('data-menu-active', true);
    }, timeOut);
  }

  function timeoutRemove($_li) {
    if (t_desk_in) {
      clearTimeout(t_desk_in);
    }

    if (t_desk_out) {
      clearTimeout(t_desk_out);
      t_desk_out = null;
    }

    t_desk_out = setTimeout(function () {
      if ($_li.hasClass('active') && $_li.hasClass('to-sub')) {
        $_li.addClass('active-leaving');
      }

      if (final__trigger == false) {
        $_li.closest('ul').find('.active').removeClass('active');
      }

      $_li.removeClass('active').off(cssTransEnd).one(cssTransEnd, function () {
        $_li.removeClass('active-leaving');
      });
      $li.filter('[class*="__item--1"]').removeClass('inactive');
      $body.removeClass('mn--opened'); // $page__head.removeClass('header--opened');
    }, timeOut);
  }

  function setSubHeight($il__current) {
    var $il = $il__current,
        $sub = $il.closest('.mn-drop__height-control'),
        $parent = $il.closest('ul'),
        suitHeight = null;

    if ($il.find('> ul').length) {
      var $child = $il.find('> ul');
      suitHeight = Math.max($child.height(), $parent.height());
    } else {
      suitHeight = $parent.height();
    }

    $sub.css('height', suitHeight);
  }

  function setResizeFn() {
    var t = null;
    $(window).on('resize', function () {
      if (t) {
        clearTimeout(t);
        t = null;
      }

      t = setTimeout(function () {
        lv2_pos();
      }, 80);
    });
  }

  function lv2_pos() {
    if (!$plate.length) {
      return;
    }

    $plate.each(function () {
      if ($nav.hasClass('center-plate')) {
        var $p = $(this),
            $li = $p.closest('li'),
            pos = -$p.outerWidth() / 2,
            outbound = $li.offset().left + $li.outerWidth() / 2 + $p.outerWidth() / 2 - $(window).width();

        if (outbound > 0) {
          pos = pos - outbound;
        }

        $p.find('.mn__plate-inner').css({
          'margin-left': pos,
          'margin-right': -pos
        });
      }
    });
  }

  function getLv(__$el) {
    return __$el.attr('class').match(/__(link|list|item)--(\d)/)[2];
  }

  function setNavScroll() {
    const $window = $(window);
    $window
      .on('scroll resize', function () {
        if ($window.scrollTop() === 0 && $page__head.hasClass('header--scrolled')) {
          // Only remove the class if the scroll position is exactly 0
          $page__head.removeClass('header--scrolled');
        } else if ($window.scrollTop() > scroll_trigger && !$page__head.hasClass('header--scrolled')) {
          $page__head.addClass('header--scrolled');
        } // else do nothing, in order to avoid scroll position oscillation
      })
      .trigger('scroll');

    const mainContent = document.getElementsByClassName('main-content')[0];
    if (mainContent) {
      const adjustMainContentMarginTop = () => {
        mainContent.style.marginTop = $page__head[0].offsetHeight + 1 + 'px';
      }

      // Initial call
      adjustMainContentMarginTop();
      
      // Dynamically change the margin-top of main-content, as page-head height changes
      new ResizeObserver(adjustMainContentMarginTop).observe($page__head[0]);
    }
  }

  return {
    init: setup
  };
}(jQuery);

export default jsNav;
