import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import QRCode from "qrcode";
import { langArg } from "@shared/js/scripts/lang";

const headerJS = (function() {

  const lang = document.documentElement.lang;

  function getTxtByLang(arrayTxt) {
    var currentLang = 0;
    switch (lang) {
      case 'zh-hk':
        currentLang = 1;
        break;
      case 'zh-cn':
        currentLang = 2;
        break;
      default:
        currentLang = 0;
    }
    return arrayTxt[currentLang];
  }

  function setupActiveMenuLv1 (header) {
    if (!header) return;

    $(header).find(".menu__link--lv1").each((i, e) => {
      if (window.location.href.includes($(e).attr("href")) && $(e).attr("href") != "#"  && $(e).attr("href") != "#!") {
        $(e).closest(".menu__item--lv1").toggleClass("is-selected", true);
      }
    })
  }

  function setupLangHref (header) {
    const tc = header.querySelector(".lang__item[lang='zh-HK']");
    const sc = header.querySelector(".lang__item[lang='zh-CN']");
    const en = header.querySelector(".lang__item[lang='en']");

    ["tc", "sc", "en"].forEach(lang => {
      let hrefArray = window.location.pathname.split("/").splice(2).reverse();
      hrefArray.push(lang);
      hrefArray.push("");
      
      let newPath = hrefArray.reverse().join("/");

      if (lang == "tc" && tc) tc.href = newPath;
      if (lang == "sc" && sc) sc.href = newPath;
      if (lang == "en" && en) en.href = newPath;
    })
  }

  function initShareBtns() {
    // var $headerShare = $('.header-nav__tool--share');
    // var $headerShareTrigger = $headerShare.find('.header-nav__tool--share-trigger');
    // $headerShareTrigger.click(function (event) {
    //     event.preventDefault();
    //     closeAllSubMenu();
    //     $headerShare.addClass('is-active');
    // });
    // $(document).on('touchstart.headerShare click.headerShare', function (e) {
    //     if (!$headerShare.is(e.target) && $headerShare.has(e.target).length === 0) {
    //         $headerShare.removeClass('is-active');
    //     }
    // });
    // var $holder = $('.share-btns');
    // var $btn = $holder.find('.share-btns__btn').not('.share-btns__btn--rss');
    var $btn = $('.js-social-list').find('.social__link').not('.social__link--rss');
    var $windowCount = 0;
    //init QR
    QRCode.toDataURL(document.querySelector('#wc-qrcode canvas'), window.location.href, {
      width: 256,
      margin: 0,
    }, function (err, url) {
      if (err) console.error(err); 
      else {
        var qrCodeImage = new Image();
        qrCodeImage.src = url;
        qrCodeImage.alt = langArg({
          en: 'QR Code for sharing',
          tc: '分享用二維碼',
          sc: '分享用二维码'
        });
        document.querySelector('#wc-qrcode').appendChild(qrCodeImage);
      }
    });

    var mobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    $btn.on('click', 
      function (event) {
        event.preventDefault();
        var $thisBtn = $(this);
        var pageShareTitle = encodeURIComponent(document.getElementsByTagName("title")[0].innerHTML);
        var pageShareContent = window.location.href;
        var href = "";
        var type = 'default';
        var pageUrl = encodeURIComponent(window.location.href);
        //var pageUrl = encodeURIComponent('https://www.ogcio.gov.hk' + window.location.pathname);
        if ($thisBtn.data('media') == 'facebook') {
            href = "https://www.facebook.com/sharer/sharer.php?u=" + pageUrl;
        } else if ($thisBtn.data('media') == 'email') {
            href = "mailto:?subject=" + pageShareTitle + "&body=" +pageShareTitle+'%0A'+ pageUrl;
        } else if ($thisBtn.data('media') == 'whatsapp') {
            if(mobileDevice){
                href = "whatsapp://send?text=" + pageShareTitle + ": " + pageUrl;
            }else{
              href = "https://web.whatsapp.com/send?text=" + pageShareTitle + ": " + pageUrl;
            }
        } else if ($thisBtn.data('media') == 'twitter') {
            href = "http://twitter.com/share?text=" + pageShareTitle + ": " + "&url=" + pageUrl;
        } else if ($thisBtn.data('media') == 'weibo') {
            href = "http://service.weibo.com/share/share.php?url=" + pageUrl + "&title=" + pageShareTitle;
        } else if ($thisBtn.data('media') == 'wechat') {
            href = window.location.href;
            type = 'wechat';
        }
    
        PopupCenter(href, "shareWindow_" + $windowCount, 600, 500, type);
        $windowCount++;
      }
    );
  }

  function PopupCenter(url, title, w, h, type) {
    if (type == 'wechat') {
        var html = $('.wc-qrcode').html();
        console.log(html);
		var wechatTitle = getTxtByLang(['WeChat Share', '微信分享', '微信分享']);
        var newWindow = window.open(url, '_blank', 'scrollbars=yes, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left); //　
        newWindow.document.write('<!DOCTYPE html><html><head><title>' + wechatTitle + '</title></head><body></body></html>');
        newWindow.document.write(html);
    } else {
        // Fixes dual-screen position                         Most browsers      Firefox
        var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : screen.left;
        var dualScreenTop = window.screenTop != undefined ? window.screenTop : screen.top;

        var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
        var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

        var left = ((width / 2) - (w / 2)) + dualScreenLeft;
        var top = ((height / 2) - (h / 2)) + dualScreenTop;
        var newWindow = window.open(url, title, 'scrollbars=yes, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);

        // Puts focus on the newWindow
        if (window.focus) {
            newWindow.focus();
        }
    }
  }

  function preserveHeader() {
    if (document.querySelector('main#main.page-content--preserve-header')) {
      document.documentElement.classList.add('preserve-header');
    }
  }

  function setup() {
    const header = document.querySelector('.page-head');
    const anchor = document.getElementById('header-scroll-anchor');
    if (!header || !anchor) return;

    // track position of the anchor element to toggle header styles
    const scrollTracker = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        header.classList.toggle('page-head--scrolled', !entry.isIntersecting);
        document.documentElement.classList.toggle('header-scrolled', !entry.isIntersecting);
      });
    });
    scrollTracker.observe(anchor);

    // track scroll direction for showing/hiding header
    gsap.registerPlugin(ScrollTrigger);

    ScrollTrigger.create({
      trigger: "body",
      start: () => "top top",
      end: () => `+=${document.body.offsetHeight * 2}`,
      onUpdate: ({ direction }) => {
        header.style.setProperty('--scroll-direction', direction);
      }
    });

    // dynamically set header height variable for styling
    let headerHeight = 0;

    const heightTracker = new ResizeObserver(entries => {
      entries.forEach(entry => {
        if (entry.target.clientHeight !== headerHeight) {
          document.documentElement.style.setProperty('--header-height', `${entry.target.clientHeight}px`);
          headerHeight = entry.target.clientHeight;
        }
      });
    });
    heightTracker.observe(header);

    setupActiveMenuLv1(header);
    setupLangHref(header);
    initShareBtns();
    preserveHeader();
  }

  return {init: setup};
})();

export default headerJS;