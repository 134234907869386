/* Check key code */
// Tab
export function ckSTab(e) {
  return e.which == 9 && e.shiftKey;
}

// Shift-tab
export function ckTab(e) {
  return e.which == 9 && !e.shiftKey;
}

// Esc
export function ckEsc(e) {
  return e.which == 27 && !e.shiftKey;
}

// https://www.sitepoint.com/get-url-parameters-with-javascript/
export function getUrlParams(url) {
  // get query string from url (optional) or window
  var params = url ? url.split('?')[1] : window.location.search.slice(1);
  var queryString = decodeURIComponent(params);

  // we'll store the parameters here
  var obj = {};

  // if query string exists
  if (queryString) {
    // stuff after # is not part of query string, so get rid of it
    queryString = queryString.split('#')[0];

    // split our query string into its component parts
    var arr = queryString.split('&');

    for (var i = 0; i < arr.length; i++) {
      // separate the keys and the values
      var a = arr[i].split('=');

      // set parameter name and value (use 'true' if empty)
      var paramName = a[0];
      var paramValue = typeof a[1] === 'undefined' ? true : a[1];

      // (optional) keep case consistent
      // paramName = paramName.toLowerCase();
      // if (typeof paramValue === 'string') paramValue = paramValue.toLowerCase();

      // if the paramName ends with square brackets, e.g. colors[] or colors[2]
      if (paramName.match(/\[(\d+)?\]$/)) {
        // create key if it doesn't exist
        var key = paramName.replace(/\[(\d+)?\]/, '');
        if (!obj[key]) obj[key] = [];

        // if it's an indexed array e.g. colors[2]
        if (paramName.match(/\[\d+\]$/)) {
          // get the index value and add the entry at the appropriate position
          var index = /\[(\d+)\]/.exec(paramName)[1];
          obj[key][index] = paramValue;
        } else {
          // otherwise add the value to the end of the array
          obj[key].push(paramValue);
        }
      } else {
        // we're dealing with a string
        if (!obj[paramName]) {
          // if it doesn't exist, create property
          obj[paramName] = paramValue;
        } else if (obj[paramName] && typeof obj[paramName] === 'string') {
          // if property does exist and it's a string, convert it to an array
          obj[paramName] = [obj[paramName]];
          obj[paramName].push(paramValue);
        } else {
          // otherwise add the property
          obj[paramName].push(paramValue);
        }
      }
    }
  }

  return obj;
}

export function getHash(target) {
  var hash = window.location.hash.substring(1);
  if (!target || target.test(hash)) {
    window.location.hash = '';
    if (typeof history.replaceState != 'undefined') history.replaceState('', document.title, window.location.pathname);
    return hash;
  }
  return false;
}

// http://stackoverflow.com/questions/29563822/new-recaptcha-with-jquery-validation-plugin
export function recaptchaCallback() {
  $('#hiddenRecaptcha').valid();
}

// Fix recaptcha accessibility issue
export function onloadRecaptchaCallback() {
  $('#g-recaptcha-response').attr({
    title: langArg({ en: 'Recaptcha', tc: '驗證碼', sc: '验证码' }),
    'aria-hidden': 'true',
  });
}

export function youtuParser(url) {
  var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  var match = url.match(regExp);
  return match && match[7].length == 11 ? match[7] : false;
}

export function convertYoutubeEmbedLink(url) {
  const shortUrlRegex = /youtu.be\/([a-zA-Z0-9_-]+)\??/i;
  const longUrlRegex = /youtube.com\/((?:embed)|(?:watch))((?:\?v\=)|(?:\/))(\w+)/i;
  let match, id;
  let params = '';
  
  if (url.includes('youtu.be')) {
    match = url.match(shortUrlRegex);
    if (match && match[1].length === 11) {
      id = match[1];
    }
  } else if (url.includes('youtube.com')) {
    match = url.match(longUrlRegex);
    if (match && match[3].length === 11) {
      id = match[3];
    }
  }

  if (id) {
    let urlObj = new URL(url);
    params = new URLSearchParams(urlObj.search);
    params.delete('v');
    params = params.size ? '?' + params.toString() : '';
  }

  return id ? `https://www.youtube.com/embed/${id}${params}` : url;
}

export function isRetinaDisplay() {
  if (window.matchMedia) {
    var mq = window.matchMedia(
      'only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen  and (min-device-pixel-ratio: 1.3), only screen and (min-resolution: 1.3dppx)'
    );
    return (mq && mq.matches) || window.devicePixelRatio > 1;
  }
}

// https://sberry.me/articles/javascript-event-throttling-amp-debouncing
export function throttle(fn, delay) {
  var allowSample = true;

  return function (e) {
    if (allowSample) {
      allowSample = false;
      setTimeout(function () {
        allowSample = true;
      }, delay);
      fn(e);
    }
  };
}

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/eval
export function looseParse(vari) {
  return Function('"use strict";return (' + vari + ')')();
}

jQuery.fn.outerHTML = function () {
  return jQuery('<div />').append(this.eq(0).clone()).html();
};

jQuery.fn.nextOrFirst = function (selector) {
  var next = this.next(selector);
  return next.length ? next : this.prevAll(selector).last();
};

jQuery.fn.prevOrLast = function (selector) {
  var prev = this.prev(selector);
  return prev.length ? prev : this.nextAll(selector).last();
};

jQuery.fn.reverse = [].reverse;

jQuery.fn.shuffle = function () {
  var j;
  for (var i = 0; i < this.length; i++) {
    j = Math.floor(Math.random() * this.length);
    $(this[i]).before($(this[j]));
  }
  return this;
};

export function randomString(length) {
  var stringLength = length ? length : 7;
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < stringLength; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

/**
 * element.querySelector
 * ```js
 * const element = qs(parent, 'div.class');
 * ```
 * @param {HTMLElement} element - parent element to search
 * @param {String} selector - selector of desired child element
 * @return {HTMLElement} DOM element
 */
export function qs(element, selector) {
  return element.querySelector(selector);
}

/**
 * element.querySelectorAll
 * ```js
 * const elements = qsa(parent, 'div.class');
 * ```
 * @param {HTMLElement} element - parent element to search
 * @param {String} selector - selector of desired child element
 * @return {NodeList} DOM elements
 */
export function qsa(element, selector) {
  return element.querySelectorAll(selector);
}

/**
 * document.querySelector
 * ```js
 * const element = dq('header.class1');
 * ```
 * @param {String} selector - selector of desired element in document
 * @return {HTMLElement} DOM element
 */
export function dq(selector) {
  return qs(document, selector);
}

/**
 * document.querySelectorAll
 * ```js
 * const elements = dqa('header.class1');
 * ```
 * @param {String} selector - selector of desired element in document
 * @return {NodeList} DOM elements
 */
export function dqa(selector) {
  return qsa(document, selector);
}

/**
 * Add class(es) to element(s)
 * ```js
 * addClass(element, 'abc');
 * addClass(listOfElements, ['abc', 'def]);
 * ```
 * @param {HTMLElement|NodeList} element - DOM element or list of elements
 * @param {String|Array} className - CSS class name or an array of class name
 */
export function addClass(element, className) {
  if (element && element.length) {
    for (let i = 0, l = element.length; i < l; i++) {
      addClass(element[i], className);
    }
  } else {
    if (Array.isArray(className)) {
      for (let i = 0, l = className.length; i < l; i++) {
        element.classList.add(className[i]);
      }
    } else {
      element.classList.add(className);
    }
  }
}

/**
 * Remove class(es) from element(s)
 * ```js
 * removeClass(element, 'abc');
 * removeClass(listOfElements, ['abc', 'def]);
 * ```
 * @param {HTMLElement|NodeList} element - DOM element or list of elements
 * @param {String|Array} className - CSS class name or an array of class name
 */
export function removeClass(element, className) {
  if (element && element.length) {
    for (let i = 0, l = element.length; i < l; i++) {
      removeClass(element[i], className);
    }
  } else {
    if (Array.isArray(className)) {
      for (let i = 0, l = className.length; i < l; i++) {
        element.classList.remove(className[i]);
      }
    } else {
      element.classList.remove(className);
    }
  }
}

/**
 * Toggle CSS class(es) in element(s)
 * ```js
 * toggleClass(element, 'abc');
 * toggleClass(listOfElements, ['abc', 'def]);
 * ```
 * @param {HTMLElement|NodeList} element - DOM element or list of elements
 * @param {String|Array} className - CSS class name or an array of class name
 */
export function toggleClass(element, className) {
  if (element && element.length) {
    for (let i = 0, l = element.length; i < l; i++) {
      toggleClass(element[i], className);
    }
  } else {
    if (Array.isArray(className)) {
      for (let i = 0, l = className.length; i < l; i++) {
        element.classList.toggle(className[i]);
      }
    } else {
      element.classList.toggle(className);
    }
  }
}

/**
 * Determine whether an element has specified CSS class
 * ```js
 * hasClass(element, 'abc');
 * ```
 * @param {HTMLElement} element Target element
 * @param {String} className CSS class name
 * @return {Boolean} has class or not
 */
export function hasClass(element, className) {
  if (element) {
    return element.classList.contains(className);
  }
  return false;
}

/**
 * find parent element matching class name
 * ```js
 * findParentByClass(element, 'abc');
 * ```
 * @param {HTMLElement} element Target element
 * @param {String} className CSS class name
 * @return {HTMLElement|null} DOM element or null if not found
 */
export function findParentByClass(element, className) {
  while ((element = element.parentElement) && !element.classList.contains(className));
  return element;
}

/**
 * get class name for the provided screen size, used by slider js
 * ```js
 * getScreenStateClass(screen_size);
 * ```
 * @param {Number} screen_size pre-calculated screen size
 * @return {String} class name for sm/md/lg screen size
 */
export function getScreenStateClass(screen_size) {
  if (screen_size > 991) {
    return 'screen-lg';
  } else if (screen_size > 767) {
    return 'screen-md';
  } else {
    return 'screen-sm';
  }
}

/**
 * determine if a given element (as jQuery object) is scrolled into view
 * @param {jQuery} _$elem
 * @returns {boolean} whether _$elem is scrolled into view
 */
export function isScrolledIntoView(_$elem) {
  var docViewTop = $(window).scrollTop();
  var docViewBottom = docViewTop + $(window).height();

  var elemTop = _$elem.offset().top * 1.2;
  var elemBottom = (elemTop + _$elem.height()) * 0.8;

  // console.log(_$elem, elemBottom, docViewBottom, elemTop, docViewTop );

  return elemBottom <= docViewBottom && elemTop >= docViewTop;
}
