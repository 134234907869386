import SwiperSlider from "@shared/js/scripts/swiperSlider";

const storiesSlider = (function() {
  const options = {
    effect: 'slide',
    slidesPerView: 3,
    spaceBetween: 24,
    breakpoints: {
      1400: {
        spaceBetween: 40,
      },
    }
  };
  
  function setup() {
    const storiesSlider = document.querySelectorAll('.stories-slider');
    if (!storiesSlider.length) return;
    
    const swiperSliderSet = new Set();
    const mq = window.matchMedia('(min-width: 992px)');

    storiesSlider.forEach((slider) => {
      const itemsLength = slider.querySelectorAll('.js-swiper-item').length;

      if (itemsLength > 3) {
        swiperSliderSet.add(slider);

        if (mq.matches) {
          _init(slider);
        }
      }

      if (itemsLength > 2) {
        slider.classList.add('show-more');

        slider.querySelector('.js-show-more-btn')?.addEventListener('click', (e) => {
          slider.classList.add('shown');
          // if not mouse click (i.e. keyboard click), focus the first show-more item so that user can continue to navigate
          if (e.detail === 0) {
            slider.querySelector('.js-swiper-item:nth-child(3) a')?.focus();
          }
        });
      }
    });

    mq.addEventListener('change', (e) => {
      if (e.matches) {
        swiperSliderSet.forEach((slider) => {
          _init(slider);
        });

        storiesSlider.forEach(slider => slider.classList.remove('shown'));
      } else {
        swiperSliderSet.forEach((slider) => {
          _destroy(slider);
        });
      }
    });
  }
  
  function _init(slider) {
    slider.swiperSlider = new SwiperSlider(slider, options);
  }

  function _destroy(slider) {
    if (slider.swiperSlider && slider.swiperSlider.swiper) {
      slider.swiperSlider.swiper.destroy();
    }
    slider.querySelectorAll('.js-swiper-item').forEach((item) => item.removeAttribute('style'));
  }
  
  return { init: setup };
})();

export default storiesSlider;