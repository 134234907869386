import "jquery-ui";
import "jquery-ui/ui/widgets/datepicker";
import moment from "moment";
import { getLang } from "@shared/js/scripts/lang.js";

const eventSearchBar = (() => {

  const setupDateRangePicker = (component) => {
    var focusable;
    let $datepickers = $(component).find(".datepicker-item > input");

    const daysName = {
      en: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      tc: ["日", "月", "火", "水", "木", "金", "土"],
      sc: ["日", "一", "二", "三", "四", "五", "六"],
    }

    const setupEscapeEvent = () => {
        $("#ui-datepicker-div .ui-datepicker-calendar a")
          .last()
          .on("keydown", backToFocus);
        $("#ui-datepicker-div .ui-datepicker-prev")
          .first()
          .on("keydown", backToPrevFocus);
    }

    const onChangeMonthYear = () => {
      setTimeout(() => {
        if (["s_date", "e_date"].includes(document.activeElement.id)) {
          focusable = document.activeElement;
        }
        $("#ui-datepicker-div .ui-datepicker-prev").first().focus();
        setupEscapeEvent();
      }, 1);
    }

    const setupPrevNextButtonTabIndex = () => {
      $("#ui-datepicker-div a.ui-datepicker-prev, #ui-datepicker-div a.ui-datepicker-next").attr("tabindex", "0");
    }

    const getDate = (element) => {
      var date;
      try {
        date = $.datepicker.parseDate(dateFormat, element.value);
      } catch (error) {
        date = null;
      }

      return date;
    }

    var dateFormat = "d M yy",
      from = $("#s_date")
        .datepicker({
          defaultDate: "+1w",
          numberOfMonths: 1,
          changeMonth: false,
          changeYear: false,
          showOtherMonths: true,
          selectOtherMonths: true,
          minDate: "-5Y",
          maxDate: "+5Y",
          dateFormat,
          dayNamesMin: daysName[getLang()],
          onChangeMonthYear,
          onSelect: function (selectedDate) {
            setTimeout(() => {
              if (focusable != undefined) {
                $(focusable).focus();
              }
              from.datepicker("hide");
              to.datepicker("hide");
            }, 1);
          }
        })
        .on("change", function () {
          to.datepicker("option", "minDate", getDate(this));
          if (focusable != undefined) {
            setTimeout(() => {
              $(focusable).focus();
            }, 1);
          }
        }),
      to = $("#e_date")
        .datepicker({
          defaultDate: "+1w",
          numberOfMonths: 1,
          changeMonth: false,
          changeYear: false,
          showOtherMonths: true,
          selectOtherMonths: true,
          dayNamesMin: daysName[getLang()],
          minDate: "-5Y",
          maxDate: "+5Y",
          dateFormat,
          onChangeMonthYear,
        })
        .on("change", function () {
          from.datepicker("option", "maxDate", getDate(this));
          if (focusable != undefined) {
            setTimeout(() => {
              $(focusable).focus();
            }, 1);
          }
        });

    // Set today as default val
    from.datepicker("setDate", moment().format("DD MMM YYYY").toString());
    to.datepicker("setDate", moment().format("DD MMM YYYY").toString());

    // set mindate after set default val
    to.datepicker(
      "option",
      "minDate",
      moment().format("DD MMM YYYY").toString()
    );

    setupPrevNextButtonTabIndex();

    // a11y
    const backToFocus = (e, direct = "next") => {
      var keyCode = e.keyCode || e.which;

      if (keyCode == 9 && !e.shiftKey && focusable != undefined) {
        focusable?.focus();
        focusable = undefined;
        $("#s_date, #e_date").datepicker("hide");
      }
    };

    const backToPrevFocus = (e, direct = "next") => {
      var keyCode = e.keyCode || e.which;

      if (keyCode == 9 && e.shiftKey && focusable != undefined) {
        focusable?.focus();
        focusable = undefined;
      }
    };

    const pressToNavigateDatePicker = (e) => {
      var keyCode = e.keyCode || e.which;

      if (keyCode == 40 && !e.shiftKey) {
        e.preventDefault();
        focusable = $(e.target);

        $("#ui-datepicker-div .ui-datepicker-prev").first().focus();
        setupEscapeEvent();
      }
    };

    // listen event
    $("#s_date, #e_date").on("focus", (e) => {
      $(e.target).on("keydown", pressToNavigateDatePicker);
      setTimeout(() => {
        setupPrevNextButtonTabIndex();
      }, 1);
    });

    $("#s_date, #e_date").on("focusout", (e) => {
      $(e.target).off("keydown", pressToNavigateDatePicker);
    });
  };

  const setupBsSelect = (component) => {
    $(component).find("select").selectpicker();
  };

  const handleSubmit = (e) => {
    // e.preventDefault();
    const component = e.target;

    const data = {
      type: e.target.elements.search_type.value,
      eventType: e.target.elements.search_event_type.value,
      keywords: e.target.elements.search_keywords.value,
      fromDate: e.target.elements.search_from_date.value,
      toDate: e.target.elements.search_to_date.value,
    };

    // console.log(data);
  };

  const asyncTimeout = (ms) => {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  const setupResetButton = (component) => {
    $(component)
      .find(`button[type="reset"]`)
      .on("click", async (e) => {
        let $select = $(component).find("select");

        $select.each((i, s) => {
          let opt = $(s).find("option:first-of-type").val();
          $(s).val(opt);
        });
        $select.selectpicker("refresh");

        // Set today as default val
        const from = $("#s_date"), to = $("#e_date");
        to.datepicker(
          "option",
          "minDate",
          moment().format("DD MMM YYYY").toString()
        );

        from.datepicker(
          "option",
          "maxDate",
          undefined
        );
        
        setTimeout(() => {
          from.datepicker("setDate", moment().format("DD MMM YYYY").toString());
          to.datepicker("setDate", moment().format("DD MMM YYYY").toString());
        }, 1);

      });
  };

  const setup = () => {
    $(".event-search-bar").each((i, component) => {
      setupBsSelect(component);
      setupDateRangePicker(component);
      setupResetButton(component);

      $(component).on("submit", handleSubmit);
    });
  };

  return {
    init: setup,
  };
})();

export default eventSearchBar;
