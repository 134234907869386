function getURLSearchParams(key) {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(key);
}

function setUrlSearchParam(key, value) {
  const urlParams = new URLSearchParams(window.location.search);
  urlParams.set(key, value);

  [...urlParams.entries()].forEach(([key, value]) => {
    if (value == "" || value == null || value == "undefined") {
      urlParams.delete(key);
    }
  });

  window.history.replaceState(
    {},
    "",
    window.location.pathname + "?" + urlParams.toString()
  );

  // $(".page-head .lang__item").each(function () {
  //   var $this = $(this);
  //   var href = $this.attr('href');

  //   if (!href.startsWith('http')) {
  //     href = window.location.origin + href;
  //   }

  //   var url = new URL(href);
  //   url.searchParams.set(key, value);
  //   $this.attr('href', url.toString());
  // });
}


export { getURLSearchParams, setUrlSearchParam }